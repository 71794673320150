import React from 'react';
import {
    Button,
    Dialog,
    makeStyles,
    DialogActions,
    DialogContent,
    IconButton,
    DialogTitle,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { closeDialog } from 'actions/dialog';
import { useDispatch, useTrackedState } from 'store';

const ResponsiveDialog = () => {
    const dispatch = useDispatch();
    const theme = useTheme();
    const { dialog } = useTrackedState();

    const { open, actions, title, content, dialogProps } = dialog;

    const handleClose = () => {
        dispatch(closeDialog());
    };

    const useStyles = makeStyles((theme) => ({
        closeButton: {
            position: 'absolute',
            right: theme.spacing(1),
            top: theme.spacing(1),
            color: theme.palette.grey[500],
        },
        dialog: {
            // '& .MuiDialog-paperFullScreen': {
            //     maxWidth: dialogProps?.maxWidth || 'lg',
            // },
        },
    }));

    const classes = useStyles();
    const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));

    return (
        <Dialog
            className={classes.dialog}
            fullScreen={fullScreen || Boolean(dialogProps?.fullScreen)}
            open={open}
            onClose={handleClose}
            aria-labelledby="responsive-dialog-title"
            fullWidth={Boolean(dialogProps?.fullWidth)}
            maxWidth={dialogProps?.maxWidth || 'sm'}
        >
            <DialogTitle id="responsive-dialog-title">
                {title}
                <IconButton aria-label="close" className={classes.closeButton} onClick={handleClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent>{content}</DialogContent>
            <DialogActions>
                {actions.map(({ onClick, title }) => (
                    <Button autoFocus onClick={onClick} color="primary">
                        {title}
                    </Button>
                ))}
            </DialogActions>
        </Dialog>
    );
};

export default ResponsiveDialog;
