import { TextField } from '@material-ui/core';
import { useStyles } from '../../styles';
import { Controller, useWatch } from "react-hook-form";
import ReactHookTextField from '../../../../components/ReactHookTextField';
import NumberFormat from '../../../../components/NumberFormat';

const Row = ({ control, index, setTotalPrice, totalPrice }) => {
    const classes = useStyles();

    const qty = useWatch({
        control,
        name: `lineItems.${index}.quantity`
    }, false);

    const price = useWatch({
        control,
        name: `lineItems.${index}.price`
    }, false);

    return (
        <div style={{display: 'flex'}}>
            <ReactHookTextField
                name={`lineItems.${index}.description`}
                type='textarea'
                control={control}
                label='Description'
                style={{flex: 'auto', marginRight: '10px'}}
                InputProps={{
                    multiline:true
                }}
            />

            <Controller
                name={`lineItems.${index}.quantity`}
                type='number'
                control={control}
                render={({ field, fieldState: { error } }) => <TextField 
                    {...field} 
                    label='Qty'
                    style={{minWidth: '70px', marginRight: '10px'}}
                    className={classes.input}
                    variant='outlined' 
                    InputLabelProps={{ shrink: true }} 
                    size='small'
                    error={error}
                    helperText={error && error.message} 
                    onChange={(e) => {
                        const quantity = e.target.value;
                        setTotalPrice([
                            ...totalPrice.slice(0,index),
                            quantity * (price || 0),
                            ...totalPrice.slice(index+1)
                        ]);
                        field.onChange(quantity);
                        }}
                    />
                }
            />     

            <Controller
                name={`lineItems.${index}.price`}
                type='number'
                control={control}
                render={({ field, fieldState: { error } }) => <TextField 
                    {...field} 
                    label='Item Price'
                    style={{width: '175px', marginRight: '10px'}}
                    className={classes.input}
                    variant='outlined' 
                    InputLabelProps={{ shrink: true }} 
                    size='small'
                    error={error}
                    helperText={error && error.message} 
                    onChange={(e) => {
                        const price = e.target.value;
                        setTotalPrice([
                            ...totalPrice.slice(0,index),
                            (qty || 0) * price,
                            ...totalPrice.slice(index+1)
                        ]);
                        field.onChange(price);
                        }}
                    InputProps={{
                        inputComponent: NumberFormat,
                    }}
                    />
                }
            />     

            <TextField
                label='Total Price'
                type='text'
                disabled={true}
                className={classes.input}
                variant='outlined' 
                InputLabelProps={{ shrink: true }} 
                size='small'
                value={price * qty}
                style={{width: '175px', marginRight: '10px'}}
                InputProps={{
                    inputComponent: NumberFormat,
                }}
            />
        </div>                
    );
};

export default Row;
