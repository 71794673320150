import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { displayedDueDate, salesRepInitials } from '../../Quotes/components/QuoteFields';
import fanSizePlusMinus from 'helpers/fanSizePlusMinus';
import PDFHeader from './PDFHeader';

Font.register({
    family: "Roboto",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf"
});

Font.register({
    family: "Roboto Bold",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf"
});

const appendFormatter = (str) => {

    const appender = (name, value) => {
        return `${name != '' ? name + ' ' : ''}${value} ${str}`
    }

    return appender
}

const positiveValueFormatter = (formatter) => {

    return (name, value) => {
        if (value > 0) return formatter(name, value);
        return ''
    }
}

const reverseFormatter = (name, value) => {
    if (!value) return ''
    return `${value} ${name}`
}

const boolFormatter = (name, value) => {
    return `${name} ${value ? 'yes' : 'no'}`
}

const boolYesFormatter = (name, value) => {
    if (!value) return ''
    return `${name} yes`
}

export const styles = StyleSheet.create({
    page: {
        position: 'relative',
        fontSize: 10,
        width: '100%',
        marginTop: 15
    },
    w5: { width: '5%' },
    w10: { width: '10%' },
    w15: { width: '15%' },
    w20: { width: '20%' },
    w25: { width: '25%' },
    w30: { width: '30%' },
    w45: { width: '45%' },
    w50: { width: '50%' },
    w55: { width: '55%' },
    w65: { width: '65%' },
    w70: { width: '70%' },
    w80: { width: '80%' },
    w85: { width: '85%' },
    w100: { width: '100%' },
    title: {
        fontSize: 20,
        marginTop: 20,
        fontFamily: 'Roboto Bold'
    },
    mR: {
        marginRight: 2
    },
    mL: {
        marginLeft: 2
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    table: {
        marginLeft: '4%',
        marginRight: '4%',
        width: '92%',
        borderTop: '1px solid grey',
        borderLeft: '1px solid grey',
        borderRight: '1px solid grey',
        borderBottom: '1px solid grey',
    },
    big: {
        fontSize: 16,
        fontFamily: 'Roboto Bold',
    },
    boldText: {
        fontSize: 12,
        fontFamily: 'Roboto Bold',
    },
    text: {
        fontSize: 12,
        fontFamily: 'Roboto',
    },
    boldTextSmaller: {
        fontSize: 10,
        fontFamily: 'Roboto Bold',
    },
    textSmaller: {
        fontSize: 10,
        fontFamily: 'Roboto',
    },
    smallText: {
        fontSize: 10,
        marginTop: 2,
        fontFamily: 'Roboto',
    },
    tC: {
        textAlign: 'center'
    },
    bR: {
        borderRight: '1px solid grey',
    },
    cellSmall: {
        width: 25,
        borderRight: '1px solid grey'
    },
    cellDesc: {
        width: 400,
        borderRight: '1px solid grey',
        margin: '0 4 0 2'
    },
    cellDraw: {
        width: 100,
        borderRight: '1px solid grey'
    },
    cellStock: {
        width: 38,
        borderRight: '1px solid grey'
    },
    cellSup: {
        width: 100,
        borderRight: '1px solid grey'
    },
    cellMid: {
        width: 60,
        borderRight: '1px solid grey'
    },
    cellRec: {
        width: 40,
    },

});

export const rows = [
    {
        name: 'Motor',
        field: 'motor',
        desc: [
            ['motorMake'],
            ['motorDesign'],
            ['KW', 'motorKW', reverseFormatter],
            ['Pole', 'motorPole', reverseFormatter],
            ['RPM', 'motorSpeed', reverseFormatter],
            ['motorPowerSupply'],
            ['motorProtection'],
            ['motorInsulation'],
            ['motorFrame'],
            ['motorMount'],
            ['motorStart'],
            ['TerminalBox:', 'motorTerminalBox'],
            ['Datasheet:', 'motorDatasheet', boolYesFormatter],
            ['TestReport:', 'motorTestReport', boolYesFormatter],
            ['Certification:', 'motorCertification', boolYesFormatter]
        ],
        notes: 'motorOther'
    },
    {
        name: 'Impeller',
        field: 'impeller',
        notes: 'impellerNotes',
        desc: [
            ['Class:', 'impellerClass'],
        ]
    },
    {
        name: 'Liner Set',
        field: 'liner',
        desc: [
            ['Material:', 'linerMaterial'],
        ]
    },
    {
        name: 'Hub',
        field: 'hub',
        desc: [
            ['hubStyle'],
            ['Size:', 'hubSize'],
            ['Support:', 'hubSupport'],
        ]
    },
    {
        name: 'Hub Bush',
        field: 'hubBush',
        desc: [
            ['Size:', 'hubBushSize'],
        ]
    },
    {
        name: 'Fan Pulley',
        field: 'fanPulley',
        checkField: 'fanDrive',
        checkValue: 'BeltDrive',
        desc: [
            ['Type:', 'fanPulleyType'],
            ['Bush:', 'fanPulleyBush'],
            ['Bore:', 'fanPulleyBore'],
        ]
    },
    {
        name: 'Motor Pulley',
        field: 'driveMotorPulley',
        checkField: 'fanDrive',
        checkValue: 'BeltDrive',
        desc: [
            ['Type:', 'driveMotorPulleyType'],
            ['Bush:', 'driveMotorPulleyBush'],
            ['Bore:', 'driveMotorPulleyBore'],
        ]
    },
    {
        name: 'Belts',
        field: 'driveBelt',
        checkField: 'fanDrive',
        checkValue: 'BeltDrive',
        desc: [
            ['Type:', 'driveBeltType'],
            ['Centres:', 'driveBeltCentres'],
            ['Tension:', 'driveBeltTension'],
        ]
    },
    {
        name: 'Belt Guard',
        field: 'driveBeltGuard',
        checkField: 'fanDrive',
        checkValue: 'BeltDrive',
        desc: [
            ['Material:', 'driveBeltGuardMaterial'],
        ],
        notes: 'driveBeltGuardNotes',
    },
    {
        name: 'Slide Rails',
        field: 'driveSlideRails',
        checkField: 'fanDrive',
        checkValue: 'BeltDrive',
        desc: [
            ['Model:', 'driveSlideRailsModel'],
            ['Size:', 'driveSlideRailsSize'],
        ]
    },
    {
        name: 'Balancing',
        field: 'balance',
        desc: [
            ['RPM', 'balanceRPM', reverseFormatter],
            ['Grade:', 'balanceGrade'],
            ['Fix Weights:', 'balanceFixWeights'],
        ],
        notes: 'balanceNotes'
    },
    {
        name: 'Shaft',
        field: 'shaft',
        desc: [
            ['Drive End Dia:', 'shaftDEDia', appendFormatter('mm')],
            ['Non Drive End Dia:', 'shaftNDEDia', appendFormatter('mm')],
            ['Material:', 'shaftMaterial'],
            ['Cooling Wheel:', 'shaftCoolingWheel'],
        ],
        notes: 'shaftNotes'
    },
    {
        name: 'Shaft Guard',
        field: 'shaftGuard',
        desc: [
            ['Material:', 'shaftGuardMaterial'],
            ['Paint:', 'shaftGuardPaint'],
        ],
        notes: 'shaftGuardNotes'
    },
    {
        name: 'Bearings (DE)',
        field: 'bearingDE',
        desc: [
            ['', 'bearingDE'],
            ['Fixed:', 'bearingDEFixed', boolFormatter],
            ['Seal', 'bearingDESeal', reverseFormatter],
            ['bearingDELife'],
        ],
        notes: 'bearingDENotes'
    },
    {
        name: 'Bearings (NDE)',
        field: 'bearingNDE',
        desc: [
            ['', 'bearingNDE'],
            ['Fixed:', 'bearingDEFixed', boolFormatter],
            ['Seal', 'bearingNDESeal', reverseFormatter],
            ['bearingNDELife'],
        ],
        notes: 'bearingNDENotes'
    },
    {
        name: 'Shaft Seal',
        field: 'shaftSeal',
        desc: [
            ['Type:', 'shaftSealType'],
            ['Thickness:', 'shaftSealThickness'],
        ]
    },
    {
        name: 'Couplings',
        field: 'coupling',
        desc: [['couplingType']],
        notes: 'couplingNotes'
    },
    {
        name: 'Coupling Guard',
        field: 'couplingGuard',
        desc: [
            ['Material:', 'couplingGaurdMaterial'],
            ['Paint:', 'couplingGaurdPaint'],
        ],
        notes: 'couplingGuardNotes'
    },
    {
        name: 'Case',
        field: 'case',
        desc: [
            ['caseMaterial'],
            ['', 'caseThickness', appendFormatter('mm')],
            ['x Splits', 'caseNoSplit', positiveValueFormatter(reverseFormatter)],
            ['x Inspection Door', 'caseInspectionDoor', positiveValueFormatter(reverseFormatter)],
            ['x Pullout', 'casePullout', positiveValueFormatter(reverseFormatter)],
            ['caseInspectionDoorType'],
            ['Gasket:', 'caseGasket'],
            ['Match Flanges:', 'caseMatchFlanges', boolFormatter],
            ['Anti Spark:', 'caseAntiSpark', boolYesFormatter],
            ['x Case Drain', 'caseDrain', positiveValueFormatter(reverseFormatter)],
            ['caseDrainType'],
            ['Insulation:', 'caseInsulation'],
            ['Fasteners:', 'caseFasteners'],
        ],
        notes: 'caseNotes'
    },
    {
        name: 'Inlet Cone',
        field: 'inletCone',
        desc: [
            ['Material:', 'inletConeMaterial'],
            ['Anti Spark:', 'inletConeAntiSpark', boolYesFormatter],
            ['Type:', 'inletConeType']
        ]
    },
    {
        name: 'Pedestal',
        field: 'pedestal',
        desc: [
            ['Weld:', 'pedestalWeld'],
            ['Material:', 'pedestalMaterial'],
        ]
    },
    {
        name: 'Base',
        field: 'base',
        desc: [
            ['Type:', 'baseType'],
            ['Coating:', 'baseCoating']
        ]
    },
    {
        name: 'Base Mount',
        field: 'baseMount',
        desc: [
            ['Type:', 'baseMountType'],
            ['Style:', 'baseMountStyle'],
        ]
    },
    {
        name: 'Inlet Damper',
        field: 'inletDamper',
        desc: [
            ['Material:', 'inletDamperMaterial'],
            ['Control:', 'inletDamperControl'],
        ],
        notes: 'inletDamperNotes'
    },
    {
        name: 'Inlet Box',
        field: 'inletBox',
        desc: [
            ['Material:', 'inletBoxMaterial'],
        ],
        notes: 'inletBoxNotes'
    },
    {
        name: 'Inlet Silencer',
        field: 'inletSilencer',
        desc: [
            ['Type:', 'inletSilencerType'],
            ['Material:', 'inletSilencerMaterial'],
        ]
    },
    {
        name: 'Inlet Silencer Transition',
        field: 'inletSilencerTransition',
        desc: [
            ['Length:', 'inletSilencerTransitionLength'],
            ['Thickness:', 'inletSilencerTransitionThickness'],
        ],
    },
    {
        name: 'Inlet Flexible',
        field: 'inletFlexible',
        desc: [
            ['Material:', 'inletFlexibleMaterial'],
            ['Flow Liners:', 'inletFlexibleFlowLiners', boolFormatter],
        ],
        notes: 'inletFlexibleMisc'
    },
    {
        name: 'Inlet Vanes',
        field: 'inletVanes',
    },
    {
        name: 'Evase',
        field: 'evase',
        notes: 'evaseNotes'
    },
    {
        name: 'Outlet Damper',
        field: 'outletDamper',
        desc: [
            ['Material:', 'outletDamperMaterial'],
            ['Control:', 'outletDamperControl'],
        ],
        notes: 'outletDamperNotes'
    },
    {
        name: 'Outlet Silencer',
        field: 'outletSilencer',
        desc: [
            ['Type:', 'outletSilencerType'],
            ['Material:', 'outletSilencerMaterial'],
        ]
    },
    {
        name: 'Outlet Silencer Transition',
        field: 'outletSilencerTransition',
        desc: [
            ['Length:', 'outletSilencerTransitionLength'],
            ['Thickness:', 'outletSilencerTransitionThickness'],
        ],
    },
    {
        name: 'Outlet Flexible',
        field: 'outletFlexible',
        desc: [
            ['Material:', 'outletFlexibleMaterial'],
            ['Flow Liners:', 'outletFlexibleFlowLiners', boolFormatter],
        ],
        notes: 'outletFlexibleMisc'
    },
    {
        name: 'Internal Finish',
        field: 'internalCoating',
        desc: [
            ['Type:', 'internalCoatingType'],
        ]
    },
    {
        name: 'External Finish',
        field: 'externalCoating',
        desc: [
            ['Type:', 'externalCoatingType'],
            ['Colour:', 'externalCoatingColour'],
        ]
    },
    {
        name: 'Lag & Clad',
        field: 'lagClad',
        desc: [
            ['Insulation Type:', 'lagCladInsulationType'],
            ['Insulation Thickness:', 'lagCladInsulationThickness'],
            ['Cladding Type:', 'lagCladCladdingType'],
            ['Cladding Thickness:', 'lagCladCladdingThickness'],
        ]
    },
];

const WarehousePDF = ({ quoteData, jobData }) => {
    const { attributes } = jobData;

    const client = quoteData.clientDetails;
    const contact = client.contactDetails;
    let contactText = contact.name
    contactText +=  contact.phone ? ` ${contact.phone}` : ""
    contactText +=  contact.phoneAlt ? ` (${contact.phoneAlt})` : ""

    const Aerotech = quoteData.productAreaID === 7 || quoteData.productAreaID === 8;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <PDFHeader Aerotech={Aerotech} id={jobData.id} isWarehouse={true} />
                <View style={[styles.flex, styles.table]}>
                    <View style={[styles.w25, styles.mL, styles.bR]}>
                        <Text style={[styles.boldText]}>Due Date: {displayedDueDate(attributes.commercialsDueDate, attributes.commercialsRevisedDueDate)}</Text>
                    </View>
                    <View style={[styles.w20, styles.mL, styles.bR]}>
                        <Text style={[styles.boldText, styles.mR]}>PS: {attributes.engineeringDraftsPerson && salesRepInitials(attributes.engineeringDraftsPerson)}</Text>
                    </View>
                    <View style={[styles.w25, styles.mL, styles.bR]}>
                        <Text style={[styles.boldText]}>GA Drg No: {attributes.fanGeneralArrangementDrawing}</Text>
                    </View>
                    <View style={[styles.w30, styles.mL, styles.bR]}>
                        <Text style={[styles.boldText]}>Previous Jobs: {quoteData.fanSerial}</Text>
                    </View>
                </View>

                <View style={[styles.flex, styles.table]}>
                    <Text style={[styles.big, styles.mL]}>{attributes.fanQty} x {Aerotech ? '' : 'D'}{attributes.fanDesign} {Aerotech ? '' : 'S'}{attributes.fanSize}{fanSizePlusMinus(attributes.fanSizePlusMinus)}/{attributes.fanPercentageWidth} Arr {attributes.fanArrangement} {attributes.fanHanding} {attributes.fanOrt}{attributes.fanPos ? `POS ${attributes.fanPos}` : ''} {attributes.fanOther}</Text>
                </View>

                <View style={[styles.flex, styles.table]}>
                    <View style={[styles.cellSmall]}>
                        <Text style={[styles.boldText, styles.tC]}>Qty</Text>
                    </View>
                    <View style={[styles.cellDesc]}>
                        <Text style={[styles.boldText, styles.tC]}>Description</Text>
                    </View>
                    <View style={[styles.cellSup]}>
                        <Text style={[styles.boldText, styles.tC]}>Supplier</Text>
                    </View>
                    <View style={[styles.cellMid]}>
                        <Text style={[styles.boldText, styles.tC]}>Order Due</Text>
                    </View>
                    <View style={[styles.cellRec]}>
                        <Text style={[styles.boldText, styles.tC]}>Order Rec</Text>
                    </View>
                </View>

                {rows.map(({ name, field, desc, notes }) => {
                    return attributes[`${field}Warehouse`] && attributes[`${field}Qty`] >= '1' ?
                        <View style={[styles.flex, styles.table]}>
                            <View style={[styles.cellSmall]}>
                                <Text style={[styles.smallText, styles.tC]}>{attributes[`${field}Qty`]}</Text>
                            </View>
                            <View style={[styles.cellDesc]}>
                                <Text style={[styles.smallText]}>
                                    {name} - {desc && desc.map(f => {
                                        switch (f.length) {
                                            case 1:
                                                return attributes[f[0]] ? `${attributes[f[0]]}` : ''
                                            case 2:
                                                return attributes[f[1]] ? `${f[0]} ${attributes[f[1]]}` : ''
                                            case 3:
                                                return f[2](f[0], attributes[f[1]])
                                        }

                                    }).filter((i) => i != '').join(', ')}
                                    {attributes[notes]}
                                </Text>
                            </View>
                            <View style={[styles.cellSup]}>
                                <Text style={[styles.smallText, styles.tC]}>{attributes[`${field}Supplier`]}</Text>
                            </View>
                            <View style={[styles.cellMid]}>
                                <Text style={[styles.smallText, styles.tC]}>{attributes[`${field}OrderDue`] && new Date(attributes[`${field}OrderDue`]).toLocaleDateString('en-AU', { year: "2-digit", month: "2-digit", day: "2-digit" })}</Text>
                            </View>
                            <View style={[styles.cellRec]}>
                                <Text style={[styles.smallText, styles.tC]}>{attributes[`${field}OrderReceived`] ? 'Yes' : ''}</Text>
                            </View>
                        </View> : null
                }
                )}

                {jobData.lineItems.map(({ qty, description, warehouse, supplier, orderDue, orderReceived }) => {
                    return warehouse ? (
                        <View style={[styles.flex, styles.table, { borderBottom: '1px solid grey' }]}>
                            <View style={[styles.cellSmall]}>
                                <Text style={[styles.smallText, styles.tC]}>{qty}</Text>
                            </View>
                            <View style={[styles.cellDesc]}>
                                <Text style={[styles.smallText]}>{description}</Text>
                            </View>
                            <View style={[styles.cellSup]}>
                                <Text style={[styles.smallText, styles.tC]}>{supplier}</Text>
                            </View>
                            <View style={[styles.cellMid]}>
                                <Text style={[styles.smallText, styles.tC]}>{orderDue && new Date(orderDue).toLocaleDateString('en-AU', { year: "2-digit", month: "2-digit", day: "2-digit" })}</Text>
                            </View>
                            <View style={[styles.cellRec]}>
                                <Text style={[styles.smallText, styles.tC]}>{orderReceived ? 'Yes' : ''}</Text>
                            </View>
                        </View>
                    ) : null
                }
                )}
                <View style={[styles.flex, styles.table, { borderBottom: '1px solid grey' }]}>
                    <View style={[styles.w100]}>
                        <Text style={[styles.boldText, styles.mL]}>Client: {client.name}</Text>
                        <Text style={[styles.text, styles.mL]}>
                            Contact: {contactText} 
                        </Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default WarehousePDF;
