import React from 'react';
import { TextField } from '@material-ui/core';
import { useStyles } from '../screens/Quotes/styles';
import { Controller } from "react-hook-form";

const ReactHookTextField = ({ name, type, control, label, style, InputProps, placeholder, required = false, disabled=false, variant='outlined' }) => {
    const classes = useStyles();

    return (
        <Controller
            name={name}
            type={type}
            control={control}
            render={({ field, fieldState: { error } }) => <TextField 
                {...field} 
                type={type}
                label={label}
                style={style}
                className={classes.input}
                variant={variant} 
                InputLabelProps={{ shrink: true }} 
                size='small'
                disabled={disabled}
                InputProps={InputProps}
                error={error}
                required={required}
                placeholder={placeholder}
                helperText={error && error.message} />
            }
        />            
    );
};

export default ReactHookTextField;
