import { FeedbackProps } from 'types/feedback';

export const initialFeedbackState: FeedbackProps = {
    message: '',
    severity: 'info',
    open: false,
    errors: [],
};

type Action = { type: string };

const feedbackReducer = (state: FeedbackProps = initialFeedbackState, { type, ...payload }: Action): FeedbackProps => {
    switch (type) {
        case 'ADD_FEEDBACK':
            return { ...state, ...payload, open: true };
        case 'TOGGLE_FEEDBACK':
            return { ...state, open: !state.open };
        default:
            return state;
    }
};

export default feedbackReducer;
