import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';
import SelectWithDataReactHook from 'components/SelectWithDataReactHook';
import { makeStyles, TextField } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

const useStyles = makeStyles(() => ({
    overrideDisable: {
        '& .Mui-checked svg': {
            fill: '#2576b6'
        },
        '& span + .Mui-disabled': {
            color: 'rgba(0, 0, 0, 0.87)'
        }
    }
}));

const QA = ({ control, getValues, watch }) => {
    const classes = useStyles();

    const motorDatasheet = watch('attributes.motorDatasheet');
    const motorTestReport = watch('attributes.motorTestReport');
    const motorCertification = watch('attributes.motorCertification');

    return (
        <div style={{width: '100%'}}>
            <ReactHookCheckbox 
                control={control}
                name='attributes.qaSpec'
                label="Spec"     
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.qaItp'
                label="ITP"     
                style={{width: '19%', marginRight: '1%'}}
            />

            <SelectWithDataReactHook
                name='attributes.qaItpPerson'
                type='text'
                control={control}
                label='ITP Person'
                options={[
                    {
                        label: '',
                        id: ''
                    },
                    {
                        label: 'QA Manager',
                        id: 'QA Manager'
                    },
                    {
                        label: 'Client',
                        id: 'Client'
                    }
                ]}
                style={{width: '19%', marginRight: '1%'}}
            /> 
            <ReactHookCheckbox 
                control={control}
                name='attributes.qaOm'
                label="OM"     
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.qaMdr'
                label="MDR"     
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.qaWps'
                label="WPS"     
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.qaMatCert'
                label="Mat Cert"     
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.qaMechTest'
                label="Mech Test"     
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.qaPerfTest'
                label="Perf Test"     
                style={{width: '19%', marginRight: '1%'}}
            />
            <SelectWithDataReactHook
                name='attributes.qaWitness'
                type='text'
                control={control}
                label='Witness'
                options={[
                    {
                        label: 'No',
                        id: 'No'
                    },
                    {
                        label: 'Customer',
                        id: 'Customer'
                    },
                    {
                        label: 'Independant',
                        id: 'Independant'
                    },
                    {
                        label: 'Customer & Other',
                        id: 'Customer & Other'
                    }
                ]}
                style={{width: '29%', marginRight: '1%'}}
            /> 
            <TextField
                label='GA Dwg'
                type='text'
                disabled={true}
                className={classes.disabledInput}
                variant='outlined' 
                InputLabelProps={{ shrink: true }} 
                size='small'
                value={getValues('attributes.qaGaDwg')}
                style={{width: '15%', marginRight: '1%'}}
            />
             <TextField
                label='GA Dwg No:'
                type='text'
                disabled={true}
                className={classes.disabledInput}
                variant='outlined' 
                InputLabelProps={{ shrink: true }} 
                size='small'
                value={getValues('attributes.fanGeneralArrangementDrawing')}
                style={{width: '15%', marginRight: '1%'}}
            />
            
            <ReactHookTextField
                name='attributes.qaNoise'
                type='text'
                control={control}
                label='Noise (max)'
                style={{width: '19%', marginRight: '1%'}}
            />
            <FormControlLabel 
                control={
                    <Checkbox 
                        disabled={true}
                        checked={motorDatasheet}
                    />
                } 
                label="Motor Datasheet"
                className={classes.overrideDisable}
            />
            <FormControlLabel 
                control={
                    <Checkbox 
                        disabled={true}
                        checked={motorTestReport}
                    />
                } 
                label="Motor Test"
                className={classes.overrideDisable}
            />
            <FormControlLabel 
                control={
                    <Checkbox 
                        disabled={true}
                        checked={motorCertification}
                    />
                } 
                label="Motor Certification"
                className={classes.overrideDisable}
            />
            <ReactHookTextField
                name='attributes.qaNotes'
                type='textarea'
                control={control}
                label='Notes'
                style={{width: '29%', marginRight: '1%'}}
                InputProps={{
                    multiline:true
                }}
            />   
            <ReactHookTextField
                name='attributes.qaCompletionDate'
                type='date'
                control={control}
                label='Completion Date'
                style={{width: '14%', marginRight: '1%'}}
            />   
            <ReactHookTextField
                name='attributes.qaSentDate'
                type='date'
                control={control}
                label='Sent Date'
                style={{width: '14%', marginRight: '1%'}}
            />   
            <ReactHookTextField
                name='attributes.qaApprovedDate'
                type='date'
                control={control}
                label='Approved Date'
                style={{width: '14%', marginRight: '1%'}}
            />
        </div>
    );
};

export default QA;
