import { useEffect } from 'react';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import makePost from 'helpers/makePost';
import { useParams, useHistory } from 'react-router-dom';

const JobsNew = () => {
    const { quoteID, itemID, revision } = useParams();
    let history = useHistory();
    const dispatch = useDispatch();

    useEffect(() => {
        const fetchQuoteDetails = async () => {

            const response = await makePost({
                url: `/jobs`,
                data: { 
                    quoteID: parseInt(quoteID),
                    itemID: parseInt(itemID),
                    revision
                },
            });

            if(response.data.id) {
                history.push(`/jobs/edit/${response.data.id}`);
            } else {
                dispatch(addFeedback('Error creating new job, please go back and try again.', 'error'));
            }
        };

        fetchQuoteDetails();
    }, []);

    return <></>;
};

export default JobsNew;
