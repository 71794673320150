import { StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    page: {
        position: 'relative',
        fontSize: 10,
        paddingTop: 20,
        paddingBottom: 70
    },
    aerotech: {
        width: 300,
        height: 64
    },
    aireng: {
        width: 150,
        height: 90
    },
    w15: { width: '15%' },
    w20: { width: '20%' },
    w30: { width: '30%' },
    w40: { width: '40%' },
    w45: { width: '45%' },
    w65: { width: '65%' },
    w70: { width: '70%' },
    w80: { width: '80%' },
    w85: { width: '85%' },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    header: {
        marginLeft: '4%',
        marginTop: 10,
        marginRight: '4%',
        width: '92%',
    },
    name: {
        fontSize: 16,
        fontWeight: 600,
    },
    abn: {
        fontSize: 10,
        borderBottom: '1px solid red',
        paddingBottom: 3,
        marginBottom: 3
    },
    address: {
        borderBottom: '1px solid red',
        paddingBottom: 3,
        marginBottom: 3
    },
    web: {
        borderBottom: '1px solid red',
        paddingBottom: 3
    },
    quoteNo: {
        fontSize: 14,
        marginLeft: '4%',
        marginTop: 20,
    },
    tableHeader: {
        marginLeft: '4%',
        marginTop: 10,
        marginRight: '4%',
        width: '92%',
        borderBottom: '1px solid grey',
        marginBottom: 5
    },
    tableHeaderLabel: {
        paddingBottom: 3,
        color: 'grey'
    },
    tableHeaderText: {
        paddingBottom: 3,
    },
    table: { 
        marginLeft: '4%',
        marginRight: '4%',
        width: '92%',
        marginTop: 3,
    },
    tableLabel: {
        fontWeight: 800,
        paddingBottom: 3,
        color: 'grey'
    },
    tableText: {
        paddingBottom: 3,
    },
    qty: {
        width: '5%',
        textAlign: 'center'
    },
    priceHeader: {
        width: '15%',
        textAlign: 'right'
    },
    price: {
        width: '15%',
        textAlign: 'right'
    },
    total: {
        marginLeft: '4%',
        marginTop: 30,
        marginRight: '4%',
        width: '92%',
    },
    totalText: {
        textAlign: 'right',
        marginRight: '1%'
    },
    comments: {
        marginLeft: '4%',
        marginTop: 30,
        marginRight: '4%',
        width: '92%',
    },
    extra: {
        marginLeft: '4%',
        marginTop: 30,
        marginRight: '4%',
        width: '92%',
    },
    footer: {
        fontSize: 8,
        marginLeft: '4%',
        marginRight: '4%',
        width: '92%',
        position: 'absolute',
        bottom: 0,
        left: 0,
        right: 0,
        color: 'grey',
        height: 60
    },
    bold: {
        fontWeight: 600,
    },
    marginTop30: {
        marginTop: 30,
    },
    marginTop10: {
        marginTop: 10,
    }
});

export const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'AUD',
    currencyDisplay: 'narrowSymbol'
});