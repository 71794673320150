import React, { useState, useEffect } from 'react';
import Form from '../Form';
import ContentWrapper from '../ContentWrapper';
import DataProvider from 'providers/data';
import { axiosInstance } from 'App';

interface AddEditFormProps {
    children?: any;
    isBusy?: boolean;
    apiPath?: string;
    transform?: any;
    formStructure: any[];
    submitOptions?: any;
    formValidation?: any;
}

const AddEditForm: React.FC<AddEditFormProps> = ({ apiPath, children, formStructure, submitOptions, formValidation, isBusy = false, transform }) => {
    const [data, setData] = useState();
    const [refresh, setRefresh] = useState(true);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);

            const result = await axiosInstance({ url: apiPath });

            if (transform) {
                setData(transform(result.data));
            }
            else {
                setData(result.data);
            }
            
            setIsLoading(false);
        };

        if (refresh) {
            fetchData();
            setRefresh(false);
        }
    }, [apiPath, refresh]);

    return (
        <DataProvider data={{ data, isLoading, mutate: () => setRefresh(true) }}>
            <ContentWrapper isBusy={isBusy}>
                <Form
                    data={data}
                    formStructure={formStructure}
                    formValidation={formValidation}
                    fetching={isLoading}
                    submitOptions={submitOptions}
                />
                {children}
            </ContentWrapper>
        </DataProvider>
    );
};

export default AddEditForm;
