import ReactHookTextField from 'components/ReactHookTextField';
import SelectWithDataReactHook from 'components/SelectWithDataReactHook';
import { TextField } from '@material-ui/core';
import { useStyles } from './Form';

const Engineering = ({ control, getValues, projectDraftspersonList }) => {
    const classes = useStyles();

    return (
        <div style={{width: '100%'}}>
            <SelectWithDataReactHook
                name='attributes.engineeringDraftsPerson'
                control={control}
                label='Project Draftsperson'
                options={projectDraftspersonList}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.engineeringExpectedStart'
                type='date'
                control={control}
                label='Engineering Expected Start'
                style={{width: '14%', marginRight: '1%'}}
            />
            <TextField
                label='Quoted Eng (Hours)'
                type='text'
                disabled={true}
                className={classes.disabledInput}
                variant='outlined' 
                InputLabelProps={{ shrink: true }} 
                size='small'
                value={getValues('attributes.commercialsEngQuoted')}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.commercialsEngAllowed'
                type='text'
                control={control}
                label='Allowed Eng (Hours)'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.commercialsEngActual'
                type='text'
                control={control}
                label='Actual Eng (Hours)'
                style={{width: '14%', marginRight: '1%'}}
            />
            <SelectWithDataReactHook
                name='attributes.qaGaDwg'
                type='text'
                control={control}
                label='GA Dwg'
                options={[
                    {
                        label: 'Certified',
                        id: 'Certified'
                    },
                    {
                        label: 'Approval',
                        id: 'Approval'
                    }
                ]}
                style={{width: '10%', marginRight: '1%'}}
            />  
            <ReactHookTextField
                name='attributes.fanGeneralArrangementDrawing'
                type='text'
                control={control}
                label='GA Dwg No:'
                style={{width: '10%', marginRight: '1%'}}
            />   
        </div>
    );
};

export default Engineering;
