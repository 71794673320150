import React, { useCallback, useContext } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import BrowseContext from 'contexts/browseContext';
import { TableBody, TableCell } from '@material-ui/core';
import TRow from './TRow';
import { TableItem } from 'types/browse';

import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    tbody: {
        position: 'relative',
        '& tr:last-child:': {
            borderBottom: '0',
        },
    },
    td: {
        fontSize: '0.9em',
        padding: 0,
        paddingLeft: theme.spacing(1),
    },
    ul: {
        marginTop: 0,
    },
    menuWrapper: {
        width: '48px',
        textAlign: 'right',
        border: '1px dashed red',
    },
    content: {
        minHeight: '29px',
    },
    addTD: {
        padding: 0,
    },
    addP: {
        fontSize: '0.9em',
        margin: '14px 0'
    }
}));

interface TBodyProps {
    addRowContent: any;
}

const TBody: React.FC<TBodyProps> = ({addRowContent}) => {
    const classes = useStyles();
    const { isLoading, apiData, pageSize, tableStructure, urlPath, disableEmptyRows } = useContext(BrowseContext);
    
    const defaultFormatData = useCallback((item, fieldName) => item[fieldName], []);
    const numberOfEmptyRows = disableEmptyRows ? 0 : pageSize - (apiData?.length || 0);

    const emptyRows = 
            numberOfEmptyRows > 0
                ? [...Array(numberOfEmptyRows)].map((e, i) => (
                      <tr key={`empty${i}`}>
                          {tableStructure.map((item: TableItem, tdIndex: number) => (
                              <TableCell className={classes.td} key={`empty${i}_${tdIndex}`}>
                                  <div className={classes.content} />
                              </TableCell>
                          ))}
                      </tr>
                  ))
                : null;

    const addRow = (
        <tr key={`addRow`}>
            <TableCell className={classes.td} key={`addRow_cell_1`} />
            <TableCell className={classes.addTD} key={`addRow_cell_2`}>
                <Typography variant="body1" component="p" color="secondary" className={classes.addP}>
                    {addRowContent}
                </Typography> 
            </TableCell>
        </tr>
    );

    return (
        <TableBody className={classes.tbody}>
            {apiData && apiData.map((item: any, index:any) => (
                <TRow
                    key={index}
                    item={item}
                    id={index}
                    defaultFormatData={defaultFormatData}
                    tableStructure={tableStructure}
                    urlPath={urlPath}
                />
            ))}
            {!isLoading && addRowContent && addRow}
            {emptyRows}
        </TableBody>
    );
};

export default TBody;
