import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';

const InletSilencer = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Inlet Silencer</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.inletSilencerQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.inletSilencerType'
                type='text'
                control={control}
                label='Type'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.inletSilencerMaterial'
                type='text'
                control={control}
                 
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.inletSilencerDimensions'
                type='text'
                control={control}
                label='Dimensions'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField 
                control={control}
                name='attributes.inletSilencerMisc'
                label='Misc'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default InletSilencer;
