import { useEffect } from 'react';
import { TextField, Divider, Grid, Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import NumberFormat from 'components/NumberFormat';
import { MenuItem, ListSubheader, FormControl, InputLabel, Select, FormHelperText } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { useStyles } from './Form';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';

const CustomTableCell = ({ name, disabled, control, getValues, createdAt }) => {
    const classes = useStyles();
    return (
        <TableCell align="left" className={classes.tableCell}>
            {!disabled ? (
                <ReactHookTextField name={name} type="text" control={control} variant="standard" style={{ marginBottom: 0 }} />
            ) : (
                <TextField
                    type="text"
                    disabled={true}
                    className={classes.disabledInput}
                    variant="outlined"
                    InputLabelProps={{ shrink: true }}
                    size="small"
                    value={getValues(name)}
                    style={{ marginBottom: 0 }}
                />
            )}
        </TableCell>
    );
};

const Commercials = ({ control, jobData, quoteData, clientData, getValues, createdAt, watch, setValue }) => {
    const classes = useStyles();

    const invoiceTo = watch('attributes.commercialsInvoiceTo.id');
    const contactId = watch('attributes.commercialsContact.id');

    const { quoteID, revision, itemID, productAreaName, salesRepName, clientDetails, fanSerial, itemRef, project, enquirySourceName, attributes : { auraJobFileNo, nybSalesMemoNo }, industryName, applicationName } = quoteData;
    
    const invoiceAddresses = clientData.addresses
        .filter((e) => e.status !== 'Inactive' && e.addressTypeName === 'Invoice')
        .map(({ id, street1, street2, city, state, postcode, country, notes }) => {
            let street = [street1, street2?.trim() ?? ''].filter((s) => s != '').join(', ');
            return [
                <MenuItem key={`option_${id}`} value={id} className={classes.listItem}>
                    {street}, {city}, {state} {postcode}
                </MenuItem>,
                <ListSubheader className={classes.listItemExtra}>
                    {country}
                    <br />
                    {notes && <>Notes: {notes}</>}
                </ListSubheader>,
            ];
        });

    const contacts = clientData.contacts
        .filter((e) => e.status !== 'Inactive')
        .map(({ id, name, position, phone, phoneAlt, email, notes }) => {
            return [
                <MenuItem key={`option_${id}`} value={id} className={classes.listItem}>
                    {name}
                </MenuItem>,
                <ListSubheader className={classes.listItemExtra}>
                    Phone: {phone} {phoneAlt}
                    {position && (
                        <>
                            <br />
                            Position: {position}
                        </>
                    )}
                    {email && (
                        <>
                            <br />
                            Email: {email}
                        </>
                    )}
                    {notes && (
                        <>
                            <br />
                            Notes: {notes}
                        </>
                    )}
                </ListSubheader>,
            ];
        });

    useEffect(() => {
        if (invoiceTo) {
            const add = clientData.addresses.filter((e) => e.id === invoiceTo)[0];
            if (add) {
                setValue('attributes.commercialsInvoiceTo.city', add.city);
                setValue('attributes.commercialsInvoiceTo.country', add.country);
                setValue('attributes.commercialsInvoiceTo.postcode', add.postcode);
                setValue('attributes.commercialsInvoiceTo.state', add.state);
                setValue('attributes.commercialsInvoiceTo.street1', add.street1);
                setValue('attributes.commercialsInvoiceTo.street2', add.street2);
                setValue('attributes.commercialsInvoiceTo.email', add.email);
            }
        }
    }, [invoiceTo]);

    useEffect(() => {
        if (contactId) {
            const contact = clientData.contacts.filter((e) => e.id === contactId)[0];
            if (contact) {
                setValue('attributes.commercialsContact.name', contact.name);
                setValue('attributes.commercialsContact.position', contact.position);
                setValue('attributes.commercialsContact.phone', contact.phone);
                setValue('attributes.commercialsContact.phoneAlt', contact.phoneAlt);
                setValue('attributes.commercialsContact.notes', contact.notes);
                setValue('attributes.commercialsContact.email', contact.email);
            }
        }
    }, [contactId]);

    return (
        <div style={{ width: '100%' }}>
            <TextField
                label="Quote No"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={`${quoteID}${revision}${itemID}`}
                style={{ width: '8%', marginRight: '1%' }}
            />
            <TextField
                label="Product Area"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={productAreaName}
                style={{ width: '12%', marginRight: '1%' }}
            />
            <TextField
                label="Sales"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={salesRepName}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Client"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={clientDetails?.name}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <Controller
                name="attributes.commercialsInvoiceTo.id"
                type={'select'}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormControl variant="outlined" className={classes.input} style={{ width: '24%', marginRight: '1%' }} error={error}>
                        <InputLabel margin="dense" className={classes.selectLabel}>
                            Invoice To
                        </InputLabel>

                        <Select margin="dense" {...field}>
                            {invoiceAddresses}
                        </Select>

                        {error && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                )}
            />
            <TextField
                label="Invoice To Email"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={watch('attributes.commercialsInvoiceTo.email')}
                style={{ width: '20%', marginRight: '1%' }}
            />
            <br />
            <TextField
                label="Quote Contact Name"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={clientDetails?.contactDetails.name}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Quote Contact Landline"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={clientDetails?.contactDetails.phone}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Quote Contact Mobile"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={clientDetails?.contactDetails.phoneAlt}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Quote Contact Email"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={clientDetails?.contactDetails.email}
                style={{ width: '14%', marginRight: '1%' }}
            />

            <br />
            <Divider />

            <br />

            <TextField
                label="Job Created Date"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={new Date(createdAt).toLocaleDateString('en-AU')}
                style={{ width: '14%', marginRight: '1%' }}
            />

            <ReactHookTextField
                name="attributes.commercialsOrderNumber"
                type="text"
                control={control}
                label="Order Number"
                style={{ width: '14%', marginRight: '1%' }}
            />
            <ReactHookTextField
                name="attributes.commercialsOrderDate"
                type="date"
                control={control}
                label="Order Date"
                style={{ width: '14%', marginRight: '1%' }}
            />
            <ReactHookTextField
                name="attributes.commercialsDueDate"
                type="date"
                control={control}
                label="Due Date"
                style={{ width: '14%', marginRight: '1%' }}
            />
            <ReactHookTextField
                name="attributes.commercialsRevisedDueDate"
                type="date"
                control={control}
                label="Revised Due Date"
                style={{ width: '14%', marginRight: '1%' }}
            />
            <br />
            <TextField
                label="Prev Fan Serial"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                fullWidth
                size="small"
                style={{ width: '14%', marginRight: '1%' }}
                value={fanSerial}
            />
            <TextField
                label="Item Ref"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={itemRef}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Project"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={project}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Aura Job File No"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={auraJobFileNo}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="NYB Sales Memo No"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={nybSalesMemoNo}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Industry"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={industryName}
                style={{ width: '24%', marginRight: '1%' }}
            />
            <TextField
                label="Application"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={applicationName }
                style={{ width: '14%', marginRight: '1%' }}
            />
            <Controller
                name="attributes.commercialsContact.id"
                type={'select'}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormControl variant="outlined" className={classes.input} style={{ width: '14%', marginRight: '1%' }} error={error}>
                        <InputLabel margin="dense" className={classes.selectLabel}>
                            Job Contact
                        </InputLabel>

                        <Select margin="dense" {...field}>
                            {contacts}
                        </Select>

                        {error && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                )}
            />

            <TextField
                label="Job Contact Landline"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={watch('attributes.commercialsContact.phone')}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Job Contact Mobile"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={watch('attributes.commercialsContact.phoneAlt')}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Job Contact Email"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={watch('attributes.commercialsContact.email')}
                style={{ width: '22%', marginRight: '1%' }}
            />

            <Divider />
            <br />
            <br />

            <ReactHookTextField
                label="Combined Sell Price"
                name="combinedPrice"
                type="text"
                control={control}
                style={{ width: '15%', marginRight: '1%' }}
                InputProps={{
                    inputComponent: NumberFormat,
                }}
            />

            <ReactHookTextField
                label="Combined Estimated Cost"
                name="combinedCost"
                type="text"
                control={control}
                style={{ width: '15%', marginRight: '1%' }}
                InputProps={{
                    inputComponent: NumberFormat,
                }}
            />

            <ReactHookTextField
                label="Commercials Note"
                name="commercialsNotice"
                type="text"
                control={control}
                style={{ width: '30%', marginRight: '1%' }}
            />

            <TextField
                label="Enquiry Source"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={enquirySourceName}
                style={{ width: '14%', marginRight: '1%' }}
            />

            <Divider />
            <br />

            <ReactHookTextField
                label="Warranty Period (Months)"
                name="attributes.commercialsWarrantyPeriod"
                type="text"
                control={control}
                style={{ width: '14%', marginRight: '1%' }}
            />

            <ReactHookTextField
                label="Warranty From"
                name="attributes.commercialsWarrantyFrom"
                type="date"
                control={control}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <ReactHookTextField
                label="Retentions"
                name="attributes.commercialsRetentions"
                type="text"
                control={control}
                style={{ width: '19%', marginRight: '1%' }}
            />
            <ReactHookTextField
                label="Penalties"
                name="attributes.commercialsPenalties"
                type="text"
                control={control}
                style={{ width: '19%', marginRight: '1%' }}
            />
            <ReactHookTextField
                label="Terms"
                name="attributes.commercialsTerms"
                type="text"
                control={control}
                style={{ width: '39%', marginRight: '1%' }}
                InputProps={{
                    multiline: true,
                }}
            />
            <ReactHookTextField
                label="Extra"
                name="attributes.commercialsExtra"
                type="text"
                control={control}
                style={{ width: '39%', marginRight: '1%' }}
                InputProps={{
                    multiline: true,
                }}
            />

            <Divider />
            <br />

            <Grid container spacing={2}>
                <Grid item xs={4}>
                    <Table className={classes.table}>
                        <caption className={classes.caption}>Job Hours</caption>

                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.head} align="left" />
                                <TableCell className={classes.head} align="left">
                                    Quoted
                                </TableCell>
                                <TableCell className={classes.head} align="left">
                                    Allowed
                                </TableCell>
                                <TableCell className={classes.head} align="left">
                                    Actual
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            <TableRow key={1}>
                                <TableCell className={classes.selectTableCell}> Eng</TableCell>
                                <CustomTableCell {...{ name: 'attributes.commercialsEngQuoted', control }} />
                                <CustomTableCell {...{ name: 'attributes.commercialsEngAllowed', disabled: true, getValues }} />
                                <CustomTableCell {...{ name: 'attributes.commercialsEngActual', disabled: true, getValues }} />
                            </TableRow>
                            <TableRow key={2}>
                                <TableCell className={classes.selectTableCell}> Fab</TableCell>
                                <CustomTableCell {...{ name: 'attributes.commercialsFabQuoted', control }} />
                                <CustomTableCell {...{ name: 'attributes.commercialsFabAllowed', disabled: true, getValues }} />
                                <CustomTableCell {...{ name: 'attributes.commercialsFabActual', disabled: true, getValues }} />
                            </TableRow>
                            <TableRow key={3}>
                                <TableCell className={classes.selectTableCell}> Fit</TableCell>
                                <CustomTableCell {...{ name: 'attributes.commercialsFitQuoted', control }} />
                                <CustomTableCell {...{ name: 'attributes.commercialsFitAllowed', disabled: true, getValues }} />
                                <CustomTableCell {...{ name: 'attributes.commercialsFitActual', disabled: true, getValues }} />
                            </TableRow>
                            <TableRow key={4}>
                                <TableCell className={classes.selectTableCell}> QA</TableCell>
                                <CustomTableCell {...{ name: 'attributes.commercialsQaQuoted', control }} />
                                <CustomTableCell {...{ name: 'attributes.commercialsQaAllowed', control }} />
                                <CustomTableCell {...{ name: 'attributes.commercialsQaActual', control }} />
                            </TableRow>
                        </TableBody>
                    </Table>
                </Grid>

                <Grid item xs={8}>
                    <Table className={classes.table}>
                        <caption className={classes.caption}>Commercials Log</caption>

                        <TableHead>
                            <TableRow>
                                <TableCell className={classes.head}>Date</TableCell>
                                <TableCell className={classes.head}>User</TableCell>
                                <TableCell className={classes.head}>Combined Price</TableCell>
                                <TableCell className={classes.head}>Combined Cost</TableCell>
                                <TableCell className={classes.head}>Note</TableCell>
                                
                            </TableRow>
                        </TableHead>
                        <TableBody>
                                    {jobData.jobCommercialsChanges.length > 0 ? jobData.jobCommercialsChanges.map((change, i) => (
                                        <TableRow key={i}>
                                            <TableCell className={classes.tableCell}>
                                                {new Date(change.changedAt).toLocaleDateString("en-AU", { day: '2-digit', month: '2-digit', year: '2-digit' })}
                                            </TableCell>
                                            <TableCell className={classes.tableCell} style={{width: "15%"}}>{change.changedUserName}</TableCell>
                                            <TableCell className={classes.tableCell} style={{width: "10%"}}>{change.combinedPrice}</TableCell>
                                            <TableCell className={classes.tableCell} style={{width: "10%"}}>{change.combinedCost}</TableCell>
                                            <TableCell className={classes.tableCell} style={{width: "60%"}}>{change.commercialsNotice}</TableCell>
                                            
                                        </TableRow>
                                    )) : null}
                                </TableBody>
                    </Table>
                </Grid>
            </Grid>
        </div>
    );
};

export default Commercials;
