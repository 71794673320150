import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const Hub = ({ control, tableView }) => {
    
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Hub</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.hubQty'
                type='text'
                control={control}
                 
                label='Qty'
                style={{width: '9%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.hubStyle'
                type='text'
                control={control}
                 
                label='Style'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.hubSize'
                type='text'
                control={control}
                 
                label='Size'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.hubSupport'
                type='text'
                control={control}
                 
                label='Support'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.hubSupportThickness'
                type='text'
                control={control}
                 
                label='Support Thickness'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default Hub;
