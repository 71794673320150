import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    heading: {
        textAlign: 'center',
    },
    submit: {
        width: '100%',
    },
    input: {
        '& .MuiOutlinedInput-input': {
            backgroundColor: '#fff',
        },
        '& .MuiInputBase-formControl': {
            overflow: 'hidden',
        },
    },
}));

export default useStyles;
