import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const OutletDamper = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Outlet Damper</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.outletDamperQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.outletDamperType'
                type='text'
                control={control}
                label='Type'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.outletDamperMaterial'
                type='text'
                control={control}
                 
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.outletDamperBlades'
                type='text'
                control={control}
                label='Blades'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.outletDamperNoBlades'
                type='text'
                control={control}
                label='No. of blades'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.outletDamperBearings'
                type='text'
                control={control}
                label='Bearings'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.outletDamperControl'
                type='text'
                control={control}
                label='Control'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.outletDamperNotes'
                type='textarea'
                control={control}
                label='Notes'
                style={{width: '24%', marginRight: '1%'}}
                InputProps={{
                    multiline:true
                }}
            />
        </>   
    );
};

export default OutletDamper;
