import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { addFeedback } from 'actions';
import AddEditForm from 'components/AddEditForm';
import BrowseData from 'components/BrowseData';
import BrowseProvider from 'providers/browse';
import { useDispatch, useTrackedState } from 'store';

import {
    SCBLOWERS,
    formStructure,
    formValidation,
    apiBasePath,
    submitAddTransform,
    submitUpdateTransform,
    browseConfig,
} from './constants';

import { Button, Grid } from '@material-ui/core';
import { axiosInstance } from 'App';

import { saveAs } from 'file-saver';

interface ContentProps {
    action: string;
    id: string;
}

const Content: React.FC<ContentProps> = ({ action, id }) => {
    const dispatch = useDispatch();
    const { browseConfig: stateBrowseConfig } = useTrackedState();
    const history = useHistory();
    const { link } = SCBLOWERS;
    const { pageSize, ...parseBrowseConfig } = useMemo(() => JSON.parse(JSON.stringify(stateBrowseConfig)), [stateBrowseConfig]);

    const browseDefaultConfig = useMemo(() => {
        const newConfig = parseBrowseConfig?.[browseConfig.dataId] || {};

        return { ...browseConfig, ...newConfig };
    }, [parseBrowseConfig]);

    if (!action) {
        return (
            <>
                <BrowseProvider config={browseDefaultConfig} pageSize={pageSize}>
                    <BrowseData />
                </BrowseProvider>

                <Grid container justify="flex-end">
                    <Button
                        variant='contained'
                        color='primary'
                        onClick={async () => {
                            const url = browseConfig.createApiPath(browseConfig.apiBasePath, 1, 10000000);
                            const res = await axiosInstance({ url });

                            if (res.data.length > 0) {
                                const csvHeaders = Object.keys(res.data[0]).join(",");
                                const csvData = res.data.map((item:any) => {
                                    Object.keys(item).forEach((key:any) => {
                                        item[key] = String(item[key]).replace(/,/g, "");
                                    });

                                    return Object.values(item).join(",")
                                }).join('\n');

                                const blob = new Blob([
                                    `${csvHeaders} \n` +
                                    csvData
                                ], {type: "text/plain;charset=utf-8"});
                                saveAs(blob, `report.csv`);
                            } else {
                                dispatch(addFeedback('No results found matching filter to export', 'error'));
                            }
                        }}
                    >
                        Export to .csv
                    </Button>
                </Grid>   
            </>
        );
    }

    if (['edit', 'add'].includes(action)) {
        const apiPath = action === 'edit' ? `${apiBasePath}/${id}` : apiBasePath;
        const apiSubmitPath = action === 'edit' ? apiPath : apiBasePath;

        return (
            <AddEditForm
                apiPath={apiPath}
                formStructure={formStructure}
                formValidation={formValidation}
                submitOptions={{
                    apiPath: apiSubmitPath,
                    method: 'post',
                    transform: action === 'edit' ? submitUpdateTransform : submitAddTransform,
                    callback: (submitResponse: any) => {
                        const { data } = submitResponse;
                        const { id } = data || {};
                        if (id) {
                            const message = `Side Channel Blower was successfully ${action === 'edit' ? 'updated' : 'added'}`;
                            dispatch(addFeedback(message, 'success'));
                            history.push(`${link}/edit/${id}`);
                        } else {
                            dispatch(addFeedback(submitResponse?.title || submitResponse, 'error', submitResponse?.errors));
                        }
                    },
                }}
            >
            </AddEditForm>
        );
    }

    return null;
};

export default Content;
