import { useEffect, useState } from 'react';
import { useAuth } from 'providers/auth';
import makePost from 'helpers/makePost';
import { Typography, Link } from '@material-ui/core';

const QuicksightLink = () => {
    const { currentSession } = useAuth();
    const [ssoLink, setSsoLink] = useState('#');

    const createSsoLink = async () => {
        const session = await currentSession();

        const result = await makePost({
            url: 'users/quicksightToken',
            method: 'POST',
            data: {
                idToken: session.idToken.jwtToken,
            },
        });

        let signinToken = result.data.signinToken;
        let quicksightUrlEncoded = encodeURIComponent('https://quicksight.aws.amazon.com/');
        let thisUrlEncoded = encodeURIComponent('https://' + window.location.hostname);
        let link =
            'https://signin.aws.amazon.com/federation?Action=login&Issuer=' +
            thisUrlEncoded +
            '&Destination=' +
            quicksightUrlEncoded +
            '&SigninToken=' +
            signinToken;
        console.log(link);
        setSsoLink(link);
    };

    useEffect(() => {
        createSsoLink().catch((err) => {
          console.log("Not authorized for", err)
          setSsoLink("")
        });
    }, []);

    return ssoLink == '#' ? (
        <Typography variant="h2">Loading...</Typography>
    ) : ssoLink == '' ? (
        <Typography variant="h2">Not authorized to view reports</Typography>
    ) : (
        <Typography variant="h2">
            <Link href={ssoLink} target='_blank'>Click to open Quicksight (external)</Link>
        </Typography>
    );
};
export default QuicksightLink;
