import { DrawerProps } from 'types/drawer';
import { Action } from 'types/redux';

export const initialDrawerState: DrawerProps = {
    idField: 'toggleDrawer',
    open: false,
};

const drawerReducer = (state: DrawerProps = initialDrawerState, { type, ...payload }: Action): DrawerProps => {
    switch (type) {
        case 'TOGGLE_DRAWER':
            return { ...state, open: !state.open };
        case 'OPEN_DRAWER':
            return { ...state, open: true };
        case 'CLOSE_DRAWER':
            return { ...state, open: false };
        default:
            return state;
    }
};

export default drawerReducer;
