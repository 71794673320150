import { Document, Page, Text, View } from '@react-pdf/renderer';
import { styles, formatter } from '../PDFStyles';
import PDFHeader from '../PDFHeader';
import PDFFooter from '../PDFFooter';

const PDF = ({ quoteData, quoteDetails }) => {
    const { clientDetails, lineItems, freightCost, attributes: { extraText, leadTime, deliveryTerm, isOemPrice }, validity, productAreaID  } = quoteDetails;
    
    const calcTotal = quoteDetails.lineItems.map(e => (isOemPrice ? e.attributes.oemPrice : e.attributes.listPrice) * e.quantity).reduce((a, b) => a + b, 0) + (isNaN(freightCost) ? 0 : parseInt(freightCost));
    
    const Aerotech = productAreaID === 7 || productAreaID === 8;
    
    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <PDFHeader Aerotech={Aerotech} />
                
                <Text style={styles.quoteNo}>Quote No {quoteData.quoteID}{quoteData.revision}{quoteData.itemID}</Text>

                <View style={[styles.flex, styles.tableHeader]}>
                    <View style={styles.w20}>
                        <Text style={styles.tableHeaderLabel}>Company Name:</Text>
                        {Boolean(clientDetails.contactDetails.name) && <Text style={styles.tableHeaderLabel}>Attention:</Text> }
                        {Boolean(quoteData.project) && <Text style={styles.tableHeaderLabel}>Project Reference:</Text> }
                        {Boolean(clientDetails.contactDetails.email) && <Text style={styles.tableHeaderLabel}>Email:</Text> }
                    </View>
                    <View style={styles.w40}>
                        <Text style={styles.tableHeaderText}>{clientDetails.name}</Text>
                        {Boolean(clientDetails.contactDetails.name) && <Text style={styles.tableHeaderText}>{clientDetails.contactDetails.name}</Text> }
                        {Boolean(quoteData.project) && <Text style={styles.tableHeaderText}>{quoteData.project}</Text> }
                        {Boolean(clientDetails.contactDetails.email) && <Text style={styles.tableHeaderText}>{clientDetails.contactDetails.email}</Text> }
                    </View>
                    <View style={styles.w20}>
                        <Text style={styles.tableHeaderLabel}>Date:</Text>
                        <Text style={styles.tableHeaderLabel}>Prepared By:</Text>
                    </View>
                    <View style={styles.w20}>
                        <Text style={styles.tableHeaderText}>{new Date(quoteData.createdAt).toLocaleDateString('en-AU')}</Text>
                        <Text style={styles.tableHeaderText}>{quoteData.salesRepName}</Text>
                    </View>
                </View>

                <View style={[styles.flex, styles.table]}>
                    <View style={styles.w20}>
                        <Text style={styles.tableLabel}>Part No</Text>
                    </View>
                    <View style={styles.w45}>
                        <Text style={styles.tableLabel}>Description</Text>
                    </View>
                    <View style={styles.qty}>
                        <Text style={styles.tableLabel}>Qty</Text>
                    </View>
                    <View style={styles.priceHeader}>
                        <Text style={styles.tableLabel}>Unit Price</Text>
                    </View>
                    <View style={styles.priceHeader}>
                        <Text style={styles.tableLabel}>Total</Text>
                    </View>
                </View>

                {lineItems.map((b) => {
                    const price = isOemPrice ? b.attributes.oemPrice : b.attributes.listPrice;

                    return (
                        <View style={[styles.flex, styles.table]}>
                            <View style={styles.w20}>
                                <Text style={styles.tableText}>{b.product.attributes.model}</Text>
                            </View>
                            <View style={styles.w45}>
                                <Text style={styles.tableText}>{b.product.description}</Text>
                            </View>
                            <View style={styles.qty}>
                                <Text style={styles.tableText}>{b.quantity}</Text>
                            </View>
                            <View style={styles.price}>
                                <Text style={styles.tableText}>{formatter.format(price)}</Text>
                            </View>
                            <View style={styles.price}>
                                <Text style={styles.tableText}>{formatter.format(price*b.quantity)}</Text>
                            </View>
                        </View>
                    )
                })}

                {freightCost > 0 && (
                    <View style={[styles.flex, styles.table]}>
                        <View style={styles.w20}>
                            <Text style={styles.tableText}>Freight</Text>
                        </View>
                        <View style={styles.w45}>
                        </View>
                        <View style={styles.qty}>
                            <Text style={styles.tableText}>1</Text>
                        </View>
                        <View style={styles.price}>
                            <Text style={styles.tableText}>{formatter.format(freightCost)}</Text>
                        </View>
                        <View style={styles.price}>
                            <Text style={styles.tableText}>{formatter.format(freightCost)}</Text>
                        </View>
                    </View>
                )}

                <View style={[styles.flex, styles.total]}>
                    <View style={styles.w85}>
                        <Text style={styles.totalText}>Total (GST excluded) - AUD</Text>
                    </View>
                    <View style={styles.price}>
                        <Text style={styles.tableText}>{formatter.format(calcTotal)}</Text>
                    </View>
                </View>

                <View style={[styles.flex, styles.comments]}>
                    <Text>
                        {extraText}
                    </Text>                
                </View>

                <View style={[styles.flex, styles.extra]}>
                    <View style={styles.w20}>
                        {Boolean(leadTime) && <Text style={styles.tableHeaderLabel}>Lead Time</Text>}
                        {Boolean(deliveryTerm) && <Text style={styles.tableHeaderLabel}>Delivery Terms</Text>}
                        {Boolean(validity) && <Text style={styles.tableHeaderLabel}>Validity</Text>}
                    </View>
                    <View style={styles.w30}>
                        {Boolean(leadTime) && <Text style={styles.tableHeaderText}>{leadTime}</Text>}
                        {Boolean(deliveryTerm) && <Text style={styles.tableHeaderText}>{deliveryTerm}</Text>}
                        {Boolean(validity) && <Text style={styles.tableHeaderText}>{validity}</Text>}
                    </View>
                </View>

                <PDFFooter Aerotech={Aerotech} />
            </Page>
        </Document>
    );
};

export default PDF;
