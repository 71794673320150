import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Typography } from '@material-ui/core';
import Button from 'components/Button';
import { Formik } from 'formik';
import { initialLoginValues, loginFormStructure, loginFormValidation } from 'constants/auth';
import Input from 'components/Input';
import useStyles from './inputStyles';
import { addFeedback } from 'actions';
import { useDispatch } from 'store';
import { useAuth } from 'providers/auth';

export interface LoginFormProps {
    showReset: any;
    showChallenge: any;
}

const LoginForm: React.SFC<LoginFormProps> = ({ showReset, showChallenge }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { login }: any = useAuth();

    return (
        <Box>
            <Typography variant="h5" component="h2" className={classes.heading}>
                Sign in to your account
            </Typography>
            <Formik
                initialValues={initialLoginValues}
                validationSchema={loginFormValidation}
                onSubmit={async ({ username, password }, actions) => {
                    const { message, severity, user } = await login(username, password);
                    if (user) {
                        showChallenge(user);
                    } else {
                        dispatch(addFeedback(message, severity));
                    }
                }}
            >
                {(props) => {
                    const { handleSubmit, isSubmitting } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <table style={{ margin: 'auto' }}>
                                <tbody>
                                    {loginFormStructure.map(({ key, ...rest }) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <Input {...rest} className={classes.input} variant="outlined" />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td>
                                            <p>
                                                Forgot your password?{' '}
                                                <Link to="#" onClick={showReset}>
                                                    Reset password
                                                </Link>
                                            </p>
                                            <Button type="submit" disabled={isSubmitting} className={classes.submit} size="large">
                                                Sign In
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default LoginForm;
