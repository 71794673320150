import React from 'react';
import { Divider } from '@material-ui/core';
import makePost from 'helpers/makePost';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import { ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { openDialog, closeDialog } from 'actions/dialog';
import MenuButton from 'components/MenuButton';
import Form from 'components/Form';
import * as Yup from 'yup';

export const contactFormStructure = (data) => {
    const addresses = [];

    for (const i of data) {
        if (i.status !== 'Inactive') {
            addresses.push({value: i.id, title: `${i.street1} ${i.city} ${i.state} ${i.postcode}` });
        }
    }

    return [
        {
            rowTitle: 'Name',
            key: 'name',
            elements: [{ key: 'name', label: 'Name', fieldName: 'name', inputType: 'string' }],
        },
        {
            rowTitle: 'Office',
            key: 'phone',
            elements: [{ key: 'phone', label: 'Office Phone', fieldName: 'phone', inputType: 'string' }],
        },
        {
            rowTitle: 'Mobile',
            key: 'phoneAlt',
            elements: [{ key: 'phoneAlt', label: 'Mobile Phone', fieldName: 'phoneAlt', inputType: 'string' }],
        },
        {
            rowTitle: 'Email',
            key: 'email',
            elements: [{ key: 'email', label: 'Email', fieldName: 'email', inputType: 'email' }],
        },
        {
            rowTitle: 'Position',
            key: 'position',
            elements: [{ key: 'position', label: 'Position', fieldName: 'position', inputType: 'string' }],
        },
        {
            rowTitle: 'Notes',
            key: 'notes',
            elements: [{ key: 'notes', label: 'Notes', fieldName: 'notes', inputType: 'textarea' }],
        },
        {
            rowTitle: 'Assigned Addresses',
            key: 'addresses',
            elements: [
                { 
                    key: 'addresses', label: 'Assigned Addresses', fieldName: 'addresses', inputType: 'multipleSelect', options: addresses, defaultValue:[]
                }
            ],
        },
        {
            actionType: 'add',
            elements: [
                {
                    inputType: 'hidden',
                    fieldName: 'fax',
                    key: 'fax',
                },
                {
                    inputType: 'hidden',
                    fieldName: 'clientID',
                },
                {
                    inputType: 'submit',
                    fieldName: 'add',
                    key: 'addContact',
                    title: 'Add Contact',
                },
            ],
        },
        {
            actionType: 'edit',
            elements: [
                {
                    inputType: 'hidden',
                    fieldName: 'clientID',
                    key: 'clientID',
                },
                {
                    inputType: 'hidden',
                    key: 'updatedAtKey',
                    fieldName: 'updatedAt',
                },
                { inputType: 'submit', key: 'updateContact', fieldName: 'update', title: 'Update Contact' },
            ],
        },
    ]
};

export const contactFormValidation = Yup.object().shape({
    name: Yup.string().required('Name required'),
});

export const ContactAdd = ({ addresses, id, setRefresh }) => {
    const dispatch = useDispatch();

    return (
        <Form
            action='add'
            formStructure={contactFormStructure(addresses)}
            formValidation={contactFormValidation}
            submitOptions={{
                name: 'Contact',
                apiPath: '/contacts',
                transform: data => ({...data, clientID: parseInt(id)}),
                method: 'post',
                callback: ({data}) => {
                    if (data.id) {
                        dispatch(addFeedback('Contact was successfully added', 'success'));
                        setRefresh(true);
                        dispatch(closeDialog());
                    } else {
                        dispatch(addFeedback(`There was an error adding the contact`, 'error'));
                    }
                },
            }}
        />
    );
};

export const ContactEdit = ({ addresses, data, setRefresh }) => {
    const dispatch = useDispatch();
    
    if (data.status === 'Inactive') {
        return null;
    }
    
    const handleEditContact = () => {
        const content = (
            <Form
                action='edit'
                data={data}
                formStructure={contactFormStructure(addresses)}
                formValidation={contactFormValidation}
                submitOptions={ {
                    apiPath: `/contacts/${data.id}`,
                    method: 'post',
                    transform: data => data,
                    callback: (res) => {
                        if (res.status === 200) {
                            const message = `Contact was successfully updated`;
                            dispatch(addFeedback(message, 'success'));
                            setRefresh(true);
                            dispatch(closeDialog());
                        } else {
                            dispatch(addFeedback(`There was an error updating the contact - ${res}`, 'error'));
                        }
                    },
                }}
            />
        );

        dispatch(openDialog('Edit Contact', content, [], {maxWidth: 'md', fullWidth: true}));
    };

    const handleRemoveContact = async () => {
        const { status } = await makePost({
            url: `/contacts/${data.id}`,
            data: { ...data, status: 'Inactive' },
        });

        if (status === 200) {
            dispatch(addFeedback('Contact successfully removed', 'success'));
            setRefresh(true);
            dispatch(closeDialog());
        } else {
            dispatch(addFeedback('There was an error removing the contact', 'error'));
        }
    };

    const menuOptions = [
        { title: 'Edit Contact', key: 'editContactButton', onClick: handleEditContact },
        { title: 'Remove Contact', key: 'removeContactButton', onClick: handleRemoveContact },
    ];
    
    return (
        <>
            <Divider />
            <ListItem disableGutters>
                <ListItemText>
                    {data.name && <strong>{data.name}</strong>}
                    {data.email && (
                        <tr>
                            <th>Email:</th>
                            <td>
                                <a href={`mailto:${data.email}`}>{data.email}</a>
                            </td>
                        </tr>
                    )}
                    {data.phone && (
                        <tr>
                            <th>Office:</th>
                            <td>
                                <a href={`tel:${data.phone}`}>{data.phone}</a>
                            </td>
                        </tr>
                    )}
                    {data.phoneAlt && (
                        <tr>
                            <th>Mobile:</th>
                            <td>
                                <a href={`tel:${data.phoneAlt}`}>{data.phoneAlt}</a>
                            </td>
                        </tr>
                    )}
                    {data.position && (
                        <tr>
                            <th>Position:</th>
                            <td>{data.position}</td>
                        </tr>
                    )}
                    {data.notes && (
                        <tr>
                            <th>Notes:</th>
                            <td>{data.notes}</td>
                        </tr>
                    )}
                </ListItemText>
                <ListItemSecondaryAction>
                    <MenuButton menuOptions={menuOptions} />
                </ListItemSecondaryAction>
            </ListItem>
        </>
    )
};
