import React from 'react';
import { Snackbar } from '@material-ui/core';

import Alert from './Alert';
import { useDispatch, useTrackedState } from 'store';

const Feedback = () => {
    const dispatch = useDispatch();
    const { feedback } = useTrackedState();
    const { message, severity, open, errors = [] } = feedback;
    const toggleFeedback = () => dispatch({ type: 'TOGGLE_FEEDBACK' });

    return (
        <Snackbar open={open} autoHideDuration={15000} onClose={toggleFeedback}>
            <Alert onClose={toggleFeedback} severity={severity} errors={errors}>
                {message}
            </Alert>
        </Snackbar>
    );
};

export default Feedback;
