import * as Yup from 'yup';
import { appEnv } from 'helpers/app';

let config = {
    region: 'ap-southeast-2',
    identityPoolId: 'ap-southeast-2:79a794e2-d151-48b5-a871-c09eaa0c09e8',
    userPoolId: 'ap-southeast-2_zTk2Q5neg',
    userPoolWebClientId: '5o315tjn5jkj3chshjtm0ci32d',
    oAuthDomain: 'staging-aireng',
    oAuthRedirectSignIn: 'https://staging.phoenix.aireng.com.au/',
    oAuthRedirectSignOut: 'https://staging.phoenix.aireng.com.au/',
};

if (appEnv === 'production') {
    config = {
        identityPoolId: 'ap-southeast-2:79a794e2-d151-48b5-a871-c09eaa0c09e8',
        region: 'ap-southeast-2',
        userPoolId: 'ap-southeast-2_SkeqoAIYa',
        userPoolWebClientId: '43dvqoebta563fso2d9kibsr91',
        oAuthDomain: 'production-aireng',
        oAuthRedirectSignIn: 'https://phoenix.aireng.com.au/',
        oAuthRedirectSignOut: 'https://phoenix.aireng.com.au/',
    };
}

// TODO: Setup for staging-aireng and production-aireng for staging/production
export const appAuth = {
    Auth: {
        // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
        identityPoolId: config.identityPoolId, 

        // REQUIRED - Amazon Cognito Region
        region: config.region,

        // OPTIONAL - Amazon Cognito Federated Identity Pool Region
        // Required only if it's different from Amazon Cognito Region
        //identityPoolRegion: 'XX-XXXX-X',

        // OPTIONAL - Amazon Cognito User Pool ID
        userPoolId: config.userPoolId,

        // OPTIONAL - Amazon Cognito Web Client ID (26-char alphanumeric string)
        userPoolWebClientId: config.userPoolWebClientId,

        // OPTIONAL - Enforce user authentication prior to accessing AWS resources or not
        // mandatorySignIn: false,

        // // OPTIONAL - Configuration for cookie storage
        // // Note: if the secure flag is set to true, then the cookie transmission requires a secure protocol
        // cookieStorage: {
        // // REQUIRED - Cookie domain (only required if cookieStorage is provided)
        //     domain: '.yourdomain.com',
        // // OPTIONAL - Cookie path
        //     path: '/',
        // // OPTIONAL - Cookie expiration in days
        //     expires: 365,
        // // OPTIONAL - Cookie secure flag
        // // Either true or false, indicating if the cookie transmission requires a secure protocol (https).
        //     secure: true
        // },

        // // OPTIONAL - customized storage object
        // storage: MyStorage,

        // // OPTIONAL - Manually set the authentication flow type. Default is 'USER_SRP_AUTH'
        // authenticationFlowType: 'USER_PASSWORD_AUTH',

        // // OPTIONAL - Manually set key value pairs that can be passed to Cognito Lambda Triggers
        // clientMetadata: { myCustomKey: 'myCustomValue' },

        // OPTIONAL - Hosted UI configuration
        oauth: {
            domain: config.oAuthDomain,
            scope: ['phone', 'email', 'profile', 'openid', 'aws.cognito.signin.user.admin'],
            redirectSignIn: config.oAuthRedirectSignIn,
            redirectSignOut: config.oAuthRedirectSignOut,
            responseType: 'code', // or 'token', note that REFRESH token will only be generated when the responseType is code
        },
    },
};

// Login Form
export const initialLoginValues = {
    username: '',
    password: '',
};

export const loginFormStructure = [
    {
        key: 'username',
        name: 'username',
        title: 'Username',
        label: 'Enter your Username',
    },
    {
        key: 'password',
        name: 'password',
        title: 'Password',
        label: 'Enter your Password',
        type: 'password',
    },
];

export const loginFormValidation = Yup.object().shape({
    password: Yup.string().required('Password required'),
    username: Yup.string().required('Username required'),
});

// Reset Form
export const initialResetValues = {
    username: '',
};

export const resetFormValidation = Yup.object().shape({
    username: Yup.string().required('Username required'),
});

// Code Form
export const initialCodeValues = {
    code: '',
    new_password: '',
};

export const codeFormStructure = [
    {
        key: 'code',
        name: 'code',
        title: 'Code',
        label: 'Enter your Code',
    },
    {
        key: 'new_password',
        name: 'new_password',
        title: 'Password',
        label: 'Enter your Password',
        type: 'password',
    },
];

export const codeFormValidation = Yup.object().shape({
    code: Yup.string().required('Code required'),
    new_password: Yup.string().required('Password required'),
});

// Force password change
export const forceChangeStructure = [
    {
        key: 'newPassword',
        name: 'newPassword',
        title: 'Password',
        label: 'Enter your new password',
        type: 'password',
    }
];

export const initialForceChangeValues = {
    newPassword: '',
    family_name: '',
    given_name: ''
};

export const forceChallengeValidation = Yup.object().shape({
    newPassword: Yup.string().required('Password required'),
});
