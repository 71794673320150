import React, { useState, useEffect } from 'react';
import BrowseContext from 'contexts/browseContext';
import { createFilterValues } from 'components/BrowseData/DataTable/helpers';
import { Config } from 'types/browse';
import { axiosInstance } from 'App';

interface BrowseProviderProps {
    children: any;
    config: Config;
    pageSize?: number | undefined;
    disableEmptyRows?: boolean
}

const BrowseProvider: React.FC<BrowseProviderProps> = ({ children, config, pageSize = 50, disableEmptyRows = false }) => {
    const [filterChanged, setFilterChanged] = useState(false);

    const {
        createApiPath,
        apiBasePath,
        tableStructure,
        subTableStructure,
        groupTitle = '',
        showFilter = false,
        idField = 'id',
        urlPath,
        dataId,
        transform,
    } = config;

    const filterStructure = subTableStructure?.[groupTitle] || tableStructure;

    const [apiData, setApiData] = useState<any | undefined>();
    const [totalPages, setTotalPages] = useState<number | undefined>();
    const [totalRecords, setTotalRecords] = useState();
    const [pageNum, setPageNum] = useState(config.pageNum || 1);
    const [isLoading, setLoading] = useState(true);
    const [rowNumberOptions, setRowNumberOptions] = useState([50, 100, 'All']);
    const [filterValues, setFilterValues] = useState(config?.filterValues || createFilterValues(filterStructure));
    const [apiUrl, setApiUrl] = useState(createApiPath(apiBasePath, pageNum, pageSize, filterValues));
    const [sortValue, setSortValue] = useState({
        fieldName: tableStructure[0].fieldName,
        sortOrder: 'asc',
    });

    useEffect(() => {
        if (apiData) {
            const numRecords = totalRecords || apiData.length;
            const newTotalPages = Math.ceil(numRecords / pageSize);

            if (filterChanged) {
                setFilterChanged(false);
                setPageNum(1);
            }

            if (newTotalPages && totalPages !== newTotalPages) {
                setTotalPages(newTotalPages);
            }
        }
    }, [apiData, totalRecords, filterChanged, setFilterChanged, totalPages, pageSize]);

    useEffect(() => {
        setApiUrl(createApiPath(apiBasePath, pageNum, pageSize, filterValues));
    }, [apiBasePath, createApiPath, filterValues, pageNum, pageSize]);

    useEffect(() => {
        setLoading(true);

        async function fetchData() {
            const result = await axiosInstance({ url: apiUrl});
            
            if (transform){
                setApiData(transform(result));
            } else {
                setApiData(result.data);
            }

            setLoading(false);
        }

        fetchData()
    }, [apiUrl]);

    const handleFilterChange = (target: { name: any; value: any }) => {
        const { name, value } = target;
        setFilterChanged(true);

        const filterFieldName = name.split('_')[0];
        const newFilterValues = { ...createFilterValues(filterStructure), [filterFieldName]: value };

        setFilterValues(newFilterValues);
    };

    const clearFilter = (fieldName: string) => {
        const newFilterValues = { ...createFilterValues(filterStructure), [fieldName]: '' };

        setFilterValues(newFilterValues);
        setFilterChanged(true);
    };

    const contextValue = {
        dataId,
        apiUrl,
        apiData,
        isLoading,
        totalPages,
        pageNum,
        setPageNum,
        pageSize,
        disableEmptyRows,
        rowNumberOptions,
        setRowNumberOptions,
        filterValues,
        setFilterValues,
        tableStructure,
        subTableStructure,
        groupTitle,
        sortValue,
        setSortValue,
        showFilter,
        idField,
        handleFilterChange,
        clearFilter,
        urlPath,
    };

    return <BrowseContext.Provider value={contextValue}>{children}</BrowseContext.Provider>;
};

export default BrowseProvider;
