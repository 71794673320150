import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const InletBox = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Inlet Box</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.inletBoxQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.inletBoxMaterial'
                type='text'
                control={control}
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.inletBoxAspectBox'
                type='text'
                control={control}
                 
                label='Aspect Ratio'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.inletBoxNotes'
                type='textarea'
                control={control}
                label='Notes'
                style={{width: '24%', marginRight: '1%'}}
                InputProps={{
                    multiline:true
                }}
            />
        </>   
    );
};

export default InletBox;
