import { Document, Page, Text, View } from '@react-pdf/renderer';
import { styles, formatter } from '../PDFStyles';
import PDFHeader from '../PDFHeader';
import PDFFooter from '../PDFFooter';

const PDF = ({ quoteData, quoteDetails }) => {
    const { lineItems, extraText, validity, freightCost, productAreaID, attributes, clientDetails} = quoteDetails;
    const calcTotal = lineItems.map(e => e.sellPrice * e.quantity).reduce((a, b) => a + b, 0) + (isNaN(freightCost) ? 0 : parseInt(freightCost));

    const Aerotech = productAreaID === 7 || productAreaID === 8;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <PDFHeader Aerotech={Aerotech} />
                
                <Text style={styles.quoteNo}>Quote No {quoteData.quoteID}{quoteData.revision}{quoteData.itemID}</Text>

                <View style={[styles.flex, styles.tableHeader]}>
                    <View style={styles.w20}>
                        <Text style={styles.tableHeaderLabel}>Company Name:</Text>
                        {Boolean(clientDetails.contactDetails.name) && <Text style={styles.tableHeaderLabel}>Attention:</Text> }
                        {Boolean(quoteData.project) && <Text style={styles.tableHeaderLabel}>Project Reference:</Text> }
                        {Boolean(clientDetails.contactDetails.email) && <Text style={styles.tableHeaderLabel}>Email:</Text> }
                    </View>
                    <View style={styles.w40}>
                        <Text style={styles.tableHeaderText}>{clientDetails.name}</Text>
                        {Boolean(clientDetails.contactDetails.name) && <Text style={styles.tableHeaderText}>{clientDetails.contactDetails.name}</Text> }
                        {Boolean(quoteData.project) && <Text style={styles.tableHeaderText}>{quoteData.project}</Text> }
                        {Boolean(clientDetails.contactDetails.email) && <Text style={styles.tableHeaderText}>{clientDetails.contactDetails.email}</Text> }
                    </View>
                    <View style={styles.w20}>
                        <Text style={styles.tableHeaderLabel}>Date:</Text>
                        <Text style={styles.tableHeaderLabel}>Prepared By:</Text>
                    </View>
                    <View style={styles.w20}>
                        <Text style={styles.tableHeaderText}>{new Date(quoteData.createdAt).toLocaleDateString('en-AU')}</Text>
                        <Text style={styles.tableHeaderText}>{quoteData.salesRepName}</Text>
                    </View>
                </View>

                <View style={[styles.flex, styles.table]}>

                    <View style={styles.w20}>
                        <Text style={[styles.tableHeaderText, {fontSize: 13}]}>Fan Model:</Text>
                        <Text style={[styles.tableHeaderText, styles.bold]}>Duty:</Text>
                    </View>
                    <View style={styles.w80}>
                        <Text style={[styles.tableHeaderText, {fontSize: 13}]}>{attributes.fanDesign} {attributes.fanSize}</Text>
                        <Text style={[styles.tableHeaderText, styles.bold]}> </Text>
                    </View>
                </View>

                <View style={[styles.flex, styles.table]}>

                    <View style={styles.w20}>
                        {Boolean(attributes.fanVolume) && <Text style={styles.tableHeaderLabel}>Volume:</Text>}
                        {Boolean(attributes.fanInletPressure) && <Text style={styles.tableHeaderLabel}>Inlet Pressure:</Text>}
                        {Boolean(attributes.fanDischargePressure) && <Text style={styles.tableHeaderLabel}>Discharge Pressure:</Text>}
                        {Boolean(attributes.impellerDiameter) && <Text style={styles.tableHeaderLabel}>Impeller Dia. mm:</Text>}
                        {Boolean(attributes.fanDrive) && <Text style={styles.tableHeaderLabel}>Drive Type:</Text>}
                        {Boolean(attributes.impellerType) && <Text style={styles.tableHeaderLabel}>Rotor Type:</Text>}
                    </View>
                    <View style={styles.w30}>
                        {Boolean(attributes.fanVolume) && <Text style={styles.tableHeaderText}>{attributes.fanVolume} {attributes.fanVolumeLabel}</Text>}
                        {Boolean(attributes.fanInletPressure) && <Text style={styles.tableHeaderText}>{attributes.fanInletPressure} {attributes.fanPressureLabel}</Text>}
                        {Boolean(attributes.fanDischargePressure) && <Text style={styles.tableHeaderText}>{attributes.fanDischargePressure} {attributes.fanPressureLabel}</Text>}
                        {Boolean(attributes.impellerDiameter) && <Text style={styles.tableHeaderText}>{attributes.impellerDiameter}</Text>}
                        {Boolean(attributes.fanDrive) && <Text style={styles.tableHeaderText}>{attributes.fanDrive} Arrangement {attributes.fanArrangement}</Text>}
                        {Boolean(attributes.impellerType) && <Text style={styles.tableHeaderText}>{attributes.impellerType}</Text>}
                    </View>
                    <View style={styles.w20}>
                        {Boolean(attributes.fanRpm) && <Text style={styles.tableHeaderLabel}>RPM:</Text>}
                        {Boolean(attributes.fanDesignTempC) && <Text style={styles.tableHeaderLabel}>Design Temp (C):</Text>}
                        {Boolean(attributes.fanDutyKW) && <Text style={styles.tableHeaderLabel}>Duty kW:</Text>}

                    </View>
                    <View style={styles.w30}>
                        {Boolean(attributes.fanRpm) && <Text style={styles.tableHeaderText}>{attributes.fanRpm}</Text>}
                        {Boolean(attributes.fanDesignTempC) && <Text style={styles.tableHeaderText}>{attributes.fanDesignTempC}</Text>}
                        {Boolean(attributes.fanDutyKW) && <Text style={styles.tableHeaderText}>{attributes.fanDutyKW}</Text>}
                    </View>
                </View>

                <View style={[styles.flex, styles.table]}>
                    <View style={styles.w20}>
                        <Text style={styles.tableHeaderLabel}>Items Included In Price:</Text>
                    </View>
                    <View style={styles.w80}>
                        <Text style={[styles.tableHeaderText, {lineHeight: '1.5px'}]}>{attributes.fanOther}</Text>
                    </View>
                </View>

                {(Boolean(attributes.internalCoatingType) || Boolean(attributes.externalCoatingType) || Boolean(attributes.externalCoatingColour)) &&
                    <>
                        <View style={[styles.flex, styles.table, styles.marginTop10]}>
                            <View style={styles.w20}>
                                <Text style={[styles.tableHeaderText, styles.bold]}>Finishing</Text>
                                {Boolean(attributes.internalCoatingType) && <Text style={styles.tableHeaderLabel}>Internal:</Text>}
                                {Boolean(attributes.externalCoatingType) && <Text style={styles.tableHeaderLabel}>External:</Text>}
                                {Boolean(attributes.externalCoatingColour) && <Text style={styles.tableHeaderLabel}>Colour:</Text>}
                            </View>
                            <View style={styles.w80}>
                                <Text style={styles.tableHeaderText}> </Text>
                                {Boolean(attributes.internalCoatingType) && <Text style={styles.tableHeaderText}>{attributes.internalCoatingType}</Text>}
                                {Boolean(attributes.externalCoatingType) && <Text style={styles.tableHeaderText}>{attributes.externalCoatingType}</Text>}
                                {Boolean(attributes.externalCoatingColour) && <Text style={styles.tableHeaderText}>{attributes.externalCoatingColour}</Text>}
                            </View>
                        </View>
                        <br/>
                    </>
                }   
                <View style={[styles.flex, styles.table, styles.marginTop10]}>
                    <View style={styles.w20}>
                        <Text style={[styles.tableHeaderText, styles.bold]}>Motor:</Text>
                        {Boolean(attributes.motorKW) && <Text style={styles.tableHeaderLabel}>kW:</Text>}
                        {Boolean(attributes.motorPowerSupply) && <Text style={styles.tableHeaderLabel}>Power Supply:</Text>}
                        {Boolean(attributes.motorPole) && <Text style={styles.tableHeaderLabel}>Pole:</Text>}
                    </View>
                    <View style={styles.w30}>
                        <Text style={styles.tableHeaderText}> </Text>
                        {Boolean(attributes.motorKW) && <Text style={styles.tableHeaderText}>{attributes.motorKW}</Text>}
                        {Boolean(attributes.motorPowerSupply) && <Text style={styles.tableHeaderText}>{attributes.motorPowerSupply}</Text>}
                        {Boolean(attributes.motorPole) && <Text style={styles.tableHeaderText}>{attributes.motorPole}</Text>}
                    </View>
                    <View style={styles.w20}>
                        <Text style={styles.tableHeaderText}> </Text>
                        {Boolean(attributes.motorSpeed) && <Text style={styles.tableHeaderLabel}>Speed:</Text>}
                        {Boolean(attributes.motorProtection) && <Text style={styles.tableHeaderLabel}>Protection:</Text>}
                        {Boolean(attributes.motorDesign) && <Text style={styles.tableHeaderLabel}>Design:</Text>}
                    </View>
                    <View style={styles.w30}>
                        <Text style={styles.tableHeaderText}> </Text>
                        {Boolean(attributes.motorSpeed) && <Text style={styles.tableHeaderText}>{attributes.motorSpeed}</Text>}
                        {Boolean(attributes.motorProtection) && <Text style={styles.tableHeaderText}>{attributes.motorProtection}</Text>}
                        {Boolean(attributes.motorDesign) && <Text style={styles.tableHeaderText}>{attributes.motorDesign}</Text>}
                    </View>
                </View>

                {Boolean(attributes.motorOther) && <View style={[styles.flex, styles.table, styles.marginTop10]}>
                    <View style={styles.w20}>
                        <Text style={styles.tableHeaderLabel}>Motor Extras:</Text>
                    </View>
                    <View style={styles.w80}>
                        <Text style={styles.tableHeaderText}>{attributes.motorOther}</Text>
                    </View>
                </View> }
                
                <View style={[styles.flex, styles.table, styles.marginTop10]}>
                    <View style={styles.w45}>
                        <Text style={styles.tableLabel}>Pricing (Prices are Nett, Excluding GST.)</Text>
                    </View>
                    <View style={styles.qty}>
                        <Text style={styles.tableLabel}>Qty</Text>
                    </View>
                    <View style={styles.priceHeader}>
                        <Text style={styles.tableLabel}>Unit Price</Text>
                    </View>
                    <View style={styles.priceHeader}>
                        <Text style={styles.tableLabel}>Total</Text>
                    </View>
                </View>

                <View style={[styles.flex, styles.table]}>
                    <View style={styles.w45}>
                    </View>
                    <View style={styles.qty}>
                        <Text style={styles.tableText}>{attributes.fanQty}</Text>
                    </View>
                    <View style={styles.priceHeader}>
                        <Text style={styles.tableText}>{formatter.format(attributes.fanSellPrice)}</Text>
                    </View>
                    <View style={styles.priceHeader}>
                        <Text style={styles.tableText}>{formatter.format(attributes.fanQty*attributes.fanSellPrice)}</Text>
                    </View>
                </View>
                
                <View style={[styles.flex, styles.table, styles.marginTop10]}>
                    <View style={styles.w45}>
                        <Text style={[styles.tableHeaderText, styles.bold]}>Other Options:</Text>
                    </View>
                </View>

                {lineItems.map((sp) => {
                    return (
                        <View style={[styles.flex, styles.table]}>
                            <View style={styles.w45}>
                                <Text style={styles.tableLabel}>{sp.description}</Text>
                            </View>
                            <View style={styles.qty}>
                                {Boolean(sp.quantity) && <Text style={styles.tableText}>{sp.quantity}</Text>}
                            </View>
                            <View style={styles.price}>
                                {Boolean(sp.price) && <Text style={styles.tableText}>{formatter.format(sp.price)}</Text>}
                            </View>
                            <View style={styles.price}>
                                {(Boolean(sp.price) && Boolean(sp.quantity)) && <Text style={styles.tableText}>{formatter.format(sp.price*sp.quantity)}</Text>}
                            </View>
                        </View>
                    )
                })}

                {Boolean(attributes.extraText) && <View style={[styles.flex, styles.table, styles.marginTop10]}>
                    <Text>
                        {attributes.extraText}
                    </Text>                
                </View> }

                <View style={[styles.flex, styles.table, styles.marginTop10]}>
                    <View style={styles.w20}>
                        {Boolean(attributes.leadTime) && <Text style={styles.tableHeaderLabel}>Lead Time:</Text>}
                        {Boolean(attributes.deliveryTerm) && <Text style={styles.tableHeaderLabel}>Delivery Terms:</Text>}
                        {Boolean(validity) && <Text style={styles.tableHeaderLabel}>Validity:</Text>}
                    </View>
                    <View style={styles.w30}>
                        {Boolean(attributes.leadTime) && <Text style={styles.tableHeaderText}>{attributes.leadTime}</Text>}
                        {Boolean(attributes.deliveryTerm) && <Text style={styles.tableHeaderText}>{attributes.deliveryTerm}</Text>}
                        {Boolean(validity) && <Text style={styles.tableHeaderText}>{validity}</Text>}
                    </View>
                </View>

                <PDFFooter Aerotech={Aerotech} Aftermarket={true} />
            </Page>
        </Document>
    );
};

export default PDF;
