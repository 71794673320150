import useStyles from '../styles';
import { useFormikContext, Field, FastField, getIn } from 'formik';
import { TextField } from '@material-ui/core';

const Input = ({ label, name, type, style, InputProps, onChange = false, fast = true }: any) => {
    const classes = useStyles();
    const { errors, touched }: any = useFormikContext();

    const optProps = {
      ...( onChange ? onChange : {} )  
    };

    const Component = fast ? FastField : Field;
    
    return (
        <Component
            label={label}
            name={name}
            type={type}
            style={style} 
            className={classes.input}
            as={TextField}
            error={getIn(touched, name) && getIn(errors, name)}
            helperText={getIn(touched, name) && getIn(errors, name)} 
            variant='outlined' 
            InputLabelProps={{ shrink: true }} 
            InputProps={InputProps}
            size='small'
            {...optProps}
        />
    );
};


export default Input;
