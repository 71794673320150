import React, { useState, useEffect } from 'react';
import { axiosInstance } from 'App';
import Form from './Form';

const FormWrapper = ({ id, action }) => {
    const [clientData, setClientData] = useState({
        name: '',
        abn: '', 
        addresses: [],
        contacts: [],
    });
    const [refresh, setRefresh] = useState(action !== 'add' ? true : false);

    useEffect(() => {
        const getData = async () => {
            const res = await axiosInstance({ url: `/clients/${id}` });
            
            setClientData(res.data);
        };

        if (refresh) {
            getData();
            setRefresh(false);
        }
    }, [refresh]);

    return ((clientData?.name &&  action == 'edit') || action == 'add') ? <Form id={id} clientData={clientData} setRefresh={setRefresh} action={action} /> : null;
};

export default FormWrapper;
