import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import SelectWithDataReactHook from 'components/SelectWithDataReactHook';

const Balancing = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Balancing</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.balanceQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.balanceRPM'
                type='text'
                control={control}
                label='Balance RPM'
                style={{width: '19%', marginRight: '1%'}}
            />
            <SelectWithDataReactHook
                name='attributes.balanceGrade'
                type='text'
                control={control}
                label='Grade'
                options={[
                    {
                        label: '1',
                        id: '1'
                    },
                    {
                        label: '2.5',
                        id: '2.5'
                    },
                    {
                        label: '6',
                        id: '6'
                    }
                ]}
                style={{width: '14%', marginRight: '1%'}}
            />
            <SelectWithDataReactHook
                name='attributes.balanceFixWeights'
                type='text'
                control={control}
                label='Fix Weights'
                options={[
                    {
                        label: 'Weld',
                        id: 'Weld'
                    },
                    {
                        label: 'Bolt On',
                        id: 'Bolt On'
                    }
                ]}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.balanceNotes'
                type='text'
                control={control}
                label='Notes'
                style={{width: '19%', marginRight: '1%'}}
            />  
        </>   
    );
};

export default Balancing;
