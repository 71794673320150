import useStyles from '../styles';
import { FormControl } from '@material-ui/core';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
  
const SelectWithData = ({ setFieldValue, label, name, options, style, reset, defaultValue}: any) => {
    const classes = useStyles();

    return (
        <FormControl variant='outlined' className={classes.input} style={style}>
            <Autocomplete
                key={reset}
                id="contact-autocomplete"
                defaultValue={defaultValue}
                options={options}
                groupBy={(option) => option.group }
                getOptionLabel={({ label }: any) => label}
                onChange={(e, data: any) => setFieldValue(name, data?.id ? data.id : '')}
                renderInput={(params) => (
                    <TextField
                        {...params}
                        fullWidth
                        label={label}
                        name={name}
                        variant="outlined"
                        InputLabelProps={{ shrink: true }}
                        margin="dense"
                        style={{margin: '0'}} 
                    />
                )}

                renderGroup={(params) => (
                    <li key={params.key}>
                        <div className={classes.groupLabel}>{params.group}</div>
                        <ul className={classes.groupList}>{params.children}</ul>
                    </li>
                )}
            />
        </FormControl>
    );
};

export default SelectWithData;
