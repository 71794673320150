import React from 'react';
import { Link } from 'react-router-dom';
import { Button, Box, Typography } from '@material-ui/core';
import { Formik } from 'formik';

import Input from 'components/Input';
import { initialResetValues, resetFormValidation } from 'constants/auth';
import useStyles from './inputStyles';
import { addFeedback } from 'actions';
import { useDispatch } from 'store';
import { useAuth } from 'providers/auth';

export interface ResetFormProps {
    showLogin: any;
    showCode: any;
    setResetUsername: any;
}

const ResetForm: React.SFC<ResetFormProps> = ({ showLogin, showCode, setResetUsername }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { passwordReminder }: any = useAuth();

    return (
        <Box>
            <Typography variant="h5" component="h2" className={classes.heading}>
                Reset your password
            </Typography>
            <Formik
                initialValues={initialResetValues}
                validationSchema={resetFormValidation}
                onSubmit={async ({ username }, actions) => {
                    const { message, severity } = await passwordReminder(username);
                    dispatch(addFeedback(message, severity));

                    if (severity === 'info') {
                        setResetUsername(username);
                        showCode();
                    }
                }}
            >
                {(props) => {
                    const { handleSubmit, isSubmitting } = props;

                    return (
                        <form onSubmit={handleSubmit}>
                            <table style={{ margin: 'auto' }}>
                                <tbody>
                                    <tr>
                                        <td>
                                            <Input
                                                name="username"
                                                title="Username"
                                                label="Enter your Username"
                                                className={classes.input}
                                                variant="outlined"
                                            />
                                        </td>
                                    </tr>
                                    <tr>
                                        <td>
                                            <p>
                                                Forgot your password?{' '}
                                                <Link to="#" onClick={showLogin} color="secondary">
                                                    Return to Login Form
                                                </Link>
                                            </p>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                color="primary"
                                                variant="contained"
                                                className={classes.submit}
                                                size="large"
                                            >
                                                Send Code
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default ResetForm;
