import { useState } from 'react';
import { Button, TextField, Typography, Grid, IconButton, Divider } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';
import ContentWrapper from 'components/ContentWrapper';
import makePost from 'helpers/makePost';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import { useForm, useFieldArray } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import { QuoteFields, quoteDefaultValues, quoteFormValidation, salesRepInitials } from '../../components/QuoteFields';
import Header from '../Header';
import NumberFormat from '../../../../components/NumberFormat';
import * as Yup from 'yup';
import ReactHookTextField from '../../../../components/ReactHookTextField';
import SelectWithDataReactHook from '../../../../components/SelectWithDataReactHook';
import Row from './Row';
import { pdf } from '@react-pdf/renderer';
import PDF from './PDF';
import { saveAs } from 'file-saver';
import { axiosInstance } from 'App';
import { useStyles } from '../../styles';
import Files from 'components/Files';
import CreateJob from 'components/CreateJob';

export const formValidation = Yup.object().shape({
    ...quoteFormValidation.fields,
    lineItems: Yup.array().of(Yup.object().shape({
        quantity: Yup.number().transform(value => (isNaN(value) ? undefined : value)).required('Qty is required'),
        price: Yup.number().transform(value => (isNaN(value) ? undefined : parseFloat(value.toFixed(2)))),
    })),
    attributes: Yup.object().shape({
        fanSellPrice: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        fanEstimatedCost: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        fanQty: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        impellerDiameter: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        fanVolume: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        fanInletPressure: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        fanDischargePressure: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        fanRpm: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        fanDesignTempC: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        fanDutyKW: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        motorKW: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        motorSpeed: Yup.number().transform(value => (isNaN(value) ? undefined : value)),
        designation: Yup.string().required('Designation required'),
    })
});

const Form = ({ quoteData, quoteDetails, itemList, revList, clientList, quoteStatusList, salesRepList, productCategoryList, productAreaList, enquirySourceList, industryList, applicationList }) => {
    const dispatch = useDispatch()
    const classes = useStyles();
    const { quoteID, itemID, revision } = quoteData;
    const { attributes, lineItems, validity } = quoteDetails;

    const [totalPrice, setTotalPrice] = useState(lineItems.map(e => e.quantity * e.price));
    
    const calcTotalPrice = () => totalPrice.reduce((a, b) => {   
        return (a + b);
    }, 0);

    const { handleSubmit, watch, control, setError, setValue, getValues } = useForm({
        resolver: yupResolver(formValidation),
        defaultValues: {
            ...quoteDefaultValues(quoteData),
            attributes: {
                ...attributes,
                deliveryTerm: attributes && attributes.deliveryTerm !== null ? attributes.deliveryTerm : "Ex. Works Melbourne"
            },
            lineItems: lineItems.length ? lineItems : [],
            validity: validity !== null ? validity : "30 Days Nett",
            updatedAt: quoteDetails.updatedAt
        } 
      });

    const { fields, append, remove } = useFieldArray({
        control,
        name: "lineItems"
      });

    const onSubmit = async data => {
        try {
            const response = await makePost({
                url: `/quotedetails/${quoteID}/${itemID}/${revision}/manualentryfans`,
                data: { 
                    ...data,
                    totalPrice: calcTotalPrice(),
                    combinedPrice: calcTotalPrice() + (data.attributes?.fanQty * data.attributes?.fanSellPrice || 0),
                    combinedCost: (data.attributes?.fanQty * data.attributes?.fanEstimatedCost || 0)
                },
            });

            if (response.status === 200) {
                setValue('updatedAt', response.data.updatedAt)
            }

            dispatch(addFeedback('Quote saved', 'success'));
        } catch (error) {
            const errData = error.response.data;

            if (errData === "Stale update. Reload and update again") {
                dispatch(addFeedback(errData, 'error'));
            } else {
                Object.keys(errData.errors).map((key) => {
                    setError(key.slice(2), errData.errors[key][0]);
                    return null;
                });
    
                dispatch(addFeedback(errData.title || errData, 'error', errData.errors));
            }
        }
    };


    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <Header quoteData={quoteData} itemList={itemList} revList={revList} productCategoryList={productCategoryList} getValues={getValues} />

            <ContentWrapper>
                <QuoteFields
                    control={control}
                    clientList={clientList}
                    quoteStatusList={quoteStatusList}
                    salesRepList={salesRepList}
                    productAreaList={productAreaList}
                    enquirySourceList={enquirySourceList}
                    industryList={industryList}
                    applicationList={applicationList}
                    watch={watch} 
                    setValue={setValue} />
            </ContentWrapper>
            
            <Typography variant="h2">
                Manual Entry Fan
            </Typography>

            <ContentWrapper>
                <Typography variant="h4" gutterBottom>
                    Model/Duty
                </Typography>
                <br />

                <SelectWithDataReactHook
                    name='attributes.designation'
                    type='text'
                    control={control}
                    required={true}
                    label='Designation'
                    options={[
                        {
                            label: 'Centrifugal Fan',
                            id: 'Centrifugal Fan'
                        },
                        {
                            label: 'Material Handling Fan',
                            id: 'Material Handling Fan'
                        },
                        {
                            label: 'Dust Fan',
                            id: 'Dust Fan'
                        },
                        {
                            label: 'Axial Flow Fan',
                            id: 'Axial Flow Fan'
                        },
                        {
                            label: 'Side Channel Blower',
                            id: 'Side Channel Blower'
                        }
                    ]}
                    style={{width: '19%', marginRight: '1%'}}
                /> 
                
                <Divider />
                <br />

                <ReactHookTextField
                    name='attributes.fanVolume'
                    type='text'
                    control={control}
                    label={`Volume`}
                    style={{width: '19%', marginRight: '1%'}}
                />
                <SelectWithDataReactHook
                    name='attributes.fanVolumeLabel'
                    type='text'
                    control={control}
                    label={`Volume Unit`}
                    options={[
                        {
                            label: 'CFM',
                            id: 'CFM'
                        },
                        {
                            label: 'M3/HR',
                            id: 'M3/HR'
                        },
                        {
                            label: 'M3/MIN',
                            id: 'M3/MIN'
                        },
                        {
                            label: 'M3/SEC',
                            id: 'M3/SEC'
                        },
                        {
                            label: 'L/S',
                            id: 'L/S'
                        }
                    ]}
                    style={{width: '19%', marginRight: '1%'}}
                />
                <ReactHookTextField
                    name='attributes.fanInletPressure'
                    type='text'
                    control={control}
                    label={`Inlet Pressure`}
                    style={{width: '19%', marginRight: '1%'}}
                />
                <ReactHookTextField
                    name='attributes.fanDischargePressure'
                    type='text'
                    control={control}
                    label={`Discharge Pressure`}
                    style={{width: '19%', marginRight: '1%'}}
                />
                <SelectWithDataReactHook
                    name='attributes.fanPressureLabel'
                    type='text'
                    control={control}
                    label={`Pressure Unit`}
                    options={[
                        {
                            label: 'IN WG',
                            id: 'IN WG'
                        },
                        {
                            label: 'MM W.G.',
                            id: 'MM W.G.'
                        },
                        {
                            label: 'KPA',
                            id: 'KPA'
                        },
                        {
                            label: 'PA',
                            id: 'PA'
                        },
                        {
                            label: 'MBAR',
                            id: 'MBAR'
                        }
                    ]}
                    style={{width: '19%', marginRight: '1%'}}
                />
                <ReactHookTextField
                    name='attributes.fanRpm'
                    type='text'
                    control={control}
                    label='RPM'
                    style={{width: '19%', marginRight: '1%'}}
                />
                <ReactHookTextField
                    name='attributes.fanDesignTempC'
                    type='text'
                    control={control}
                    label='Design Temp (C)'
                    style={{width: '19%', marginRight: '1%'}}
                />
                <ReactHookTextField
                    name='attributes.fanDutyKW'
                    type='text'
                    control={control}
                    label='Duty kW'
                    style={{width: '19%', marginRight: '1%'}}
                />

                <Divider />
                <br />
                
                <ReactHookTextField
                    name='attributes.fanQty'
                    type='text'
                    control={control}
                    label='Qty'
                    style={{width: '19%', marginRight: '1%'}}
                />
                <ReactHookTextField
                    name='attributes.fanDesign'
                    type='text'
                    control={control}
                    label='Design'
                    style={{width: '19%', marginRight: '1%'}}
                />
                <ReactHookTextField
                    name='attributes.fanSize'
                    type='text'
                    control={control}
                    label='Size'
                    style={{width: '19%', marginRight: '1%'}}
                />
                <ReactHookTextField
                    name='attributes.impellerDiameter'
                    type='text'
                    control={control}
                    label='Impeller Dia. mm'
                    style={{width: '19%', marginRight: '1%'}}
                />

                <SelectWithDataReactHook
                    name='attributes.fanDrive'
                    control={control}
                    label='Drive'
                    options={[
                        {
                            label: 'Belt',
                            id: 'Belt'
                        },
                        {
                            label: 'Direct',
                            id: 'Direct'
                        }
                    ]}
                    style={{width: '19%', marginRight: '1%'}}
                />         
                <SelectWithDataReactHook
                    name='attributes.fanArrangement'
                    type='text'
                    control={control}
                    label='Arrangement'
                    options={[
                        {
                            label: '1',
                            id: '1'
                        },
                        {
                            label: '2',
                            id: '2'
                        },
                        {
                            label: '4',
                            id: '4'
                        },
                        {
                            label: '4F',
                            id: '4F'
                        },
                        {
                            label: '9',
                            id: '9'
                        }
                    ]}
                    style={{width: '19%', marginRight: '1%'}}
                />         
                <SelectWithDataReactHook
                    name='attributes.fanIdRating'
                    type='text'
                    control={control}
                    label='ID Rating'
                    options={[
                        {
                            label: 'ID1',
                            id: 'ID1'
                        },
                        {
                            label: 'ID2',
                            id: 'ID2'
                        }
                    ]}
                    style={{width: '19%', marginRight: '1%'}}
                /> 
                <SelectWithDataReactHook
                    name='attributes.impellerType'
                    type='text'
                    control={control}
                    label='Type'
                    options={[
                        {
                            label: 'Backward Inclined',
                            id: 'Backward Inclined'
                        },
                        {
                            label: 'Laminar',
                            id: 'Laminar'
                        },
                        {
                            label: 'Axial',
                            id: 'Axial'
                        },
                        {
                            label: 'Aerofoil',
                            id: 'Aerofoil'
                        },
                        {
                            label: 'Radial',
                            id: 'Radial'
                        },
                        {
                            label: 'Radial Tip',
                            id: 'Radial Tip'
                        }
                    ]}
                    style={{width: '19%', marginRight: '1%'}}
                />          
                <br />
                <ReactHookTextField
                    name='attributes.fanOther'
                    type='text'
                    control={control}
                    label='Items Included In Price'
                    InputProps={{
                        multiline:true
                    }}
                    style={{width: '39%', marginRight: '1%'}}
                />

                <br />
                <br />
                <Divider />
                <br />

                <Typography variant="h4" gutterBottom>
                    Motor
                </Typography>
                <br />

                <ReactHookTextField
                    name='attributes.motorKW'
                    type='text'
                    control={control}
                    label='kW'
                    style={{width: '19%', marginRight: '1%'}}
                />    
                <ReactHookTextField
                    name='attributes.motorSpeed'
                    type='text'
                    control={control}
                    label='Speed'
                    style={{width: '19%', marginRight: '1%'}}
                />
                <SelectWithDataReactHook
                    name='attributes.motorPowerSupply'
                    type='text'
                    control={control}
                    label='Power Supply'
                    options={[
                        {
                            label: '415V/3PH/50HZ',
                            id: '415V/3PH/50HZ'
                        },
                        {
                            label: '240V/1PH/50HZ',
                            id: '240V/1PH/50HZ'
                        },
                        {
                            label: '380V/1PH/50HZ',
                            id: '380V/1PH/50HZ'
                        },
                        {
                            label: '480V/1PH/50HZ',
                            id: '480V/1PH/50HZ'
                        }
                    ]}
                    style={{width: '19%', marginRight: '1%'}}
                />                    
                <SelectWithDataReactHook
                    name='attributes.motorProtection'
                    type='text'
                    control={control}
                    label='Protection'
                    creatable={true}
                    options={[
                        {
                            label: 'TEFC - IP55',
                            id: 'TEFC - IP55'
                        },
                        {
                            label: 'D.I.P',
                            id: 'D.I.P'
                        }
                    ]}
                    style={{width: '19%', marginRight: '1%'}}
                />
                <SelectWithDataReactHook
                    name='attributes.motorPole'
                    type='text'
                    control={control}
                    label='Pole'
                    options={[
                        {
                            label: '2',
                            id: '2'
                        },
                        {
                            label: '4',
                            id: '4'
                        },
                        {
                            label: '6',
                            id: '6'
                        },
                        {
                            label: '8',
                            id: '8'
                        },
                        {
                            label: '10',
                            id: '10'
                        }
                    ]}
                    style={{width: '19%', marginRight: '1%'}}
                />
                <SelectWithDataReactHook
                    name='attributes.motorDesign'
                    type='text'
                    control={control}
                    label='Design'
                    creatable={true}
                    options={[
                        {
                            label: 'Ex e, Class 1, Zone 1',
                            id: 'Ex e, Class 1, Zone 1'
                        },
                        {
                            label: 'Ex d Flameproof',
                            id: 'Ex d Flameproof'
                        },
                        {
                            label: 'Thermistors',
                            id: 'Thermistors'
                        }
                    ]}
                    style={{width: '19%', marginRight: '1%'}}
                />
                           
                <ReactHookTextField
                    name='attributes.motorOther'
                    type='text'
                    control={control}
                    label='Other'
                    InputProps={{
                        multiline:true
                    }}
                    style={{width: '39%', marginRight: '1%'}}
                />
            
                <br />
                <br />
                <Divider />
                <br />

                <Typography variant="h4" gutterBottom>
                    Finishing
                </Typography>
                <br />

                <SelectWithDataReactHook
                    name='attributes.internalCoatingType'
                    type='text'
                    control={control}
                    label='Internal'
                    options={[
                        {
                            label: 'URE : Blast Class 2.5, 50-75µm intercure (Zn), 50µm interthane (URE) TDFT 100-125µm',
                            id: 'URE : Blast Class 2.5, 50-75µm intercure (Zn), 50µm interthane (URE) TDFT 100-125µm',
                        },
                        {
                            label: 'ZEP : Blast Class 2.5, 50-75µm interzinc 52 TDFT 50-75µm',
                            id: 'ZEP : Blast Class 2.5, 50-75µm interzinc 52 TDFT 50-75µm',
                        },
                        {
                            label: 'ZPH : Blast Class 2.5, 50-75µm intercure 200 TDFT 50-75µm',
                            id: 'ZPH : Blast Class 2.5, 50-75µm intercure 200 TDFT 50-75µm',
                        },
                        {
                            label: 'IOZ : Blast Class 2.5, 60-90µm interzinc 2280 TDFT 60-90µm',
                            id: 'IOZ : Blast Class 2.5, 60-90µm interzinc 2280 TDFT 60-90µm',
                        },
                        {
                            label: 'HBE : Blast Class 2.5, Two coats 125',
                            id: 'HBE : Blast Class 2.5, Two coats 125',
                        },
                        {
                            label: 'HBU : Blast Class 2.5, 50-75µm intercure (Zn), 125-175µm interplus, 50µm interthane (URE) TDFT 225-300µm',
                            id: 'HBU : Blast Class 2.5, 50-75µm intercure (Zn), 125-175µm interplus, 50µm interthane (URE) TDFT 225-300µm',
                        },
                        {
                            label: 'HTA : Blast Class 2.5, Two coats 25µm Intertherm (AL) TDFT 50µm',
                            id: 'HTA : Blast Class 2.5, Two coats 25µm Intertherm (AL) TDFT 50µm',
                        },
                        {
                            label: 'NIL : No Paint',
                            id: 'NIL : No Paint',
                        },
                        {
                            label: 'BST : Blast Only',
                            id: 'BST : Blast Only',
                        },
                        {
                            label: 'PAS : Passivate Weld Areas',
                            id: 'PAS : Passivate Weld Areas',
                        },
                        {
                            label: 'SCE : Blast Class 2.5, 125-150µm interplus TDFT 125-150µm',
                            id: 'SCE : Blast Class 2.5, 125-150µm interplus TDFT 125-150µm',
                        },
                        {
                            label: 'ABR : Abrasion resistant ceramic filled epoxy two coats CERAMKOTE TDFT 175-250µm',
                            id: 'ABR : Abrasion resistant ceramic filled epoxy two coats CERAMKOTE TDFT 175-250µm',
                        },
                        {
                            label: 'ENA : Standard enamel (Dulux Luxol 4QD or similar) 1 Coat + 1 Coat GOZP',
                            id: 'ENA : Standard enamel (Dulux Luxol 4QD or similar) 1 Coat + 1 Coat GOZP',
                        },
                        {
                            label: 'GOZ : 1 Coat GOZP',
                            id: 'GOZ : 1 Coat GOZP',
                        },
                        {
                            label: 'HAL : High Temperature AL Coating',
                            id: 'HAL : High Temperature AL Coating',
                        },
                        {
                            label: 'OTH : As per client specification',
                            id: 'OTH : As per client specification',
                        },
                        {
                            label: '1-Pack : Standard Primer',
                            id: '1-Pack : Standard Primer',
                        },
                        {
                            label: '1-Pack : Standard Primer + Standard Enamel',
                            id: '1-Pack : Standard Primer + Standard Enamel',
                        },
                        {
                            label: '2-Pack : Epoxy Primer – 75µm DFT',
                            id: '2-Pack : Epoxy Primer – 75µm DFT',
                        },
                        {
                            label: '2-Pack : Epoxy Primer + Polyurethane Top Coat – 125µm DFT',
                            id: '2-Pack : Epoxy Primer + Polyurethane Top Coat – 125µm DFT',
                        },
                        {
                            label: 'High Build : Epoxy Primer + Polyurethane Top Coat – 200-225µm DFT',
                            id: 'High Build : Epoxy Primer + Polyurethane Top Coat – 200-225µm DFT',
                        },
                        {
                            label: 'Hi-Temp : 2-Coats Silicone High Temp (Rated <435°C) – 40µm DFT',
                            id: 'Hi-Temp : 2-Coats Silicone High Temp (Rated <435°C) – 40µm DFT',
                        },
                    ]}
                    style={{width: '39%', marginRight: '1%'}}
                />    
                <SelectWithDataReactHook
                    name='attributes.externalCoatingType'
                    type='text'
                    control={control}
                    label='External'
                    options={[
                        
                        {
                            label: 'URE : Blast Class 2.5, 50-75µm intercure (Zn), 50µm interthane (URE) TDFT 100-125µm',
                            id: 'URE : Blast Class 2.5, 50-75µm intercure (Zn), 50µm interthane (URE) TDFT 100-125µm',
                        },
                        {
                            label: 'ZEP : Blast Class 2.5, 50-75µm interzinc 52 TDFT 50-75µm',
                            id: 'ZEP : Blast Class 2.5, 50-75µm interzinc 52 TDFT 50-75µm',
                        },
                        {
                            label: 'ZPH : Blast Class 2.5, 50-75µm intercure 200 TDFT 50-75µm',
                            id: 'ZPH : Blast Class 2.5, 50-75µm intercure 200 TDFT 50-75µm',
                        },
                        {
                            label: 'IOZ : Blast Class 2.5, 60-90µm interzinc 2280 TDFT 60-90µm',
                            id: 'IOZ : Blast Class 2.5, 60-90µm interzinc 2280 TDFT 60-90µm',
                        },
                        {
                            label: 'HBE : Blast Class 2.5, Two coats 125',
                            id: 'HBE : Blast Class 2.5, Two coats 125',
                        },
                        {
                            label: 'HBU : Blast Class 2.5, 50-75µm intercure (Zn), 125-175µm interplus, 50µm interthane (URE) TDFT 225-300µm',
                            id: 'HBU : Blast Class 2.5, 50-75µm intercure (Zn), 125-175µm interplus, 50µm interthane (URE) TDFT 225-300µm',
                        },
                        {
                            label: 'HTA : Blast Class 2.5, Two coats 25µm Intertherm (AL) TDFT 50µm',
                            id: 'HTA : Blast Class 2.5, Two coats 25µm Intertherm (AL) TDFT 50µm',
                        },
                        {
                            label: 'NIL : No Paint',
                            id: 'NIL : No Paint',
                        },
                        {
                            label: 'BST : Blast Only',
                            id: 'BST : Blast Only',
                        },
                        {
                            label: 'PAS : Passivate Weld Areas',
                            id: 'PAS : Passivate Weld Areas',
                        },
                        {
                            label: 'SCE : Blast Class 2.5, 125-150µm interplus TDFT 125-150µm',
                            id: 'SCE : Blast Class 2.5, 125-150µm interplus TDFT 125-150µm',
                        },
                        {
                            label: 'ABR : Abrasion resistant ceramic filled epoxy two coats CERAMKOTE TDFT 175-250µm',
                            id: 'ABR : Abrasion resistant ceramic filled epoxy two coats CERAMKOTE TDFT 175-250µm',
                        },
                        {
                            label: 'ENA : Standard enamel (Dulux Luxol 4QD or similar) 1 Coat + 1 Coat GOZP',
                            id: 'ENA : Standard enamel (Dulux Luxol 4QD or similar) 1 Coat + 1 Coat GOZP',
                        },
                        {
                            label: 'GOZ : 1 Coat GOZP',
                            id: 'GOZ : 1 Coat GOZP',
                        },
                        {
                            label: 'HAL : High Temperature AL Coating',
                            id: 'HAL : High Temperature AL Coating',
                        },
                        {
                            label: 'OTH : As per client specification',
                            id: 'OTH : As per client specification',
                        },
                        {
                            label: '1-Pack : Standard Primer',
                            id: '1-Pack : Standard Primer',
                        },
                        {
                            label: '1-Pack : Standard Primer + Standard Enamel',
                            id: '1-Pack : Standard Primer + Standard Enamel',
                        },
                        {
                            label: '2-Pack : Epoxy Primer – 75µm DFT',
                            id: '2-Pack : Epoxy Primer – 75µm DFT',
                        },
                        {
                            label: '2-Pack : Epoxy Primer + Polyurethane Top Coat – 125µm DFT',
                            id: '2-Pack : Epoxy Primer + Polyurethane Top Coat – 125µm DFT',
                        },
                        {
                            label: 'High Build : Epoxy Primer + Polyurethane Top Coat – 200-225µm DFT',
                            id: 'High Build : Epoxy Primer + Polyurethane Top Coat – 200-225µm DFT',
                        },
                        {
                            label: 'Hi-Temp : 2-Coats Silicone High Temp (Rated <435°C) – 40µm DFT',
                            id: 'Hi-Temp : 2-Coats Silicone High Temp (Rated <435°C) – 40µm DFT',
                        },
                    ]}
                    style={{width: '39%', marginRight: '1%'}}
                />  
                <ReactHookTextField
                    name='attributes.externalCoatingColour'
                    type='text'
                    control={control}
                    label='Colour'
                    style={{width: '19%', marginRight: '1%'}}
                />

                <br />
                <br />
                <Divider />
                <br />

                <Typography variant="h4" gutterBottom>
                    Commercials
                </Typography>
                <br />

                <ReactHookTextField
                    name='attributes.fanEstimatedCost'
                    type='text'
                    control={control}
                    label='Fan Estimated Cost'
                    style={{width: '19%', marginRight: '1%'}}
                    InputProps={{
                        inputComponent: NumberFormat,
                    }}
                />

                <ReactHookTextField
                    name='attributes.fanSellPrice'
                    type='text'
                    control={control}
                    label='Fan Sell Price'
                    style={{width: '19%', marginRight: '1%'}}
                    InputProps={{
                        inputComponent: NumberFormat,
                    }}
                />
            </ContentWrapper>
            
            <ContentWrapper>
                <Typography variant="h4" gutterBottom>
                    Other Options
                </Typography>
                <br />

                { !fields.length ? 
                    <Button
                        variant='contained'
                        color='primary'
                        type="button"
                        onClick={() => append({})}
                    >
                        Add an Option
                    </Button> :
                    <>
                        {fields.map((field, index) => {
                            return (
                                <Row control={control} index={index} field={field} setTotalPrice={setTotalPrice} totalPrice={totalPrice} /> 
                        )})}
                        
                        <div style={{textAlign: 'right'}}>
                            <IconButton
                                type="button"
                                color="secondary"
                                onClick={() => {
                                    setTotalPrice(totalPrice.slice(0, fields.length-1));
                                    return remove(fields.length-1);
                                }}
                                style={{width: '4%'}}
                                >
                                <RemoveIcon />
                            </IconButton>
                            
                            <IconButton
                                type="button"
                                color="secondary"
                                onClick={() => append({})}
                                style={{width: '4%'}}
                                >
                                <AddIcon />
                            </IconButton>
                        </div>
                
                        <Divider />
                        <br />
                        <div style={{display: 'flex'}}>
                            <TextField
                                label='Total Price'
                                type='text'
                                disabled={true}
                                className={classes.disabledInput}
                                variant='outlined' 
                                InputLabelProps={{ shrink: true }} 
                                size='small'
                                value={calcTotalPrice()}
                                style={{width: '175px', marginLeft: 'auto'}}
                                InputProps={{
                                    inputComponent: NumberFormat,
                                }}
                            />
                        </div>
                    </>
                }
            </ContentWrapper>
            
            <ContentWrapper>

                <ReactHookTextField
                    name='validity'
                    type='text'
                    control={control}
                    label='Validity'
                    style={{width: '19%', marginRight: '1%'}}
                />

                <ReactHookTextField
                    name='attributes.leadTime'
                    type='text'
                    control={control}
                    label='Lead Time'
                    style={{width: '19%', marginRight: '1%'}}
                />

                <ReactHookTextField
                    name='attributes.deliveryTerm'
                    type='text'
                    control={control}
                    label='Delivery Term'
                    style={{width: '19%', marginRight: '1%'}}
                />

                <ReactHookTextField
                    name='attributes.extraText'
                    type='textarea'
                    control={control}
                    label='Extra Text'
                    style={{width: '100%'}}
                    InputProps={{
                        multiline:true
                    }}
                />
            </ContentWrapper>

            <Grid container justify="flex-end">
                <Button
                    variant='contained'
                    color='secondary'
                    type='submit'
                >
                    Save Quote 
                </Button>
                &nbsp;
                <Button
                    variant='contained'
                    color='primary'
                    type='submit'
                    onClick={async () => {
                            const quoteRes = await axiosInstance({ url: `/quotes/${quoteID}/${itemID}/${revision}` });
                            const quoteDetailsRes = await axiosInstance({ url: `/quotedetails/${quoteID}/${itemID}/${revision}/customfans` });
                            const quoteData = quoteRes.data;
                            
                            const blob = await pdf((
                                <PDF quoteData={quoteData} quoteDetails={quoteDetailsRes.data}/>
                            )).toBlob();
                            
                            const initials = quoteData.salesRepName ? salesRepInitials(quoteData.salesRepName) : 'NoSalesRep';
                                
                            saveAs(blob, `${quoteID}${revision}${itemID}${initials}.pdf`);
                    }}
                >
                    Generate PDF 
                </Button>
                &nbsp;
                <CreateJob url={`/jobs/new/${quoteID}/${revision}/${itemID}`} />
            </Grid>

            <Typography variant="h2">
                Files
            </Typography>

            <ContentWrapper style={{display: 'flex'}}>
                <Files url={`/quotes/${quoteID}/${itemID}/${revision}/files`} />
            </ContentWrapper>
        </form>
    );
};

export default Form;
