import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';

const OutletFlexible = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Outlet Flexible</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.outletFlexibleQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.outletFlexibleMaterial'
                type='text'
                control={control}
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.outletFlexibleFlowLiners'
                label='Flow Liners'   
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.outletFlexibleMisc'
                type='text'
                control={control}
                label='Misc'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default OutletFlexible;
