import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';

const Liners = ({ control, tableView}) => {
    return (
        <>
             {!tableView ? <Typography variant="h6" gutterBottom>Liner Set</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.linerQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '9%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.linerThickness'
                type='text'
                control={control}
                label='Liners Thickness'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.linerMaterial'
                type='text'
                control={control}
                label='Liners Material'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.linerBladesPrecentage'
                type='text'
                control={control}
                label='Liners Blades %'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.linerBackPlate'
                label='Back Plate Liner'
                style={{width: '14%', marginRight: '1%'}}
            />
        </>   
    );
};

export default Liners;
