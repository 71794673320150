export const initialBrowseConfigState = {
    pageSize: 50,
};

type Action = { type: string; id: string };

const browseConfigReducer = (state = initialBrowseConfigState, { type, id, ...payload }: Action): any => {
    switch (type) {
        case 'SET_BROWSE_CONFIG_DATA':
            return { ...state, [id]: payload };
        default:
            return state;
    }
};

export default browseConfigReducer;
