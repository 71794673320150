import React, { useContext, useEffect } from 'react';
import ContentWrapper from '../ContentWrapper';
import DataTable from './DataTable';
import BrowseContext from 'contexts/browseContext';
import { useDispatch } from 'store';
import { setBrowseConfigData } from 'actions';

interface BrowseDataProps {
    addRowContent?: any;
    minWidth?: any;
}

const BrowseData: React.FC<BrowseDataProps> = ({addRowContent, minWidth}) => {
    const dispatch = useDispatch();
    const { isLoading, pageNum, filterValues, dataId } = useContext(BrowseContext);
    
    useEffect(() => {
        return () => {
            dispatch(setBrowseConfigData(dataId, pageNum, filterValues));
        };
    }, [dataId, dispatch, filterValues, pageNum]);
    

    return <ContentWrapper isBusy={isLoading}><DataTable addRowContent={addRowContent} minWidth={minWidth} /></ContentWrapper>;
};

export default BrowseData;
