import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const External = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>External Finish</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.externalCoatingQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.externalCoatingType'
                type='textarea'
                control={control}
                label='Type'
                style={{width: '19%', marginRight: '1%'}}
                InputProps={{
                    multiline:true
                }}
            />
            <ReactHookTextField
                name='attributes.externalCoatingReport'
                type='text'
                control={control}
                label='Ext Report'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.externalCoatingITP'
                type='text'
                control={control}
                label='Ext ITP'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.externalCoatingColour'
                type='text'
                control={control}
                label='Colour'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default External;
