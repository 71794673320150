import { useContext } from 'react';
import { Button, Grid } from '@material-ui/core';
import ContentHeader from 'components/ContentHeader';
import BrowseData from 'components/BrowseData';
import BrowseProvider from 'providers/browse';
import { Formik } from 'formik';
import BrowseContext from 'contexts/browseContext';
import { useDispatch } from 'store';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { pdf } from '@react-pdf/renderer';
import PDF from './PDF';
import { saveAs } from 'file-saver';
import { axiosInstance } from 'App';
import { salesRepInitials } from '../../Quotes/components/QuoteFields';
import addWorkHours from 'components/AddWorkHours';
import { formatter } from '../../Quotes/Edit/PDFStyles';
import { EditableField } from '../../../components/EditableField';
import { getApiPath } from 'helpers/app';

export const EditableJobField = ({ id, item, fieldName, formatData }) => {
    return (
        <EditableField
            id={id}
            item={item}
            fieldName={fieldName}
            formatData={formatData}
            resourceUrl={`${getApiPath()}Jobs/${item.jobID}/CustomFans`}
        />
    );
};

export const EditableJobAttributeField = ({ id, item, fieldName, formatData }) => {
    var formatData = (item) => (item[fieldName] ? item[fieldName] : item.attributes?.[fieldName]);
    return <EditableJobField id={id} item={item} fieldName={`attributes.${fieldName}`} formatData={formatData} />;
};

const browseConfig = {
    dataId: 'jobs',
    apiBasePath: "/jobs/wip",
    createApiPath: (path, pageNum, pageSize, filterValues) => {
        return path;
    },
    tableStructure: [
        {
            title: '',
            align: 'left',
            actions: [{ title: 'Edit Job', idField: 'jobID', action: 'edit', path: "/jobs" }],
            sticky: true,
        },
        {
            title: 'Job Status',
            fieldName: 'jobStatusName',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Production Allocated',
            fieldName: 'productionAllocated',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Latest Status Note',
            fieldName: 'jobStatusNotice',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Job No',
            fieldName: 'jobID',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Company Name',
            fieldName: 'clientName',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'PS',
            fieldName: 'engineeringDraftsPerson',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.engineeringDraftsPerson != null && salesRepInitials(item.engineeringDraftsPerson),
        },
        {
            title: 'Quote No',
            fieldName: 'quoteID',
            showFilter: false,
            showSort: false,
            formatData: (item) =>
                `${item['quoteID']}${item['revision']}${item['itemID']}${
                    item.salesRepName != null ? salesRepInitials(item.salesRepName) : ''
                }`,
        },
        {
            title: 'Comments',
            fieldName: 'commercialsExtra',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Production Notes',
            fieldName: 'productionNotes',
            showFilter: false,
            component: EditableJobAttributeField,
            showSort: false,
        },
        {
            title: 'Qty',
            fieldName: 'fanQty',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Design',
            fieldName: 'fanDesign',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Size',
            fieldName: 'fanSize',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Arr',
            fieldName: 'fanArrangement',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Order Date',
            fieldName: 'commercialsOrderDate',
            showFilter: false,
            showSort: false,
            formatData: (item) => {
                const d = item.commercialsOrderDate;
                return d ? `${d.slice(8, 10)}/${d.slice(5, 7)}/${d.slice(0, 4)}` : '';
            },
        },
        {
            title: 'Eng Start Date',
            fieldName: 'engineeringExpectedStart',
            showFilter: false,
            showSort: false,
            formatData: (item) => {
                const d = item.engineeringExpectedStart;
                return d ? `${d.slice(8, 10)}/${d.slice(5, 7)}/${d.slice(0, 4)}` : '';
            },
        },
        {
            title: 'Eng Due Date',
            fieldName: 'commercialsEngAllowed',
            showFilter: false,
            showSort: false,
            formatData: (item) => {
                const d = item.engineeringExpectedStart;
                const h = item.commercialsEngAllowed;
                if (d && h) {
                    return String(addWorkHours(d, h).toLocaleDateString('en-AU', { year: 'numeric', month: '2-digit', day: '2-digit' }));
                }
            },
        },
        {
            title: 'Fab Start Date',
            fieldName: 'productionFabExpectedStart',
            showFilter: false,
            showSort: false,
            formatData: (item) => {
                const d = item.productionFabExpectedStart;
                return d ? `${d.slice(8, 10)}/${d.slice(5, 7)}/${d.slice(0, 4)}` : '';
            },
        },
        {
            title: 'Client Due Date',
            fieldName: 'commercialsDueDate',
            showFilter: false,
            showSort: false,
            formatData: (item) => {
                const d = item.commercialsDueDate;
                return d ? `${d.slice(8, 10)}/${d.slice(5, 7)}/${d.slice(0, 4)}` : '';
            },
        },
        {
            title: 'Client Rev Due Date',
            fieldName: 'commercialsRevisedDueDate',
            showFilter: false,
            showSort: false,
            formatData: (item) => {
                const d = item.commercialsRevisedDueDate;
                return d ? `${d.slice(8, 10)}/${d.slice(5, 7)}/${d.slice(0, 4)}` : '';
            },
        },
        {
            title: 'Order Number',
            fieldName: 'commercialsOrderNumber',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Price',
            fieldName: 'combinedPrice',
            showFilter: false,
            showSort: false,
            formatData: (item) => item && formatter.format(item.combinedPrice || 0),
        },
    ],
    urlPath: "/jobs/wip",
    idField: 'jobID',
};

const createFilterValues = ({
    JobID,
    CommercialsOrderDateMin,
    CommercialsOrderDateMax,
    ClientName,
    OrderNo,
    FanDesign,
    FanSize,
    QuoteNo,
    JobStatusIDs,
    SalesRepID,
    FanSerial,
    CommercialsExtra,
    FanInletPressureMin,
    FanInletPressureMax,
    FanDischargePressureMin,
    FanDischargePressureMax,
    FanVolumeMin,
    FanVolumeMax,
    FanHanding,
    FanOrt,
    FanPercentageWidth,
    FanArrangement,
}) => {
    const QuoteNoSplit = QuoteNo.match(/[a-z]+|\d+/gi) || [];

    return {
        JobID,
        ...(CommercialsOrderDateMin !== '' && { 'Attributes.CommercialsOrderDate.Min': new Date(CommercialsOrderDateMin).toISOString() }),
        ...(CommercialsOrderDateMax !== '' && { 'Attributes.CommercialsOrderDate.Max': new Date(CommercialsOrderDateMax).toISOString() }),
        ClientName,
        OrderNo,
        'Attributes.FanDesign': FanDesign,
        'Attributes.FanSize': FanSize,
        ...(QuoteNoSplit.length >= 1 && { QuoteID: QuoteNoSplit[0] }),
        ...(QuoteNoSplit.length >= 2 && { Revision: QuoteNoSplit[1] }),
        ...(QuoteNoSplit.length >= 3 && { ItemID: QuoteNoSplit[2] }),
        JobStatusIDs,
        SalesRepID,
        FanSerial,
        'Attributes.CommercialsExtra': CommercialsExtra,
        'Attributes.FanInletPressure.Min': FanInletPressureMin,
        'Attributes.FanInletPressure.Max': FanInletPressureMax,
        'Attributes.FanDischargePressure.Min': FanDischargePressureMin,
        'Attributes.FanDischargePressure.Max': FanDischargePressureMax,
        'Attributes.FanVolume.Min': FanVolumeMin,
        'Attributes.FanVolume.Max': FanVolumeMax,
        'Attributes.FanHanding': FanHanding,
        'Attributes.FanOrt': FanOrt,
        'Attributes.FanPercentageWidth': FanPercentageWidth,
        'Attributes.FanArrangement': FanArrangement,
    };
};

const WIP = ({ isLoading, salesRepList, jobStatusList }) => {
    const dispatch = useDispatch();

    const { setFilterValues, clearFilter } = useContext(BrowseContext);

    return (
        <>
            <Backdrop open={isLoading}>
                <CircularProgress color="primary" />
            </Backdrop>

            <ContentHeader title={'WIP List'} />
            {!isLoading && (
                <Formik
                    initialValues={{
                        JobID: '',
                        CommercialsOrderDateMin: '',
                        CommercialsOrderDateMax: '',
                        ClientName: '',
                        CommercialsOrderNumber: '',
                        FanDesign: '',
                        FanSize: '',
                        QuoteNo: '',
                        JobStatusIDs: '',
                        SalesRepID: '',
                        FanSerial: '',
                        CommercialsExtra: '',
                        FanInletPressureMin: '',
                        FanInletPressureMax: '',
                        FanDischargePressureMin: '',
                        FanDischargePressureMax: '',
                        FanVolumeMin: '',
                        FanVolumeMax: '',
                        FanHanding: '',
                        FanOrt: '',
                        FanPercentageWidth: '',
                        FanArrangement: '',
                    }}
                    onSubmit={async (values) => {
                        setFilterValues(createFilterValues(values));
                    }}
                >
                    {(formProps) => {
                        const { handleSubmit, isSubmitting, setFieldValue, values } = formProps;

                        return (
                            <>
                                <BrowseData minWidth="1500px" />

                                <Grid container justify="flex-end">
                                    <Button
                                        variant="contained"
                                        color="primary"
                                        type="submit"
                                        onClick={async () => {
                                            const url = browseConfig.createApiPath(
                                                browseConfig.apiBasePath,
                                                1,
                                                10000000,
                                                createFilterValues(values),
                                            );
                                            const res = await axiosInstance({ url });

                                            const blob = await pdf(<PDF res={res.data} />).toBlob();

                                            saveAs(blob, `WIPReport.pdf`);
                                        }}
                                    >
                                        Generate WIP Report
                                    </Button>
                                </Grid>
                            </>
                        );
                    }}
                </Formik>
            )}
        </>
    );
};

const WrappedApp = (props) => (
    <BrowseProvider config={browseConfig} pageSize={1000} disableEmptyRows={true}>
        <WIP {...props} />
    </BrowseProvider>
);

export default WrappedApp;
