const fanSizePlusMinus = value => {
    if (value === null) {
        return ''
    }
 
    if ( value > 0) {
        return '+'+value
    }

    return value
}

export default fanSizePlusMinus;