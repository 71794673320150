import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Box, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import Input from 'components/Input';
import { initialForceChangeValues, forceChallengeValidation, forceChangeStructure } from 'constants/auth';
import useStyles from './inputStyles';
import { addFeedback } from 'actions';
import { useDispatch } from 'store';
import { useAuth } from 'providers/auth';

export interface ChallengeProps {
    showLogin: any;
    user: any;
}

const Challenge: React.SFC<ChallengeProps> = ({ showLogin, user }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { completeNewPassword }: any = useAuth();
    const [submitStatus, setSubmitStatus] = useState();

    return (
        <Box>
            <Typography variant="h5" component="h2" className={classes.heading}>
                Change Password
            </Typography>
            <Formik
                initialValues={initialForceChangeValues}
                validationSchema={forceChallengeValidation}
                onSubmit={async ({ newPassword }) => {

                    const { message, severity } = await completeNewPassword(user, newPassword);

                    dispatch(addFeedback(message, severity));
                }}
            >
                {(props) => {
                    const { handleSubmit, isSubmitting, setSubmitting } = props;

                    if (isSubmitting && submitStatus) {
                        setSubmitting(false);
                        setSubmitStatus(undefined);
                    }

                    return (
                        <form onSubmit={handleSubmit}>
                            <table style={{ margin: 'auto' }}>
                                <tbody>
                                    {forceChangeStructure.map(({ key, ...rest }) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <Input {...rest} className={classes.input} variant="outlined" />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td>
                                            <p>
                                                Forgot your password?{' '}
                                                <Link to="#" onClick={showLogin} color="secondary">
                                                    Return to Login Form
                                                </Link>
                                            </p>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                color="primary"
                                                variant="contained"
                                                className={classes.submit}
                                                size="large"
                                            >
                                                Set Password
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default Challenge;
