import { useContext, useState } from 'react';
import { Button, Grid } from '@material-ui/core';
import ContentHeader from 'components/ContentHeader';
import ContentWrapper from 'components/ContentWrapper';
import BrowseData from 'components/BrowseData';
import BrowseProvider from 'providers/browse';
import { Formik } from 'formik';
import Input from '../../Quotes/List/Input';
import BrowseContext from 'contexts/browseContext';
import NumberFormat from 'react-number-format';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import { axiosInstance } from 'App';
import { saveAs } from 'file-saver';

import SelectWithData from '../../Quotes/List/SelectWithData';
import { Backdrop, CircularProgress } from '@material-ui/core';

import fanSizePlusMinus from 'helpers/fanSizePlusMinus';

import { useAuth } from 'providers/auth';

const link = '/jobs';

const browseConfig = {
    dataId: 'jobs',
    apiBasePath: link,
    createApiPath: (path, pageNum, pageSize, filterValues) => {
        if (Object.keys(filterValues).length) {
            var esc = encodeURIComponent;

            const filters = Object.keys(filterValues)
                .map(k => {
                    return filterValues[k] && esc(k) + '=' + esc(filterValues[k]) + '&'
                }).join('')

            return `${path}?pageNum=${pageNum}&pageSize=${pageSize}&sortBy=JobID&${filters}`;
        }
        return `${path}?pageNum=${pageNum}&pageSize=${pageSize}&sortBy=JobID&JobStateIDs=1`;
    },
    tableStructure: [
        {
            title: '',
            align: 'left',
            actions: [
                { title: 'Open/Edit', idField: 'id', action: 'edit', path: link },
                { title: 'Duplicate', idField: 'id', action: 'clone', path: link }
            ],
            sticky: true
        },
        {
            title: 'Job No',
            fieldName: 'id',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Job Status',
            fieldName: 'jobStatusName',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Latest Status Note',
            fieldName: 'jobStatusNotice',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Company Name',
            fieldName: 'clientDetails.name',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.clientDetails && item.clientDetails.name
        },
        {
            title: 'Order Number',
            fieldName: 'attributes.commercialsOrderNumber',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes && item.attributes.commercialsOrderNumber
        },
        {
            title: 'Product Area',
            fieldName: 'productAreaName',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Qty',
            fieldName: 'attributes.fanQty',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes && item.attributes.fanQty
        },
        {
            title: 'Design',
            fieldName: 'attributes.fanDesign',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes && item.attributes.fanDesign
        },
        {
            title: 'Size',
            fieldName: 'attributes.fanSize',
            showFilter: false,
            showSort: false,
            formatData: (item) => (item.attributes?.fanSize ? item.attributes?.fanSize : '') + fanSizePlusMinus(item.attributes?.fanSizePlusMinus)
        },
        {
            title: '% Width',
            fieldName: 'attributes.fanPercentageWidth',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes && item.attributes.fanPercentageWidth
        },
        {
            title: 'Arr',
            fieldName: 'attributes.fanArrangement',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes && item.attributes.fanArrangement
        },
        {
            title: 'Hand',
            fieldName: 'attributes.fanHanding',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes && item.attributes.fanHanding
        },
        {
            title: 'Ort',
            fieldName: 'attributes.fanOrt',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes && item.attributes.fanOrt
        },
        {
            title: 'Pos',
            fieldName: 'attributes.fanPos',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes && item.attributes.fanPos
        },
        {
            title: 'Job Date',
            fieldName: 'createdAt',
            showFilter: false,
            showSort: false,
            formatData: (item, fieldName) => {
                const d = item[fieldName];
                return new Date(d).toLocaleDateString('en-AU')
            }
        },
        {
            title: 'Prev Fan Serial',
            fieldName: 'fanSerial',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Quote No',
            fieldName: 'quoteID',
            showFilter: false,
            showSort: false,
            formatData: (item) => `${item['quoteID']}${item['revision']}${item['itemID']}`
        },
        {
            title: 'Commercials Extra',
            fieldName: 'attributes.commercialsExtra',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes && item.attributes.commercialsExtra
        },
        {
            title: 'Enquiry Source',
            fieldName: 'enquirySourceName',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.enquirySourceName
        },
        {
            title: 'Sales Rep',
            fieldName: 'salesRepName',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Volume L/s',
            fieldName: 'attributes.fanVolumeLPerS',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes && item.attributes.fanVolumeLPerS
        },
        {
            title: 'Inlet Press Pa',
            fieldName: 'attributes.fanInletPressurePa',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes && item.attributes.fanInletPressurePa
        },
        {
            title: 'Discharge Press Pa',
            fieldName: 'attributes.fanDischargePressurePa',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes && item.attributes.fanDischargePressurePa
        },

        {
            title: 'Aura Job File No',
            fieldName: 'auraJobFileNo',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes?.auraJobFileNo,
        },
        {
            title: 'NYB Sales Memo No',
            fieldName: 'nybSalesMemoNo',
            showFilter: false,
            showSort: false,
            formatData: (item) => item.attributes?.nybSalesMemoNo,
        },

        {
            title: 'Industry',
            fieldName: 'industryName',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Application',
            fieldName: 'applicationName',
            showFilter: false,
            showSort: false,
        },
        {
            title: 'Combined Price',
            fieldName: 'combinedPrice',
            showFilter: false,
            showSort: false,
            formatData: (item, fieldName) => <NumberFormat value={item[fieldName]} displayType={'text'} thousandSeparator isNumericString prefix={'$'} decimalScale={2} fixedDecimalScale />
        },
    ],
    urlPath: link,
    idField: 'quoteID'
};

const createFilterValues = ({
    JobID,
    CommercialsOrderDateMin,
    CommercialsOrderDateMax,
    ClientName,
    OrderNo,
    FanDesign,
    FanSize,
    QuoteNo,
    JobStatusIDs,
    SalesRepID,
    FanSerial,
    CommercialsExtra,
    CommercialsOrderNumber,
    FanInletPressureMin,
    FanInletPressureMax,
    FanDischargePressureMin,
    FanDischargePressureMax,
    FanVolumeMin,
    FanVolumeMax,
    FanHanding,
    FanOrt,
    FanPercentageWidth,
    FanArrangement,
    ProductionAllocated,
    ProductAreaID,
    EnquirySourceID,
    JobStateIDs,
    AuraJobFileNo,
    NYBSalesMemoNo,
    industryID,
    applicationID
}) => {
    const QuoteNoSplit = QuoteNo.match(/[a-z]+|\d+/ig) || [];
    return {
        JobID,
        ...(CommercialsOrderDateMin !== '' && { 'Attributes.CommercialsOrderDate.Min': new Date(CommercialsOrderDateMin + "T00:00:00.000+10:00").toISOString() }),
        ...(CommercialsOrderDateMax !== '' && { 'Attributes.CommercialsOrderDate.Max': new Date(CommercialsOrderDateMax + "T24:00:00.000+10:00").toISOString() }),
        ClientName,
        OrderNo,
        'Attributes.FanDesign': FanDesign,
        'Attributes.FanSize': FanSize,
        ...(QuoteNoSplit.length >= 1 && { QuoteID: QuoteNoSplit[0] }),
        ...(QuoteNoSplit.length >= 2 && { Revision: QuoteNoSplit[1] }),
        ...(QuoteNoSplit.length >= 3 && { ItemID: QuoteNoSplit[2] }),
        JobStatusIDs,
        SalesRepID,
        FanSerial,
        'Attributes.CommercialsExtra': CommercialsExtra,
        'Attributes.CommercialsOrderNumber': CommercialsOrderNumber,
        'Attributes.FanInletPressurePa.Min': FanInletPressureMin,
        'Attributes.FanInletPressurePa.Max': FanInletPressureMax,
        'Attributes.FanDischargePressurePa.Min': FanDischargePressureMin,
        'Attributes.FanDischargePressurePa.Max': FanDischargePressureMax,
        'Attributes.FanVolumeLPerS.Min': FanVolumeMin,
        'Attributes.FanVolumeLPerS.Max': FanVolumeMax,
        'Attributes.FanHanding': FanHanding,
        'Attributes.FanOrt': FanOrt,
        'Attributes.FanPercentageWidth': FanPercentageWidth,
        'Attributes.FanArrangement': FanArrangement,
        'Attributes.ProductionAllocated': ProductionAllocated,
        ProductAreaID,
        EnquirySourceID,
        JobStateIDs,
        'Attributes.AuraJobFileNo': AuraJobFileNo,
        'Attributes.NYBSalesMemoNo': NYBSalesMemoNo,
        industryID,
        applicationID
    }
};

const Jobs = ({ isLoading, salesRepList, jobStatusList, productAreaList, enquirySourceList, jobStateList, industryList, applicationList }) => {
    const { userAttr } = useAuth();
    const dispatch = useDispatch()

    const { setFilterValues, clearFilter } = useContext(BrowseContext);

    const [jsReset, setJsReset] = useState(`js${Date.now()}`);
    const [sReset, setSReset] = useState(`s${Date.now()}`);
    const [paReset, setPaReset] = useState(`pa${Date.now()}`);
    const [esReset, setEsReset] = useState(`es${Date.now()}`);
    const [jStateReset, setJStateReset] = useState(`JState${Date.now()}`);
    const [iReset, setIReset] = useState(`i${Date.now()}`);
    const [aReset, setAReset] = useState(`a${Date.now()}`);

    return (
        <>
            <Backdrop open={isLoading}>
                <CircularProgress color="primary" />
            </Backdrop>

            <ContentHeader title={'Jobs'} />
            {!isLoading &&
                <Formik
                    initialValues={{
                        JobID: '',
                        CommercialsOrderDateMin: '',
                        CommercialsOrderDateMax: '',
                        ClientName: '',
                        CommercialsOrderNumber: '',
                        FanDesign: '',
                        FanSize: '',
                        QuoteNo: '',
                        JobStatusIDs: '',
                        SalesRepID: '',
                        FanSerial: '',
                        CommercialsExtra: '',
                        FanInletPressureMin: '',
                        FanInletPressureMax: '',
                        FanDischargePressureMin: '',
                        FanDischargePressureMax: '',
                        FanVolumeMin: '',
                        FanVolumeMax: '',
                        FanHanding: '',
                        FanOrt: '',
                        FanPercentageWidth: '',
                        FanArrangement: '',
                        ProductionAllocated: '',
                        ProductAreaID: '',
                        EnquirySourceID: '',
                        JobStateIDs: 1,
                    }}
                    onSubmit={async (values) => {
                        setFilterValues(createFilterValues(values));
                    }}
                >
                    {(formProps) => {
                        const { handleSubmit, isSubmitting, setFieldValue, values } = formProps;

                        return (
                            <>
                                <ContentWrapper>
                                    <form onSubmit={handleSubmit}>

                                        <Input
                                            label='Job ID'
                                            name='JobID'
                                            type='number'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Job Date Start'
                                            name='CommercialsOrderDateMin'
                                            type='date'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Job Date End'
                                            name='CommercialsOrderDateMax'
                                            type='date'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Client Name'
                                            name='ClientName'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Order No'
                                            name='CommercialsOrderNumber'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Fan Design'
                                            name='FanDesign'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Fan Size'
                                            name='FanSize'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Quote No'
                                            name='QuoteNo'
                                            type='text'
                                            style={{ width: '19%', marginRight: '1%' }}
                                        />
                                        <SelectWithData reset={jsReset} setFieldValue={setFieldValue} label='Job Status' name='JobStatusIDs' options={jobStatusList} style={{ width: '19%', marginRight: '1%' }} />
                                        <SelectWithData reset={sReset} setFieldValue={setFieldValue} label='Sales Rep' name='SalesRepID' options={salesRepList} style={{ width: '19%', marginRight: '1%' }} />
                                        <Input
                                            label='Fan Prev Serial'
                                            name='FanSerial'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Commercials Extra'
                                            name='CommercialsExtra'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Fan Inlet Pressure Min (Pa)'
                                            name='FanInletPressureMin'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Fan Inlet Pressure Max (Pa)'
                                            name='FanInletPressureMax'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Fan Discharge Pressure Min (Pa)'
                                            name='FanDischargePressureMin'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Fan Discharge Pressure Max (Pa)'
                                            name='FanDischargePressureMax'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Fan Volume Min (L/sec)'
                                            name='FanVolumeMin'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Fan Volume Max (L/sec)'
                                            name='FanVolumeMax'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Fan Handing'
                                            name='FanHanding'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Fan Ort'
                                            name='FanOrt'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Fan Percentage Width'
                                            name='FanPercentageWidth'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label='Fan Arrangement'
                                            name='FanArrangement'
                                            type='text'
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <SelectWithData reset={paReset} setFieldValue={setFieldValue} label='Product Area' name='ProductAreaID' options={productAreaList} style={{ width: '14%', marginRight: '1%' }} />
                                        <SelectWithData reset={esReset} setFieldValue={setFieldValue} label='Enquiry Source' name='EnquirySourceID' options={enquirySourceList} style={{ width: '14%', marginRight: '1%' }} />
                                        { userAttr.roles.includes("Admin") && 
                                            <SelectWithData reset={jStateReset} setFieldValue={setFieldValue} defaultValue={{label: 'Open', id: 1}} label='Job State' name='JobStateIDs' options={jobStateList} style={{ width: '14%', marginRight: '1%' }} />
                                        }
                                        <SelectWithData
                                            setFieldValue={setFieldValue} 
                                            name="ProductionAllocated"
                                            type="text"
                                            label="Production Allocated"
                                            options={[
                                                {
                                                    label: 'AirEng',
                                                    id: 'AirEng',
                                                },
                                                {
                                                    label: 'Aerotech',
                                                    id: 'Aerotech',
                                                },
                                                {
                                                    label: 'Metfab',
                                                    id: 'Metfab',
                                                },
                                                {
                                                    label: 'Control Fab',
                                                    id: 'Control Fab',
                                                },
                                                {
                                                    label: 'Shearform',
                                                    id: 'Shearform',
                                                },
                                                {
                                                    label: '24/7',
                                                    id: '24/7',
                                                },
                                                {
                                                    label: 'Other External Fab',
                                                    id: 'Other External Fab',
                                                },
                                            ]}
                                            style={{ width: '24%', marginRight: '1%' }}
                                        />

                                        <Input
                                            label="Aura Job File No"
                                            name="AuraJobFileNo"
                                            type="text"
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <Input
                                            label="NYB Sales Memo No"
                                            name="NYBSalesMemoNo"
                                            type="text"
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        
                                        <br />

                                        <SelectWithData
                                            reset={iReset}
                                            setFieldValue={setFieldValue}
                                            label="Industry"
                                            name="industryID"
                                            options={industryList}
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />
                                        <SelectWithData
                                            reset={aReset}
                                            setFieldValue={setFieldValue}
                                            label="Application"
                                            name="applicationID"
                                            options={applicationList}
                                            style={{ width: '14%', marginRight: '1%' }}
                                        />

                                        <Grid container justify="flex-end">
                                            <Button
                                                variant='contained'
                                                color="primary"
                                                type='button'
                                                onClick={() => {
                                                    setFieldValue('JobID', '');
                                                    setFieldValue('CommercialsOrderDateMin', '');
                                                    setFieldValue('CommercialsOrderDateMax', '');
                                                    setFieldValue('ClientName', '');
                                                    setFieldValue('CommercialsOrderNumber', '');
                                                    setFieldValue('FanDesign', '');
                                                    setFieldValue('FanSize', '');
                                                    setFieldValue('QuoteNo', '');
                                                    setFieldValue('JobStatusIDs', '');
                                                    setJsReset(`js${Date.now()}`);
                                                    setFieldValue('SalesRepID', '');
                                                    setSReset(`s${Date.now()}`);
                                                    setFieldValue('FanSerial', '');
                                                    setFieldValue('CommercialsExtra', '');
                                                    setFieldValue('FanInletPressureMin', '');
                                                    setFieldValue('FanInletPressureMax', '');
                                                    setFieldValue('FanDischargePressureMin', '');
                                                    setFieldValue('FanDischargePressureMax', '');
                                                    setFieldValue('FanVolumeMin', '');
                                                    setFieldValue('FanVolumeMax', '');
                                                    setFieldValue('FanHanding', '');
                                                    setFieldValue('FanOrt', '');
                                                    setFieldValue('FanPercentageWidth', '');
                                                    setFieldValue('FanArrangement', '');
                                                    setFieldValue('ProductionAllocated', '');
                                                    setFieldValue('ProductAreaID', '');
                                                    setPaReset(`pa${Date.now()}`);
                                                    setEsReset(`es${Date.now()}`);
                                                    setJStateReset(`JState${Date.now()}`);
                                                    setFieldValue('AuraJobFileNo', '');
                                                    setFieldValue('NYBSalesMemoNo', '');
                                                    setIReset(`i${Date.now()}`);
                                                    setAReset(`a${Date.now()}`);
                                                    clearFilter();
                                                }}
                                            >
                                                Reset Filters
                                            </Button>
                                            &nbsp;
                                            <Button
                                                variant='contained'
                                                color="secondary"
                                                type='submit'
                                                disabled={isSubmitting}
                                            >
                                                Filter Jobs
                                            </Button>
                                        </Grid>
                                    </form>
                                </ContentWrapper>

                                <BrowseData minWidth="1500px" />

                                <Grid container justify="flex-end">
                                    <Button
                                        variant='contained'
                                        color='primary'
                                        onClick={async () => {
                                            const url = browseConfig.createApiPath(browseConfig.apiBasePath, 1, 10000000, createFilterValues(values));
                                            const res = await axiosInstance({ url });

                                            if (res.data.length > 0) {
                                                const csvHeaders = [
                                                    'Job No',
                                                    'Job Date',
                                                    'Client Name',
                                                    'Client State',
                                                    'Product Area',
                                                    'Sales Rep',
                                                    'Combined Price Sell',
                                                    'Combined Price Cost',
                                                ]

                                                const csvData = res.data.map(({
                                                    id,
                                                    createdAt,
                                                    clientDetails,
                                                    salesRepName,
                                                    productAreaName,
                                                    combinedPrice,
                                                    combinedCost }) => {

                                                    const {name, state} = clientDetails != null ? { name: clientDetails.name, state: clientDetails.addressDetails.state } : { name: 'NA', state: 'NA' };
                                                    
                                                    return `${id},\
                                                    ${new Date(createdAt).toLocaleDateString('en-AU')},${name},${state},${productAreaName},${salesRepName != null ? salesRepName : ''},${combinedPrice},${combinedCost}`
                                                }).join('\n');

                                                const blob = new Blob([
                                                    `${csvHeaders.join(",")} \n` +
                                                    csvData
                                                ], { type: "text/plain;charset=utf-8" });
                                                saveAs(blob, `report.csv`);
                                            } else {
                                                dispatch(addFeedback('No results found matching filter to export', 'error'));
                                            }
                                        }}
                                    >
                                        Export to .csv
                                    </Button>
                                </Grid>
                            </>
                        );
                    }}
                </Formik>

            }
        </>
    );
};

const WrappedApp = (props) => (
    <BrowseProvider config={browseConfig} pageSize={15}>
        <Jobs {...props} />
    </BrowseProvider>
);

export default WrappedApp;
