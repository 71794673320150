import { TextField, IconButton } from '@material-ui/core';
import RemoveIcon from '@material-ui/icons/Remove';
import { useStyles } from '../../styles';
import { Controller, useWatch } from "react-hook-form";
import ReactHookTextField from '../../../../components/ReactHookTextField';
import NumberFormat from '../../../../components/NumberFormat';

const Row = ({ control, index, setTotalCost, totalCost, setTotalSell, totalSell, remove }) => {
    const classes = useStyles();

    const qty = useWatch({
        control,
        name: `lineItems.${index}.quantity`
    }, false);

    const costPrice = useWatch({
        control,
        name: `lineItems.${index}.attributes.costPrice`
    }, false);

    const sellPrice = useWatch({
        control,
        name: `lineItems.${index}.price`
    }, false);

    return (
        <div style={{ display: 'flex' }}>
            <ReactHookTextField
                name={`lineItems.${index}.attributes.partNumber`}
                type='text'
                control={control}
                label='Part Number'
                style={{ minWidth: '150px', marginRight: '10px' }}
            />

            <ReactHookTextField
                name={`lineItems.${index}.description`}
                type='textarea'
                control={control}
                label='Description'
                style={{ minWidth: '450px', marginRight: '10px' }}
                InputProps={{
                    multiline: true
                }}
            />

            <Controller
                name={`lineItems.${index}.quantity`}
                type='number'
                control={control}
                render={({ field, fieldState: { error } }) => <TextField
                    {...field}
                    label='Qty'
                    style={{ minWidth: '70px', marginRight: '10px' }}
                    className={classes.input}
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    size='small'
                    error={error}
                    helperText={error && error.message}
                    onChange={(e) => {
                        const quantity = e.target.value;
                        setTotalCost([
                            ...totalCost.slice(0, index),
                            quantity * (costPrice || 0),
                            ...totalCost.slice(index + 1)
                        ]);
                        setTotalSell([
                            ...totalSell.slice(0, index),
                            quantity * (sellPrice || 0),
                            ...totalSell.slice(index + 1)
                        ]);
                        field.onChange(quantity);
                    }}
                />
                }
            />

            <Controller
                name={`lineItems.${index}.attributes.costPrice`}
                type='number'
                control={control}
                render={({ field, fieldState: { error } }) => <TextField
                    {...field}
                    label='Item Cost'
                    style={{ minWidth: '125px', marginRight: '10px' }}
                    className={classes.input}
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    size='small'
                    error={error}
                    helperText={error && error.message}
                    onChange={(e) => {
                        const price = e.target.value;
                        setTotalCost([
                            ...totalCost.slice(0, index),
                            (qty || 0) * price,
                            ...totalCost.slice(index + 1)
                        ]);
                        field.onChange(price);
                    }}
                    InputProps={{
                        inputComponent: NumberFormat,
                    }}
                />
                }
            />

            <TextField
                label='Total Cost'
                type='text'
                disabled={true}
                className={classes.input}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                size='small'
                value={costPrice * qty}
                style={{ minWidth: '125px', marginRight: '10px' }}
                InputProps={{
                    inputComponent: NumberFormat,
                }}
            />

            <Controller
                name={`lineItems.${index}.price`}
                type='number'
                control={control}
                render={({ field, fieldState: { error } }) => <TextField
                    {...field}
                    label='Item Sell'
                    style={{ minWidth: '125px', marginRight: '10px' }}
                    className={classes.input}
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    size='small'
                    error={error}
                    helperText={error && error.message}
                    onChange={(e) => {
                        const price = e.target.value;
                        setTotalSell([
                            ...totalSell.slice(0, index),
                            (qty || 0) * price,
                            ...totalSell.slice(index + 1)
                        ]);
                        field.onChange(price);
                    }}
                    InputProps={{
                        inputComponent: NumberFormat,
                    }}
                />
                }
            />

            <TextField
                label='Total Sell'
                type='text'
                disabled={true}
                className={classes.input}
                variant='outlined'
                InputLabelProps={{ shrink: true }}
                size='small'
                value={sellPrice * qty}
                style={{ minWidth: '125px', marginRight: '10px' }}
                InputProps={{
                    inputComponent: NumberFormat,
                }}
            />
            <ReactHookTextField
                name={`lineItems.${index}.attributes.supplier`}
                type='text'
                control={control}
                label='Supplier'
                style={{ minWidth: '175px', marginRight: '10px' }}
            />

            <ReactHookTextField
                name={`lineItems.${index}.comments`}
                type='textarea'
                control={control}
                label='Comments'
                style={{ minWidth: '250px', paddingRight: '24px' }}
                InputProps={{
                    multiline: true
                }}
            />
            <IconButton
                type="button"
                color="secondary"
                onClick={() => {
                    setTotalCost(totalCost.filter((v, i) => i != index));
                    setTotalSell(totalSell.filter((v, i) => i != index));
                    return remove(index);
                }}
                style={{height: 40, width: '4%'}}
            >
                <RemoveIcon />
            </IconButton>
        </div>
    );
};

export default Row;
