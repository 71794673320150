import * as React from 'react';
import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';
import ContentWrapper from 'components/ContentWrapper';
import { useStyles } from './Form';
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import { Button, Grid } from '@material-ui/core';
import Box from '@material-ui/core/Box';
import Collapse from '@material-ui/core/Collapse';
import IconButton from '@material-ui/core/IconButton';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';

import Motor from './Motor';
import Impeller from './Impeller';
import Liners from './Liners';
import Hub from './Hub';
import HubBush from './HubBush';
import FanPulley from './FanPulley';
import MotorPulley from './MotorPulley';
import Belts from './Belts';
import BeltGuard from './BeltGuard';
import SlideRails from './SlideRails';
import Balancing from './Balancing';
import Shaft from './Shaft';
import ShaftGuard from './ShaftGuard';
import {BearingsDE, BearingsNDE} from './Bearings';
import ShaftSeal from './ShaftSeal';
import Couplings from './Couplings';
import CouplingGuard from './CouplingGuard';
import Case from './Case';
import InletCone from './InletCone';
import Pedestal from './Pedestal';
import Base from './Base';
import BaseMount from './BaseMount';
import InletDamper from './InletDamper';
import InletBox from './InletBox';
import InletSilencer from './InletSilencer';
import InletFlexible from './InletFlexible';
import InletVanes from './InletVanes';
import Evase from './Evase';
import OutletDamper from './OutletDamper';
import OutletSilencer from './OutletSilencer';
import OutletFlexible from './OutletFlexible';
import Internal from './Internal';
import External from './External';
import LagClad from './LagClad';
import DoneIcon from '@material-ui/icons/Done';
import { InletSilencerTransition, OutletSilencerTransition } from './SilencerTransition';

const CustomTableCell = ({ name, control, type = "input", style}) => {
    const classes = useStyles();
    return (
        <TableCell align="left" className={classes.tableCell} style={style}>
            {type === 'input' && 
                <ReactHookTextField
                    name={name}
                    type='text'
                    control={control}
                    variant='standard'
                    style={{marginBottom: 0}}
                />
            }
            {type === 'textarea' && 
                <ReactHookTextField
                    name={name}
                    type='textarea'
                    control={control}
                    variant='standard'
                    style={{marginBottom: 0}}
                    InputProps={{
                        multiline:true
                    }}
                />   
            }
            {type === 'checkbox' && 
                <ReactHookCheckbox
                    control={control}
                    name={name}
                    style={{marginBottom: 0}}
                />
            }
            {type === 'date' && 
                <ReactHookTextField
                    name={name}
                    type='date'
                    control={control}
                    variant='standard'
                    style={{marginBottom: 0}}
                />
            }
      </TableCell>
    );
};

function Row(props) {
    const { name, field, notes, checkField, checkValue, component: Comp, control, getValues, watch } = props;
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    
    const showMatch = checkField ? getValues(checkField) == checkValue : true;
    
    return (
        showMatch && 
        getValues(`${field}Qty`) >= '1') && (
      <>
        <TableRow sx={{ '& > *': { borderBottom: 'unset' } }} style={open ? {background: '#dceaf5' } : {}}>
            <TableCell>
                {getValues(`${field}Qty`)}
            </TableCell>
            <TableCell>
                {name}
            </TableCell>
            <TableCell>
                {notes && getValues(notes)}
            </TableCell>
            <CustomTableCell {...{ name: `${field}Drawing`, control, type: "textarea"}} />
            <CustomTableCell {...{ name: `${field}Stock`, control, type: "checkbox", style:{textAlign: 'center'} }} />
            { watch(`${field}Stock`) ? 
                <>
                    <TableCell align="left" className={classes.tableCell}> Stock Item</TableCell>
                    <CustomTableCell {...{ name: `${field}Warehouse`, control, type: "checkbox", style:{textAlign: 'center'} }} />
                    <TableCell align="left" className={classes.tableCell}></TableCell>
                    <TableCell align="left" className={classes.tableCell}></TableCell>
                    <TableCell align="left" className={classes.tableCell} style={{textAlign: 'center', color:'#2576b6'}}><DoneIcon/></TableCell>
                </>
              : 
                <>
                    <CustomTableCell {...{ name: `${field}Supplier`, control }} />
                    <CustomTableCell {...{ name: `${field}Warehouse`, control, type: "checkbox", style:{textAlign: 'center'} }} />
                    <CustomTableCell {...{ name: `${field}OrderDate`, control, type: "date" }} />
                    <CustomTableCell {...{ name: `${field}OrderDue`, control, type: "date" }} />
                    <CustomTableCell {...{ name: `${field}OrderReceived`, control, type: "checkbox", style:{textAlign: 'center'} }} />
                </>
            }
            
          <TableCell> 
            <IconButton
              aria-label="expand row"
              size="small"
              onClick={() => setOpen(!open)}
            >
              {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </IconButton>
          </TableCell>
        </TableRow>
        <TableRow>
          <TableCell style={{ paddingBottom: 0, paddingTop: 0, background: '#dceaf5' }} colSpan={10}>
            <Collapse in={open} timeout="auto" unmountOnExit>
              <Box sx={{ margin: 1 }}>
                <Comp control={control} tableView={true} watch={watch} />
              </Box>
            </Collapse>
          </TableCell>
        </TableRow>
      </>
    );
  }

const rows = [
    {
        name: 'Motor',
        component: Motor,
        field: 'attributes.motor',
        notes: 'attributes.motorOther'
    },
    {
        name: 'Impeller',
        component: Impeller,
        field: 'attributes.impeller',
        notes: 'attributes.impellerNotes'
    },
    {
        name: 'Liner Set',
        component: Liners,
        field: 'attributes.liner',
    },
    {
        name: 'Hub',
        component: Hub,
        field: 'attributes.hub',
    },
    {
        name: 'Hub Bush',
        component: HubBush,
        field: 'attributes.hubBush',
    },
    {
        name: 'Fan Pulley',
        component: FanPulley,
        field: 'attributes.fanPulley',
        //checkField: 'attributes.fanDrive',
        checkValue: 'Belt'
    },
    {
        name: 'Motor Pulley',
        component: MotorPulley,
        field: 'attributes.driveMotorPulley',
        //checkField: 'attributes.fanDrive',
        checkValue: 'Belt'
    },
    {
        name: 'Belts',
        component: Belts,
        field: 'attributes.driveBelt',
        //checkField: 'attributes.fanDrive',
        checkValue: 'Belt'
    },
    {
        name: 'Belt Guard',
        component: BeltGuard,
        field: 'attributes.driveBeltGuard',
        notes: 'attributes.driveBeltGuardNotes',
        //checkField: 'attributes.fanDrive',
        checkValue: 'Belt'
    },
    {
        name: 'Slide Rails',
        component: SlideRails,
        field: 'attributes.driveSlideRails',
        checkField: 'attributes.fanDrive',
        checkValue: 'Belt'
    },
    {
        name: 'Balancing',
        component: Balancing,
        field: 'attributes.balance',
        notes: 'attributes.balanceNotes'
    },
    {
        name: 'Shaft',
        component: Shaft,
        field: 'attributes.shaft',
        notes: 'attributes.shaftNotes'
    },
    {
        name: 'Shaft Guard',
        component: ShaftGuard,
        field: 'attributes.shaftGuard',
        notes: 'attributes.shaftGuardNotes'
    },
    {
        name: 'Bearings (DE)',
        component: BearingsDE,
        field: 'attributes.bearingDE',
        notes: 'attributes.bearingDENotes'
    },
    {
        name: 'Bearings (NDE)',
        component: BearingsNDE,
        field: 'attributes.bearingNDE',
        notes: 'attributes.bearingNDENotes'
    },
    {
        name: 'Shaft Seal',
        component: ShaftSeal,
        field: 'attributes.shaftSeal',
    },
    {
        name: 'Couplings',
        component: Couplings,
        field: 'attributes.coupling',
    },
    {
        name: 'Coupling Guard',
        component: CouplingGuard,
        field: 'attributes.couplingGuard',
        notes: 'attributes.couplingGuardNotes'
    },
    {
        name: 'Case',
        component: Case,
        field: 'attributes.case',
        notes: 'attributes.caseNotes'
    },
    {
        name: 'Inlet Cone',
        component: InletCone,
        field: 'attributes.inletCone',
    },
    {
        name: 'Pedestal',
        component: Pedestal,
        field: 'attributes.pedestal',
    },
    {
        name: 'Base',
        component: Base,
        field: 'attributes.base',
    },
    {
        name: 'Base Mount',
        component: BaseMount,
        field: 'attributes.baseMount',
    },
    {
        name: 'Inlet Damper',
        component: InletDamper,
        field: 'attributes.inletDamper',
        notes: 'attributes.inletDamperNotes'
    },
    {
        name: 'Inlet Box',
        component: InletBox,
        field: 'attributes.inletBox',
        notes: 'attributes.inletBoxNotes'
    },
    {
        name: 'Inlet Silencer',
        component: InletSilencer,
        field: 'attributes.inletSilencer',
    },
    {
        name: 'Inlet Silencer Transition',
        component: InletSilencerTransition,
        field: 'attributes.inletSilencerTransition',
    },
    {
        name: 'Inlet Flexible',
        component: InletFlexible,
        field: 'attributes.inletFlexible',
        notes: 'attributes.inletFlexibleMisc'
    },
    {
        name: 'Inlet Vanes',
        component: InletVanes,
        field: 'attributes.inletVanes',
    },
    {
        name: 'Evase',
        component: Evase,
        field: 'attributes.evase',
        notes: 'attributes.evaseNotes'
    },
    {
        name: 'Outlet Damper',
        component: OutletDamper,
        field: 'attributes.outletDamper',
        notes: 'attributes.outletDamperNotes'
    },
    {
        name: 'Outlet Silencer',
        component: OutletSilencer,
        field: 'attributes.outletSilencer',
    },
    {
        name: 'Outlet Silencer Transition',
        component: OutletSilencerTransition,
        field: 'attributes.outletSilencerTransition',
    },
    {
        name: 'Outlet Flexible',
        component: OutletFlexible,
        field: 'attributes.outletFlexible',
        notes: 'attributes.outletFlexibleMisc'
    },
    {
        name: 'Internal Finish',
        component: Internal,
        field: 'attributes.internalCoating',
    },
    {
        name: 'External Finish',
        component: External,
        field: 'attributes.externalCoating',
    },
    {
        name: 'Lag & Clad',
        component: LagClad,
        field: 'attributes.lagClad',
    },
];

const TableView = ({ control, setShowEdit, getValues, watch={watch} }) => {
    const classes = useStyles();

    return (
        <ContentWrapper>
            <Table className={classes.table} >
                <TableHead>
                    <TableRow>
                        <TableCell className={classes.head} align="left" style={{ width: 20 }}>Qty</TableCell>
                        <TableCell className={classes.head} align="left" style={{ width: 150 }}>Item</TableCell>
                        <TableCell className={classes.head} align="center">Notes/Other</TableCell>
                        <TableCell className={classes.head} align="left" style={{ width: 200 }}>Drawing</TableCell>
                        <TableCell className={classes.head} align="center" style={{ width: 80 }}>Stock Item</TableCell>
                        <TableCell className={classes.head} align="left" style={{ width: 200 }}>Supplier</TableCell>
                        <TableCell className={classes.head} align="center" style={{ width: 80 }}>WH</TableCell>
                        <TableCell className={classes.head} align="left" style={{ width: 200 }}>Order Date</TableCell>
                        <TableCell className={classes.head} align="left" style={{ width: 200 }}>Order Due</TableCell>
                        <TableCell className={classes.head} align="center" style={{ width: 80 }}>Order Received/In Stock</TableCell>
                        <TableCell className={classes.head} style={{ width: 65 }}></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>

                {rows.map(({ name, component, field, notes, checkField, checkValue }) => (
                    <Row 
                        key={name}
                        name={name}
                        component={component}
                        field={field}
                        notes={notes}
                        checkField={checkField}
                        checkValue={checkValue}
                        control={control}
                        getValues={getValues}
                        watch={watch}
                    />
                ))}

                </TableBody>
            </Table>    
            <br />     
            <Grid container justify="flex-end">
                <Button
                    variant='contained'
                    color="secondary"
                    type='submit'
                    onClick={()=>setShowEdit(true)}
                >
                    Bulk Edit View
                </Button>
            </Grid>  
        </ContentWrapper>
    );
};

export default TableView;
