import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        flex: '1',
        justifyContent: 'left',
    },
    title: {
        [theme.breakpoints.down('xs')]: {
            fontSize: '1.4em',
        },
    },
}));

interface TitleProps {
    title: string;
    subtitle?: string;
}

const Title: React.FC<TitleProps> = ({ title, subtitle }) => {
    const classes = useStyles();

    return (
        <div className={classes.wrapper}>
            <Typography variant="h1" className={classes.title}>
                {title}
            </Typography>
            {subtitle && <p>{subtitle}</p>}
        </div>
    );
};

export default Title;
