import { Divider } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const ModelDuty = ({ control }) => {
    return (
        <div>
            <ReactHookTextField
                name='attributes.fanQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanDesign'
                type='text'
                control={control}
                label='Design'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanSize'
                type='text'
                control={control}
                label='Size'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanSizePlusMinus'
                type='text'
                control={control}
                label='Size +/-'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.impellerDiameter'
                type='text'
                control={control}
                label='Impeller Dia. mm'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanPercentageWidth'
                type='text'
                control={control}
                label='% Width'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.impellerClass'
                type='text'
                control={control}
                label='Impeller Class'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanWidth'
                type='text'
                control={control}
                label='Fan Width'
                style={{width: '14%', marginRight: '1%'}}
            />

            <Divider />
            <br />

            <ReactHookTextField
                name='attributes.fanDrive'
                type='text'
                control={control}
                label='Drive'
                style={{width: '14%', marginRight: '1%'}}
            />          
            <ReactHookTextField
                name='attributes.fanArrangement'
                type='text'
                control={control}
                label='Arrangement'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanIdRating'
                type='text'
                control={control}
                label='ID Rating'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanHanding'
                type='text'
                control={control}
                label='Handing'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanOrt'
                type='text'
                control={control}
                label='Ort'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanPos'
                type='text'
                control={control}
                label='Pos'
                style={{width: '14%', marginRight: '1%'}}
            />
            
            <Divider />
            <br />

            <ReactHookTextField
                name='attributes.fanVolume'
                type='text'
                control={control}
                label={`Volume`}
                style={{width: '14%', marginRight: '1%'}}
            />    
            <ReactHookTextField
                name='attributes.fanVolumeLabel'
                type='text'
                control={control}
                label={`Volume Label`}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanInletPressure'
                type='text'
                control={control}
                label={`Inlet Pressure`}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanDischargePressure'
                type='text'
                control={control}
                label={`Discharge Pressure`}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanPressureLabel'
                type='text'
                control={control}
                label={`Pressure Label`}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanRpm'
                type='text'
                control={control}
                label='RPM'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanDesignTempC'
                type='text'
                control={control}
                label='Design Temp (C)'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanDutyKW'
                type='text'
                control={control}
                label='Duty kW'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanDutyDensity'
                type='text'
                control={control}
                label='Duty Density'
                style={{width: '14%', marginRight: '1%'}}
            />    
            <ReactHookTextField
                name='attributes.fanDutyDensityLabel'
                type='text'
                control={control}
                label='Duty Density Label'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.fanElevationMASL'
                type='text'
                control={control}
                label='Elevation (MASL)'
                style={{width: '14%', marginRight: '1%'}}
            />    
            <ReactHookTextField
                name='attributes.fanOther'
                type='textarea'
                control={control}
                label='Other'
                style={{width: '24%', marginRight: '1%'}}
                InputProps={{
                    multiline:true
                }}
            />
        </div>   
    );
};

export default ModelDuty;
