import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const BaseMount = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Base Mount</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.baseMountQty'
                type='text'
                control={control}
                 
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.baseMountType'
                type='text'
                control={control}
                 
                label='Type'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default BaseMount;
