import { createTheme } from '@material-ui/core/styles';

const theme = createTheme({
    palette: {
        primary: {
            light: '#608280',
            main: '#2b2b2b',
            dark: '#20496A'
        },
        secondary: {
            main: '#2576b6',
        },
        error: {
            main: '#f44336',
        },
        background: {
            default: '#dcdddf',
        },
        info: {
            main: '#272c35',
        },
        success: {
            main: '#529c56',
        },
        warning: {
            main: '#cf8c1e',
        },
    },
    typography: {
        fontFamily: 'Arial',
        h1: {
            fontSize: '2.6em',
            fontWeight: 'bold',
        },
        h2: {
            fontSize: '2em',
            fontWeight: 'bold',
        },
        h3: {
            fontSize: '1.8em',
            fontWeight: 'bold',
        },
        h4: {
            fontSize: '1.6em',
            fontWeight: 'bold',
        },
        h5: {
            fontSize: '1.2em',
            fontWeight: 'bold',
        },
    },
});

export default theme;
