import * as Yup from 'yup';
import AddIcon from '@material-ui/icons/Add';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { FilterValues } from 'types/browse';

const link = '/scblowers';

export const SCBLOWERS = {
    title: 'Side Channel Blowers',
    link,
    actions: [
        {
            key: 'browseSCBlowers',
            excludeActions: [''],
            title: 'Browse Side Channel Blowers',
            to: link,
            icon: ArrowBackIcon,
        },
        {
            key: 'addSCBlower',
            excludeActions: ['add'],
            title: 'Add Side Channel Blower',
            to: `${link}/add`,
            icon: AddIcon,
        },
    ],
};

export const apiBasePath = link;

export const tableStructure = [
    {
        title: '',
        align: 'left',
        actions: [
            { title: 'Edit Blower', idField: 'id', action: 'edit', path: link },
        ],
        width: '5',
    },
    {
        title: 'Model',
        formatData: (item: any) => item.attributes.model,
        showFilter: false,
        showSort: false,
        textLink: { idField: 'id', action: 'edit' },
    },
    {
        title: 'Size',
        formatData: (item: any) => item.attributes.size,
        showFilter: false,
        showSort: false,
        textLink: { idField: 'id', action: 'edit' },
        width: '100',
    },
    {
        title: 'Motor kW',
        formatData: (item: any) => item.attributes.motorSize,
        showFilter: false,
        showSort: false,
        textLink: { idField: 'id', action: 'edit' },
        width: '100',
    },
    {
        title: 'Stage',
        formatData: (item: any) => item.attributes.stage,
        showFilter: false,
        showSort: false,
        textLink: { idField: 'id', action: 'edit' },
        width: '100',
    },
    {
        title: 'Phase',
        formatData: (item: any) => item.attributes.phase,
        showFilter: false,
        showSort: false,
        textLink: { idField: 'id', action: 'edit' },
        width: '100',
    },
    {
        title: 'Curve No',
        formatData: (item: any) => item.attributes.cr,
        showFilter: false,
        showSort: false,
        textLink: { idField: 'id', action: 'edit' },
        width: '100',
    },
    {
        title: 'OEM Price',
        formatData: (item: any) => item.attributes.oemPrice,
        showFilter: false,
        showSort: false,
        textLink: { idField: 'id', action: 'edit' },
        width: '100',
    },
    {
        title: 'List Price',
        formatData: (item: any) => item.attributes.listPrice,
        showFilter: false,
        showSort: false,
        textLink: { idField: 'id', action: 'edit' },
        width: '100',
    },
    {
        title: 'Qty',
        fieldName: 'stockQuantity',
        showFilter: false,
        showSort: false,
        textLink: { idField: 'id', action: 'edit' },
    },
    {
        title: 'Notes',
        formatData: (item: any) => item.attributes.notes,
        showFilter: false,
        showSort: false,
        textLink: { idField: 'id', action: 'edit' },
    },
];

export const browseConfig = {
    dataId: 'scBlowers',
    apiBasePath,
    createApiPath: (path: string, pageNum: number, pageSize: number, filterValues?: FilterValues | undefined) => {
        if (filterValues?.hasOwnProperty('name') && filterValues['name'].length) {
            return `${path}?pageNum=${pageNum}&pageSize=${pageSize}&searchTerm=${encodeURIComponent(filterValues?.['name'].toString())}`;
        }
        return `${path}?pageNum=${pageNum}&pageSize=${pageSize}`;
    },
    transform: undefined,
    tableStructure,
    urlPath: link,
};

export const addEditConfig = {
    dataId: 'scBlowers',
};


export const formStructure = [
    {
        rowTitle: 'Side Channel Blowers',
        key: 'scBlowersForm',
        elements: [
            { label: 'Model', key: 'model', fieldName: 'attributes.model', inputType: 'string', size: 'sm' },
            { label: 'Size', fieldName: 'attributes.size', key: 'size', inputType: 'string', size: 'sm' },
            { label: 'Motor kW', key: 'motorSize', fieldName: 'attributes.motorSize', inputType: 'string', size: 'sm' },
            { label: 'Stock Qty', fieldName: 'stockQuantity', key: 'stockQuantity', inputType: 'number', size: 'sm' },
            { label: 'Stage', fieldName: 'attributes.stage', key: 'stage', inputType: 'string', size: 'sm' },
            { label: 'Phase', key: 'phase', fieldName: 'attributes.phase', inputType: 'string', size: 'sm' },
            { label: 'Curve No', key: 'cr', fieldName: 'attributes.cr', inputType: 'string', size: 'sm' },
            { label: 'OEM Price', fieldName: 'attributes.oemPrice', key: 'oemPrice', inputType: 'number', size: 'sm' },
            { label: 'List Price', fieldName: 'attributes.listPrice', key: 'listPrice', inputType: 'number', size: 'sm' },
            { label: 'Weight', key: 'weight', fieldName: 'attributes.weight', inputType: 'string', size: 'sm' },
            { label: 'Carton Size', key: 'cartonSize', fieldName: 'attributes.cartonSize', inputType: 'string', size: 'sm' },
            { label: 'Description', fieldName: 'description', key: 'description', inputType: 'textarea' },
            { label: 'Notes', fieldName: 'attributes.notes', key: 'notes', inputType: 'textarea' },
            {
                actionType: 'add',
                inputType: 'submit',
                fieldName: 'add',
                key: 'scBlowersAddKey',
                title: 'Add Side Channel Blower',
            },
            {
                actionType: 'edit',
                inputType: 'submit',
                fieldName: 'update',
                key: 'updateSCBlowersKey',
                title: 'Update Side Channel Blower',
            },
        ],
    },
];

export const formValidation = Yup.object().shape({
    attributes: Yup.object().shape({
        model: Yup.string().required('Model required'),
        size: Yup.string().required('Size required'),
        motorSize: Yup.string().required('Motor Size required'),
        stage: Yup.string().required('Stage required'),
        phase: Yup.string().required('Phase required'),
        oemPrice: Yup.number().required('OEM Price required as a positive number').positive(),
        listPrice: Yup.number().required('List Price required as a positive number').positive(),
    }),
    stockQuantity: Yup.number().required('Stock Qty required as a positive number').integer(),
});

export const submitAddTransform = (formData: any) => {
    return formData;
};

export const submitUpdateTransform = (formData: any) => {
    return formData;
};
