import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const HubBush = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Hub Bush</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.hubBushQty'
                type='text'
                control={control}
                 
                label='Qty'
                style={{width: '9%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.hubBushSize'
                type='text'
                control={control}
                 
                label='Size'
                style={{width: '14%', marginRight: '1%'}}
            />
        </>   
    );
};

export default HubBush;
