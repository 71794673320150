import { Button, InputLabel, useMediaQuery } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useParams } from 'react-router-dom';
import { useFormikContext } from 'formik';
import Select from 'components/Select';
import Input from 'components/Input';
import Checkbox from 'components/Checkbox';
import PropTypes from 'prop-types';
import React from 'react';
import { useTheme } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
    tr: {
        backgroundColor: 'white',
        border: '1px solid black',
    },
    th: {
        width: '170px',
    },
    td: {
        paddingBottom: theme.spacing(1),
    },
    label: {
        textAlign: 'left',
        verticalAlign: 'top',
        fontWeight: 'bold',
    },
    input: {
        marginBottom: theme.spacing(2),
        marginRight: theme.spacing(1),
        width: '100%',
    },
}));

const FormRow = ({ rowTitle, elements, isSubmitting, isFetching, variant = 'outlined', showLabel = false, inputProps = {} }) => {
    const { values, errors, touched } = useFormikContext();
    const classes = useStyles();
    const { action: currentAction } = useParams();
    const theme = useTheme();
    const isDesktop = useMediaQuery(theme.breakpoints.up('md'));

    return (
        <tr className={classes.tr}>
            {showLabel && (
                <th className={classes.th}>
                    <InputLabel className={classes.label}>{rowTitle && rowTitle}</InputLabel>
                </th>
            )}
            <td className={classes.td}>
                {elements.map(
                    ({
                        fieldName,
                        actionType,
                        key: inputKey,
                        inputType,
                        apiPath,
                        options,
                        transform,
                        key,
                        label,
                        placeholder,
                        title,
                        size,
                        disabled,
                    }) => {
                        const fieldProps = { label, placeholder };
                        const style = { width: '98%' };

                        if (isDesktop && size) {
                            switch (size) {
                                case 'xs':
                                    style.width = '12%';
                                    break;
                                case 'sm':
                                    style.width = '24%';
                                    break;
                                case 'md':
                                    style.width = '49%';
                                    break;
                                case 'lg':
                                    style.width = '74%';
                                    break;
                                case 'xl':
                                    style.width = '80%';
                                    break;
                                default:
                                    break;
                            }
                        }

                        if (!inputType || (actionType && currentAction !== actionType)) {
                            return null;
                        }

                        if (inputType === 'hidden') {
                            return <input type={inputType} key={key} name={fieldName} value={values[fieldName] || ''} />;
                        }

                        const hasError = Boolean(errors[fieldName]) && Boolean(touched[fieldName]);

                        if (inputType === 'select') {
                            return (
                                <Select
                                    label={label}
                                    fieldName={fieldName}
                                    apiPath={apiPath}
                                    options={options}
                                    error={hasError}
                                    fieldValue={values[fieldName]}
                                    variant={variant}
                                    className={classes.input}
                                    style={style}
                                />
                            );
                        }

                        if (inputType === 'multipleSelect') {
                            return (
                                <Select
                                    label={label}
                                    fieldName={fieldName}
                                    apiPath={apiPath}
                                    options={options}
                                    error={hasError}
                                    fieldValue={values[fieldName]}
                                    variant={variant}
                                    className={classes.input}
                                    style={style}
                                    multiple={true}
                                />
                            );
                        }
                        
                        if (inputType === 'textarea') {
                            return (
                                <Input
                                    disabled={disabled}
                                    type={inputType}
                                    name={fieldName}
                                    autoComplete="false"
                                    variant={variant}
                                    className={classes.input}
                                    {...fieldProps}
                                    {...inputProps}
                                    style={style}
                                    error={hasError}
                                    key={key}
                                    InputProps={{
                                        multiline:true
                                    }}
                                />
                            )
                        }

                        if (inputType === 'date') {
                            return (
                                <Input
                                    disabled={disabled}
                                    type={inputType}
                                    name={fieldName}
                                    autoComplete="false"
                                    variant={variant}
                                    className={classes.input}
                                    {...fieldProps}
                                    {...inputProps}
                                    style={style}
                                    error={hasError}
                                    key={key}
                                />
                            )
                        }

                        if (inputType === 'checkbox') {
                            fieldProps.checked = values[fieldName];

                            return (
                                <Checkbox
                                    name={fieldName}
                                    variant={variant}
                                    className={classes.input}
                                    {...fieldProps}
                                    {...inputProps}
                                    style={style}
                                    error={hasError}
                                    key={key}
                                />
                            )
                        }

                        if (['submit', 'button'].includes(inputType)) {
                            return (
                                <div key={key}>
                                    <Button
                                        variant="contained"
                                        color="secondary"
                                        type={inputType}
                                        disabled={isSubmitting || isFetching}
                                        // showSpinner={isSubmitting || isFetching}
                                        {...inputProps}
                                    >
                                        {title}
                                    </Button>
                                </div>
                            );
                        }

                        return (
                            <Input
                                disabled={disabled}
                                type={inputType}
                                name={fieldName}
                                autoComplete="false"
                                variant={variant}
                                className={classes.input}
                                {...fieldProps}
                                {...inputProps}
                                style={style}
                                error={hasError}
                                key={key}
                            />
                        );
                    },
                )}
            </td>
        </tr>
    );
};

FormRow.propTypes = {
    rowTitle: PropTypes.string,
    elements: PropTypes.array,
    isSubmitting: PropTypes.bool.isRequired,
    isFetching: PropTypes.bool.isRequired,
    size: PropTypes.object,
};

FormRow.defaultProps = {
    rowTitle: undefined,
    elements: [],
    size: undefined,
};

export default FormRow;
