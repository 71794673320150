import React from 'react';
import { Container } from '@material-ui/core';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import ContentHeader from 'components/ContentHeader';
import Content from './Content';
import { SCBLOWERS } from './constants';

const useStyles = makeStyles({
    root: {
        position: 'relative',
        margin: '20px',
        padding: '20px',
    },
    list: {
        width: 250,
    },
});

export interface RouterProps {
    id: string;
    action: string;
}

export interface ProductsProps extends RouteComponentProps<RouterProps> {}

const Products: React.FC<ProductsProps> = ({ match }) => {
    const classes = useStyles();
    const { action, id } = match?.params || {};
    const { actions, title } = SCBLOWERS;

    return (
        <Container maxWidth={false}>
            <ContentHeader title={title} actions={actions} action={action} />
            <Content action={action} id={id} />
        </Container>
    );
};

export default withRouter(Products);
