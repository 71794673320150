import React from 'react';
import MuiAlert, { AlertProps as MuiAlertProps } from '@material-ui/lab/Alert';
import { AlertTitle } from '@material-ui/lab';

interface AlertProps extends MuiAlertProps {
    errors?: any;
}

const Alert: React.FC<AlertProps> = (props) => {
    const keys = Object.keys(props.errors);

    return (
        <MuiAlert elevation={6} variant="filled" severity={props.severity}>
            <AlertTitle>{props.children}</AlertTitle>
            {Boolean(keys.length) && (
                <ul style={{ marginLeft: 0, paddingLeft: 0 }}>
                    {keys.map((key) => {
                        return <li key={key}>{props.errors[key][0]}</li>;
                    })}
                </ul>
            )}
        </MuiAlert>
    );
};

export default Alert;
