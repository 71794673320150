import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';

const Shaft = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Shaft</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.shaftQty'
                type='text'
                control={control}
                 
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.shaftDEDia'
                type='text'
                control={control}
                 
                label='DE Dia'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.shaftNDEDia'
                type='text'
                control={control}
                 
                label='NDE Dia'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.shaftMaterial'
                type='text'
                control={control}
                 
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.shaftCoolingWheel'
                label="Cooling Wheel"     
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.shaftNotes'
                type='text'
                control={control}
                label='Notes'
                style={{width: '19%', marginRight: '1%'}}
            />

        </>   
    );
};

export default Shaft;
