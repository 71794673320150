import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const LagClad = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Lag & Clad</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.lagCladQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.lagCladInsulationType'
                type='text'
                control={control}
                label='Insulation Type'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.lagCladInsulationThickness'
                type='text'
                control={control}
                label='Insulation Thickness'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.lagCladCladdingType'
                type='text'
                control={control}
                label='Cladding Type'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.lagCladCladdingThickness'
                type='text'
                control={control}
                label='Cladding Thickness'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default LagClad;
