import React from 'react';
import { ThemeProvider } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import Amplify from '@aws-amplify/core';
import theme from 'theme';
import axios from 'axios';

import QuotesWrapper from './screens/Quotes/Wrapper';
import NewQuotesWrapper from './screens/Quotes/New/Wrapper';


import QuotesNew from './screens/Quotes/New';
import QuotesEdit from './screens/Quotes/Edit';
import Quotes from './screens/Quotes/List';
import JobsNew from './screens/Jobs/New';
import JobsClone from './screens/Jobs/Clone';
import JobsEdit from './screens/Jobs/Edit';
import Jobs from './screens/Jobs/List';
import WIP from './screens/Jobs/WIP';
import Clients from './screens/Clients';
import SCBlowers from './screens/SCBlowers';
import Users from './screens/Users';
import Layout from './components/Layout';
import SecurityCheck from './components/SecurityCheck';
import { appAuth } from 'constants/auth';
import Feedback from 'components/Feedback';
import { getApiPath } from 'helpers/app';
import { AuthProvider } from 'providers/auth';
import Reports from 'screens/Reports';

Amplify.configure(appAuth);

export const axiosInstance = axios.create({
    baseURL: getApiPath(),
    responseType: 'json',
    headers: {
        'Content-Type': 'application/json',
        Accept: 'application/json',
    },
});

const App = () => (
    <ThemeProvider theme={theme}>
        <AuthProvider>
            <Router>
                <CssBaseline />
                <SecurityCheck>
                    <Layout>
                        <Switch>
                            <Route exact path="/">
                                <Clients />
                            </Route>
                            <Route path="/clients/:action?/:id?">
                                <Clients />
                            </Route>
                            <Route path="/scblowers/:action?/:id?">
                                <SCBlowers />
                            </Route>
                            <Route path="/users/:action?/:id?">
                                <Users />
                            </Route>
                            <Route path="/reports">
                                <Reports />
                            </Route>
                            <Route path="/jobs/new/:quoteID/:revision/:itemID">
                                <JobsNew />
                            </Route>
                            <Route path="/jobs/edit/:jobID">
                                <JobsEdit />
                            </Route>
                            <Route path="/jobs/clone/:jobID">
                                <JobsClone />
                            </Route>
                            <Route path="/jobs/wip">
                                <QuotesWrapper>
                                    <WIP />
                                </QuotesWrapper>
                            </Route>
                            <Route path="/jobs">
                                <QuotesWrapper>
                                    <Jobs />
                                </QuotesWrapper>
                            </Route>
                            <Route path="/quotes/new/client/:clientId">
                                <NewQuotesWrapper>
                                    <QuotesNew />
                                </NewQuotesWrapper>
                            </Route>
                            <Route path="/quotes/edit/:quoteID/:revision/:itemID">
                                <QuotesWrapper>
                                    <QuotesEdit />
                                </QuotesWrapper>
                            </Route>
                            <Route path="/quotes">
                                <QuotesWrapper>
                                    <Quotes />
                                </QuotesWrapper>
                            </Route>
                        </Switch>
                    </Layout>
                </SecurityCheck>
            </Router>
        </AuthProvider>
        <Feedback />
    </ThemeProvider>
);

export default App;
