import { useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useStyles } from '../../styles';
import { axiosInstance } from 'App';
import Form from './Form';

const SCBlower = ({ quoteData, itemList, revList, clientList, quoteStatusList, salesRepList, productCategoryList, productAreaList, enquirySourceList, industryList, applicationList }) => {
    const classes = useStyles();

    const [blowers, setBlowers] = useState();
    const [blowersObj, setBlowersObj] = useState();

    const [quoteDetails, setQuoteDetails] = useState();

    useEffect(() => {
        const fetchBlowers = async () => {
            const result = await axiosInstance({ url: '/scblowers?pageSize=100000' });

            const obj = result.data.reduce((obj, item) => ({
                ...obj,
                [item.id]: item,
            }), {});

            setBlowers(result.data);
            setBlowersObj(obj);
        };

        fetchBlowers();

        const fetchQuoteDetails = async () => {
            const result = await axiosInstance({ url: `/quotedetails/${quoteData.quoteID}/${quoteData.itemID}/${quoteData.revision}/scblowers` });
        
            setQuoteDetails(result.data);
        };

        fetchQuoteDetails();
    }, []);

    
    return (quoteDetails && blowers && blowersObj ? 
        <Form 
            quoteData={quoteData}
            quoteDetails={quoteDetails}
            itemList={itemList}
            revList={revList}
            clientList={clientList}
            quoteStatusList={quoteStatusList}
            salesRepList={salesRepList}
            productCategoryList={productCategoryList}
            productAreaList={productAreaList}
            enquirySourceList={enquirySourceList}
            industryList={industryList}
            applicationList={applicationList}
            blowers={blowers}
            blowersObj={blowersObj} /> : 
        <Backdrop open={true} className={classes.backdrop}>
            <CircularProgress color="primary" />
        </Backdrop>
    );
};

export default SCBlower;
