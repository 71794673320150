import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';
import SelectWithDataReactHook from 'components/SelectWithDataReactHook';

const InletCone = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Inlet Cone</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.inletConeQty'
                type='text'
                control={control}
                 
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <SelectWithDataReactHook
                name='attributes.inletConeType'
                type='text'
                control={control}
                label="Type"
                options={[
                    {
                        label: 'Flared',
                        id: 'Flared'
                    },
                    {
                        label: '3-Piece',
                        id: '3-Piece'
                    },
                    {
                        label: 'Split',
                        id: 'Split'
                    }
                ]}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.inletConeMaterial'
                type='text'
                control={control}
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.inletConeThickness'
                type='text'
                control={control}
                label='Thickness'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.inletConeAntiSpark'
                label='Anti Spark'
                style={{width: '10%', marginRight: '1%'}}
            />

        </>   
    );
};

export default InletCone;
