import React, { useMemo } from 'react';
import { makeStyles } from '@material-ui/core/styles';

import Title from './Title';
import ActionButton from './ActionButton';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
        paddingTop: theme.spacing(2),
        display: 'flex',
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('md')]: {
            paddingLeft: theme.spacing(2),
            paddingRight: theme.spacing(2),
        },
    },
    titleWrapper: {
        flex: '1',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        [theme.breakpoints.down('xs')]: {
            paddingBottom: theme.spacing(1),
        },
    },
    actionWrapper: {
        minWidth: '100px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },
}));

interface ContentHeaderProps {
    title: string;
    subtitle?: string | undefined;
    actions?: any[];
    action?: string | undefined;
}

const ContentHeader: React.FC<ContentHeaderProps> = ({ title, subtitle, actions = [], action = '' }) => {
    const classes = useStyles();

    const actionButtons = useMemo(
        () => actions.map(({ key, ...thisAction }) => <ActionButton key={key} {...thisAction} action={action} />),
        [action, actions],
    );

    return (
        <div className={classes.wrapper}>
            <div className={classes.titleWrapper}>
                <Title title={title} subtitle={subtitle} />
            </div>
            <div className={classes.actionWrapper}>{actionButtons}</div>
        </div>
    );
};

export default ContentHeader;
