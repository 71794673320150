import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Button, Box, Typography } from '@material-ui/core';
import { Formik } from 'formik';
import Input from 'components/Input';
import { initialCodeValues, codeFormValidation, codeFormStructure } from 'constants/auth';
import useStyles from './inputStyles';
import { addFeedback } from 'actions';
import { useDispatch } from 'store';
import { useAuth } from 'providers/auth';

export interface CodeFormProps {
    showLogin: any;
    username: string;
}

const CodeForm: React.SFC<CodeFormProps> = ({ showLogin, username }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { resetPassword, passwordReminder }: any = useAuth();
    const [submitStatus, setSubmitStatus] = useState();

    const handleSendCode = () => {
        passwordReminder(username);
    };

    return (
        <Box>
            <Typography variant="h5" component="h2" className={classes.heading}>
                Reset your password
            </Typography>
            <Formik
                initialValues={initialCodeValues}
                validationSchema={codeFormValidation}
                onSubmit={async ({ code, new_password }, actions) => {
                    const { severity, message } = await resetPassword(username, code, new_password);

                    dispatch(addFeedback(message, severity));
                    if (severity === 'success') {
                        showLogin();
                    }
                    setSubmitStatus(message);
                }}
            >
                {(props) => {
                    const { handleSubmit, isSubmitting, setSubmitting } = props;

                    if (isSubmitting && submitStatus) {
                        setSubmitting(false);
                        setSubmitStatus(undefined);
                    }

                    return (
                        <form onSubmit={handleSubmit}>
                            <table style={{ margin: 'auto' }}>
                                <tbody>
                                    {codeFormStructure.map(({ key, ...rest }) => {
                                        return (
                                            <tr key={key}>
                                                <td>
                                                    <Input {...rest} className={classes.input} variant="outlined" />
                                                </td>
                                            </tr>
                                        );
                                    })}
                                    <tr>
                                        <td>
                                            <p>
                                                <Link to="#" onClick={showLogin} color="secondary">
                                                    Return to Login Form
                                                </Link>{' '}
                                                |{' '}
                                                <Link to="#" onClick={handleSendCode} color="secondary">
                                                    Resend Code
                                                </Link>
                                            </p>
                                            <Button
                                                type="submit"
                                                disabled={isSubmitting}
                                                color="primary"
                                                variant="contained"
                                                className={classes.submit}
                                                size="large"
                                            >
                                                Set Password
                                            </Button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </form>
                    );
                }}
            </Formik>
        </Box>
    );
};

export default CodeForm;
