import { Button, ListItemText, Typography, List } from '@material-ui/core';
import ContentWrapper from 'components/ContentWrapper';
import makePost from 'helpers/makePost';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ReactHookTextField from '../../components/ReactHookTextField';

export const formValidation = Yup.object().shape({
    password: Yup.string().required('Password required'),
});

const ResetPasswordForm = ({ userId }) => {
    const dispatch = useDispatch();

    const { handleSubmit, control, reset } = useForm({
        resolver: yupResolver(formValidation),
        defaultValues: {
            password: '',
        },
    });

    const onSubmit = async (data) => {
        try {
            const response = await makePost({
                url: `/users/${userId}/resetPassword`,
                data: {
                    ...data,
                },
            });
            dispatch(addFeedback('Password has been reset', 'success'));
        } catch (error) {
            const errData = error.response.data;
            dispatch(addFeedback(errData?.title || errData, 'error', errData.errors));
        } finally {
            reset();
        }
    };

    return (
        <ContentWrapper>
            <form onSubmit={handleSubmit(onSubmit)}>
                <ReactHookTextField
                    name="password"
                    type="text"
                    control={control}
                    label="Temporary Password"
                    style={{ width: '15%', marginRight: '1%' }}
                />
                <Button variant="contained" color="secondary" type="submit">
                    Reset Password
                </Button>

                <Typography>User will be required to set a new password after using the temporary password.</Typography>
                <Typography>Password should:</Typography>
                <Typography variant='subtitle2'>- Have minimum 8 characters</Typography>
                <Typography variant='subtitle2'>- Contain at least 1 number</Typography>
                <Typography variant='subtitle2'>- Contain at least 1 special character</Typography>
                <Typography variant='subtitle2'>- Contain at least 1 lowercase letter</Typography>
                <Typography variant='subtitle2'>- Contain at least 1 uppercase letter</Typography>
            </form>
        </ContentWrapper>
    );
};

export default ResetPasswordForm;
