import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';

const OuletSilencer = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Outlet Silencer</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.outletSilencerQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.outletSilencerType'
                type='text'
                control={control}
                label='Type'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.outletSilencerMaterial'
                type='text'
                control={control}
                 
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.outletSilencerDimensions'
                type='text'
                control={control}
                label='Dimensions'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField 
                control={control}
                name='attributes.outletSilencerMisc'
                label='Misc'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default OuletSilencer;
