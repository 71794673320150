import { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Typography, Select, FormControl, Backdrop, CircularProgress } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import { useStyles, SelectAddInput } from '../styles';
import makePost from 'helpers/makePost';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';

const formatSubmitData = (data) => ({
    clientID: parseInt(data.clientID),
    contactID: parseInt(data.contactID),
    clientDetails: data.clientDetails,
    addressID: parseInt(data.addressID), 
    createdAt: new Date(data.createdAt).toISOString(),
    awardedAt: new Date(data.awardedAt).toISOString(),
    salesRepID: data.salesRepID,
    enquirySourceID: parseInt(data.enquirySourceID),
    productAreaID: parseInt(data.productAreaID), 
    quoteStatusID: parseInt(data.quoteStatusID), 
    project: data.project,
    fanSerial: data.fanSerial,
    comments: data.comments,
    productCategoryID: parseInt(data.productCategoryID),
    industryID: data.industryID ? parseInt(data.industryID) : null,
    applicationID: data.applicationID ? parseInt(data.applicationID): null
});

const Header = ({ quoteData, itemList, revList, productCategoryList, getValues }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();
    const { quoteID, itemID, revision } = quoteData;
    
    const [isLoading, setIsLoading] = useState(false);

    const handleItemAdd = async (event) => {
        setIsLoading(true);

        try {
            const response = await makePost({
                url: `/quotes/${quoteID}`,
                data: formatSubmitData({
                    ...getValues(),
                    productCategoryID: event.target.value
                }),
            });

            const { quoteID: resQuoteID, itemID: resItemID, revision: resRevision } = response.data;

            dispatch(addFeedback('New item initialised', 'success'));
            history.push(`/quotes/edit/${resQuoteID}/${resRevision}/${resItemID}`);
        } catch (error) {
            setIsLoading(false);
            dispatch(addFeedback(error.response.data.title || error.response.data, 'error', error.response.data.errors));
        }
    };

    const handleRevAdd = async () => {
        setIsLoading(true);

        try {
            const response = await makePost({
                url: `/quotes/${quoteID}/${itemID}/${revision}/clone`
            });

            const { quoteID: resQuoteID, itemID: resItemID, revision: resRevision } = response.data;

            dispatch(addFeedback('New revision initialised', 'success'));
            history.push(`/quotes/edit/${resQuoteID}/${resRevision}/${resItemID}`);
        } catch (error) {
            setIsLoading(false);
            dispatch(addFeedback(error.response.data.title || error.response.data, 'error', error.response.data.errors));
        }
    };

    const handleItemChange = async (event) => {
        history.push(`/quotes/edit/${quoteID}/${event.target.value}`)
    };

    const handleRevChange = async (event) => {
        history.push(`/quotes/edit/${quoteID}/${event.target.value}/${itemID}`)
    };
    
    return (
        <>
            <Backdrop open={isLoading} className={classes.backdrop}>
                <CircularProgress color="primary" />
            </Backdrop>

            <Typography variant="h1" className={classes.header}>
                Quote: {quoteID} Revision: 
                <FormControl variant='outlined' className={classes.select}>   
                    <Select native onChange={handleRevChange} margin='dense'>
                        {revList}
                    </Select>
                </FormControl>

                <Select
                    native
                    input={<SelectAddInput />}
                    onClick={handleRevAdd}
                    IconComponent={AddIcon}
                    classes={{icon:classes.selectAddIcon}}
                    >
                </Select>

                Item: 
                <FormControl variant='outlined' className={classes.select}>    
                    <Select native onChange={handleItemChange} margin='dense'>
                        {itemList}
                    </Select>
                </FormControl>

                <Select
                    native
                    input={<SelectAddInput />}
                    onChange={handleItemAdd}
                    IconComponent={AddIcon}
                    classes={{icon:classes.selectAddIcon}}
                    >
                        <option aria-label="Add new item" value="" selected />
                        {productCategoryList.map(({ id, label }) => (
                           <option key={`cat-${id}`} value={id}>
                               {label}
                            </option>
                        ))};
                </Select>
            </Typography>
        </>
    );
};

export default Header;
