import React from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { FormControlLabel, Checkbox } from '@material-ui/core';

export interface InputProps {
    name: string;
    variant?: string;
    label: string;
    className: any;
    style: any;
    InputProps: any;
}

const Input: React.FC<InputProps> = ({ variant, label, className, style, InputProps, ...rest }) => {
    const { errors, touched } = useFormikContext();
    const fieldName = rest.name;
    const helper = getIn(touched, fieldName) && getIn(errors, fieldName);

    return (
        <FormControlLabel
            className={className}
            style={style}
            control={<Field
                {...rest}
                type={'checkbox'}
                as={Checkbox}
                variant={variant}
                helperText={helper}
                InputProps={InputProps}
            />}
            label={label}
        />
    );
};

export default Input;
