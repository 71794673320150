import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const InletVanes = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Inlet Vanes</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.inletVanesQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.inletVanesControl'
                type='text'
                control={control}
                label='Control'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default InletVanes;
