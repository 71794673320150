import { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useStyles } from '../styles';
import { axiosInstance } from 'App';
import SCBlower from './SCBlower';
import SpareParts from './SpareParts';
import CenFan from './CenFan';
import ManFan from './ManFan';

const alphabetPosition = (text) => {
    return [...text].map(a => parseInt(a, 36) - 10).filter(a => a >= 0);
}

const QuotesEdit = ({ isLoading, clientList, salesRepList, productAreaList, quoteStatusList, productCategoryList, enquirySourceList, industryList, applicationList }) => {
    const classes = useStyles();
    const { quoteID, itemID, revision } = useParams();
    
    const [quoteData, setQuoteData] = useState({
        clientID: '',
        contactID: '',
        addressID: '', 
        createdAt: '',
        awardedAt: '',
        salesRepID: '',
        enquirySourceID: '',
        productAreaID: '',
        quoteStatusID: '',
        project: '',
        fanSerial: '',
        comments: '',
        productCategoryID: '',
    });
    const [quoteIsLoading, setQuoteIsLoading] = useState(true);

    const [itemList, setItemList] = useState();
    const [revList, setRevList] = useState();

    useEffect(() => {
        setQuoteIsLoading(true)

        const getData= async () => {
            const resultQuote = await axiosInstance({ url: `/quotes/${quoteID}/${itemID}/${revision}` });
            
            setQuoteData(resultQuote.data);

            const resultItemRev = await axiosInstance({ url: `/quotes/${quoteID}` });
            const iID = parseInt(itemID);

            const groups = resultItemRev.data.reduce((groups, item) => {
                const group = (groups[item.itemID] || []);
                group.push(item);
                groups[item.itemID] = group;
                return groups;
              }, {});

            const itemList = Object.keys(groups).map((id) => {
                const rev = groups[id].reduce((prev, current) => {
                    return (+alphabetPosition(prev.revision) > +alphabetPosition(current.revision)) ? prev : current
                })

                return (
                    <option key={`item-${rev.itemID}`} value={`${rev.revision}/${rev.itemID}`} selected={iID === rev.itemID}>
                        {rev.itemID}
                    </option>
                )
            });

            setItemList(itemList);

            const revList = resultItemRev.data.filter((i) => i.itemID === iID).map((item) => (
                <option key={`rev-${item.revision}`} value={item.revision} selected={revision === item.revision}>
                    {item.revision}
                </option>
            ));

            setRevList(revList)

            setQuoteIsLoading(false)
        };

        getData();
    }, [quoteID, itemID, revision]);

    const concatIsLoading = isLoading || quoteIsLoading;

    return (
        <>
            <Backdrop open={concatIsLoading} className={classes.backdrop}>
                <CircularProgress color="primary" />
            </Backdrop>

            { !concatIsLoading && 
                <>
                    {parseInt(quoteData.productCategoryID) === 1 && 
                        <SCBlower
                            quoteData={quoteData}
                            itemList={itemList}
                            revList={revList}
                            clientList={clientList}
                            quoteStatusList={quoteStatusList}
                            salesRepList={salesRepList}
                            productCategoryList={productCategoryList}
                            productAreaList={productAreaList}
                            enquirySourceList={enquirySourceList}
                            industryList={industryList}
                            applicationList={applicationList}
                        />
                    }
                    {parseInt(quoteData.productCategoryID) === 2 && 
                        <SpareParts
                            quoteData={quoteData}
                            itemList={itemList}
                            revList={revList}
                            clientList={clientList}
                            quoteStatusList={quoteStatusList}
                            salesRepList={salesRepList}
                            productCategoryList={productCategoryList}
                            productAreaList={productAreaList} 
                            enquirySourceList={enquirySourceList}
                            industryList={industryList}
                            applicationList={applicationList}
                        />
                    }
                    {parseInt(quoteData.productCategoryID) === 3 && 
                        <CenFan
                            quoteData={quoteData}
                            itemList={itemList}
                            revList={revList}
                            clientList={clientList}
                            quoteStatusList={quoteStatusList}
                            salesRepList={salesRepList}
                            productCategoryList={productCategoryList}
                            productAreaList={productAreaList} 
                            enquirySourceList={enquirySourceList}
                            industryList={industryList}
                            applicationList={applicationList}
                        />
                    }
                    {parseInt(quoteData.productCategoryID) === 4 && 
                        <ManFan
                            quoteData={quoteData}
                            itemList={itemList}
                            revList={revList}
                            clientList={clientList}
                            quoteStatusList={quoteStatusList}
                            salesRepList={salesRepList}
                            productCategoryList={productCategoryList}
                            productAreaList={productAreaList} 
                            enquirySourceList={enquirySourceList}
                            industryList={industryList}
                            applicationList={applicationList}
                        />
                    }
                </>
            }
        </>
    );
};

export default QuotesEdit;
