import { useEffect, useState } from 'react';
import { TextField, FormControl, Select, InputLabel, FormHelperText } from '@material-ui/core';
import { useStyles } from '../../styles';
import { Controller, useWatch } from "react-hook-form";
import ReactHookTextField from '../../../../components/ReactHookTextField';
import NumberFormat from '../../../../components/NumberFormat';

const Row = ({ control, index, watchIsOemPrice, blowers, blowersObj, setTotalCost, totalCost, setValue }) => {
    const classes = useStyles();

    const [itemTotalCost, setItemTotalCost] = useState(0)

    const productId = useWatch({
        control,
        name: `lineItems.${index}.product.id`
    });

    const qty = useWatch({
        control,
        name: `lineItems.${index}.quantity`
    });

    const oemPrice = useWatch({
        control,
        name: `lineItems.${index}.attributes.oemPrice`
    });
    
    const listPrice = useWatch({
        control,
        name: `lineItems.${index}.attributes.listPrice`
    });
    
    useEffect(() => {
        if (productId) {
            setValue(`lineItems.${index}.attributes.oemPrice`, oemPrice);
            setValue(`lineItems.${index}.attributes.listPrice`, listPrice);
        }
    }, []);

    useEffect(() => {
        const price = watchIsOemPrice === 'true' ? oemPrice : listPrice;
        
        setItemTotalCost((qty || 0) * (productId ? price : 0));

        setTotalCost([
            ...totalCost.slice(0,index),
            (qty || 0) * (productId ? price : 0),
            ...totalCost.slice(index+1)
        ]);

    }, [watchIsOemPrice, productId, qty, oemPrice, listPrice]);

    useEffect(() => {
        setTotalCost([
            ...totalCost.slice(0,index),
            itemTotalCost,
            ...totalCost.slice(index+1)
        ]);

    }, [itemTotalCost]);
    return (
        <>
            <Controller
                name={`lineItems.${index}.product.id`}
                control={control} 
                render={({ field, fieldState: { error }}) => (
                    <FormControl 
                        key={field.id} 
                        variant='outlined' 
                        className={classes.input} 
                        style={{width: '22%', marginRight: '0.5%'}}
                        error={error}>
                        <InputLabel margin="dense" className={classes.selectLabel}>Model - Phase</InputLabel>

                        <Select 
                            native margin='dense' 
                            onChange={(e) => {
                                const prodId = e.target.value;
                                field.onChange(prodId);
                                setValue(`lineItems.${index}.attributes.oemPrice`, blowersObj[prodId].attributes.oemPrice);
                                setValue(`lineItems.${index}.attributes.listPrice`, blowersObj[prodId].attributes.listPrice);
                            }} 
                            defaultValue={field.value}>
                            <option aria-label="None" value="" />
                            {blowers.map((d) => {
                                return (
                                    <option key={`blower-${index}-${d.id}`} value={d.id}>
                                        {d.attributes.model} - {d.attributes.phase}
                                    </option>
                                )
                            })}
                        </Select>

                        {error && <FormHelperText>{error.message}</FormHelperText> }
                    </FormControl>
                )}
            />
            
            <Controller
                name={`lineItems.${index}.quantity`}
                type='number'
                control={control}
                render={({ field, fieldState: { error } }) => <TextField 
                    {...field} 
                    label='Qty'
                    style={{width: '5%', marginRight: '0.5%'}}
                    className={classes.input}
                    variant='outlined' 
                    InputLabelProps={{ shrink: true }} 
                    size='small'
                    error={error}
                    helperText={error && error.message} 
                    />
                }
            />     

            <TextField
                label='Size'
                type='text'
                disabled={true}
                className={classes.disabledInput}
                variant='outlined' 
                InputLabelProps={{ shrink: true }} 
                size='small'
                value={productId && blowersObj[productId].attributes.size}
                style={{width: '10%', marginRight: '0.5%'}}
            />

            <TextField
                label='Motor Size'
                type='text'
                disabled={true}
                className={classes.disabledInput}
                variant='outlined' 
                InputLabelProps={{ shrink: true }} 
                size='small'
                value={productId && blowersObj[productId].attributes.motorSize}
                style={{width: '8%', marginRight: '0.5%'}}
            />

            <TextField
                label='Stage'
                type='text'
                disabled={true}
                className={classes.disabledInput}
                variant='outlined' 
                InputLabelProps={{ shrink: true }} 
                size='small'
                value={productId && blowersObj[productId].attributes.stage}
                style={{width: '10%', marginRight: '0.5%'}}
            />

            <TextField
                label='Stock'
                type='text'
                disabled={true}
                className={classes.disabledInput}
                variant='outlined' 
                InputLabelProps={{ shrink: true }} 
                size='small'
                value={productId && blowersObj[productId].stockQuantity}
                style={{width: '5%', marginRight: '0.5%'}}
            />

            <Controller
                name={`lineItems.${index}.attributes.oemPrice`}
                type='number'
                control={control}
                render={({ field, fieldState: { error } }) => <TextField 
                    {...field}                 
                    label='OEM Price'
                    type='text'
                    disabled={true}
                    className={classes.disabledInput}
                    variant='outlined' 
                    InputLabelProps={{ shrink: true }} 
                    size='small'
                    style={{width: '7%', marginRight: '0.5%', display: watchIsOemPrice === 'true' ? 'inline-block' : 'none'}}  
                    InputProps={{
                        inputComponent: NumberFormat,
                    }}
                    />
                }
            />

            <Controller
                name={`lineItems.${index}.attributes.listPrice`}
                type='number'
                control={control}
                render={({ field, fieldState: { error } }) => <TextField 
                    {...field} 
                    label='List Price'
                    type='text'
                    disabled={true}
                    className={classes.disabledInput}
                    variant='outlined' 
                    InputLabelProps={{ shrink: true }} 
                    size='small'
                    style={{width: '7%', marginRight: '0.5%', display: watchIsOemPrice === 'true' ? 'none' : 'inline-block'}}  
                    InputProps={{
                        inputComponent: NumberFormat,
                    }}
                    />
                }
            />
            
            <TextField
                label='Total Price'
                type='text'
                disabled={true}
                className={classes.disabledInput}
                variant='outlined' 
                InputLabelProps={{ shrink: true }} 
                size='small'
                value={itemTotalCost}
                style={{width: '7%', marginRight: '0.5%'}}
                InputProps={{
                    inputComponent: NumberFormat,
                }}
            />

            <ReactHookTextField
                name={`lineItems.${index}.comments`}
                type='textarea'
                control={control}
                label='Comments'
                style={{width: '22%'}}
                InputProps={{
                    multiline:true
                }}
            />
        </>                
    );
};

export default Row;
