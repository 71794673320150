import { MessageType, SeverityType } from 'types/feedback';

export const addFeedback = (message: MessageType, severity: SeverityType, errors?: any) => {
    return {
        type: 'ADD_FEEDBACK',
        message,
        severity,
        errors,
    };
};
