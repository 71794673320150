import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Table } from '@material-ui/core';
import Pagination from './components/Pagination';
import TBody from './components/TBody';
import THead from './components/THead';

interface DataTableProps {
    addRowContent: any;
    minWidth: any;
}

const DataTable: React.FC<DataTableProps> = ({addRowContent, minWidth}) => {
    const useStyles = makeStyles({
        tableContainer: {
            '&::-webkit-scrollbar': {
                width: '7px'
            },
            '&::-webkit-scrollbar-thumb': {
                borderRadius: '4px',
                backgroundColor: 'rgba(0, 0, 0, .5)',
                boxShadow: '0 0 1px rgba(255, 255, 255, .5)'
            }
        },
        table: {
          minWidth: minWidth,
        },
      });

    const classes = useStyles();

    if (minWidth) {
        return (
            <TableContainer className={classes.tableContainer}>
                <Table className={classes.table}>
                    <THead />
                    <TBody addRowContent={addRowContent} />
                </Table>
                <Pagination />
            </TableContainer>
        );
    };

    return (
        <>
            <Table>
                <THead />
                <TBody addRowContent={addRowContent} />
            </Table>
            <Pagination />
        </>
    );
};

export default DataTable;
