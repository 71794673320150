import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const Pedestal = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Pedestal</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.pedestalQty'
                type='text'
                control={control}
                 
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.pedestalWeld'
                type='text'
                control={control}
                label='Weld'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.pedestalSidePlateThickness'
                type='text'
                control={control}
                 
                label='Side Plate Thickness'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.pedestalTopPlateThickness'
                type='text'
                control={control}
                 
                label='Top Plate Thickness'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.pedestalMaterial'
                type='text'
                control={control}
                 
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            /> 
        </>   
    );
};

export default Pedestal;
