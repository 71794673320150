import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const CouplingGuard = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Coupling Guard</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.couplingGuardQty'
                type='text'
                control={control}
                 
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.couplingGuardMaterial'
                type='text'
                control={control}
                 
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.couplingGuardThickness'
                type='text'
                control={control}
                 
                label='Thickness'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.couplingGuardPaint'
                type='text'
                control={control}
                 
                label='Paint'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.couplingGuardNotes'
                type='text'
                control={control}
                 
                label='Notes'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default CouplingGuard;
