import { Button, Typography, IconButton } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Row from './Row';
import ContentWrapper from 'components/ContentWrapper';

const OtherOptions = ({ control, fields, append, remove }) => {
    return (
        <>
            <Typography variant="h4" gutterBottom>
                Other Options
            </Typography>
            
            { !fields.length ? 
                <Button
                    variant='contained'
                    color='primary'
                    type="button"
                    onClick={() => append({
                        qty: null,
                        description: null,
                        drawing: null,
                        stock: null,
                        supplier: null,
                        orderDate: null,
                        orderDue: null,
                        orderReceived: null,
                        notes: null,
                        
                    })}
                >
                    Add an Option
                </Button> :
                <ContentWrapper>
                    {fields.map((field, index) => {
                        return (
                            <Row key={field.id} control={control} index={index} remove={remove} /> 
                    )})}
                    
                    <div style={{textAlign: 'right'}}>
                        <IconButton
                            type="button"
                            color="secondary"
                            onClick={() => append({})}
                            style={{width: '4%'}}
                            >
                            <AddIcon />
                        </IconButton>
                    </div>
                </ContentWrapper>
            }
        </>   
    );
};

export default OtherOptions;
