import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const Evase = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Evase</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.evaseQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />

            <ReactHookTextField
                name='attributes.evaseNotes'
                type='text'
                control={control}
                label='Notes'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default Evase;
