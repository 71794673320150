import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import SelectWithDataReactHook from 'components/SelectWithDataReactHook';
import ReactHookCheckbox from 'components/ReactHookCheckbox';

const Case = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Case</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.caseQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.caseMaterial'
                type='text'
                control={control}
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.caseThickness'
                type='text'
                control={control}
                label='Thickness'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.caseWeld'
                type='text'
                control={control}
                label='Weld'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.caseNoSplit'
                type='text'
                control={control}
                label='No. of splits'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.casePullout'
                type='text'
                control={control}
                label='Pullout'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.caseInspectionDoor'
                type='text'
                control={control}
                label='Inspection Door'
                style={{width: '19%', marginRight: '1%'}}
            />
            <SelectWithDataReactHook
                name='attributes.caseInspectionDoorType'
                type='text'
                control={control}
                label='Inspection Door Type'
                options={[
                    {
                        label: 'Bolted',
                        id: 'Bolted'
                    },
                    {
                        label: 'Hinged',
                        id: 'Hinged'
                    },
                    {
                        label: 'Raised',
                        id: 'Raised'
                    }
                ]}
                style={{width: '14%', marginRight: '1%'}}
            />
            <SelectWithDataReactHook
                name='attributes.caseGasket'
                type='text'
                control={control}
                label='Gasket'
                options={[
                    {
                        label: 'Rubber',
                        id: 'Rubber'
                    },
                    {
                        label: 'PTFE',
                        id: 'PTFE'
                    },
                    {
                        label: 'Fibreglass',
                        id: 'Fibreglass'
                    }
                ]}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.caseMatchFlanges'
                label='Match Flanges'
                style={{width: '10%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.caseAntiSpark'
                label='Anti Spark'
                style={{width: '10%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.caseDrain'
                type='text'
                control={control}
                label='Drain'
                style={{width: '19%', marginRight: '1%'}}
            />
            <SelectWithDataReactHook
                name='attributes.caseDrainType'
                type='text'
                control={control}
                label='Drain Type'
                options={[
                    {
                        label: 'Socket',
                        id: 'Socket'
                    },
                    {
                        label: 'Plug',
                        id: 'Plug'
                    },
                    {
                        label: 'Valve',
                        id: 'Valve'
                    }
                ]}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.caseInsulation'
                type='text'
                control={control}
                label='Insulation'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.caseFasteners'
                type='text'
                control={control}
                label='Fasteners'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.caseHeatShield'
                type='text'
                control={control}
                label='Heat Shield'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.caseNotes'
                type='text'
                control={control}
                label='Notes'
                style={{width: '19%', marginRight: '1%'}}
            />      
        </>   
    );
};

export default Case;
