import React from 'react';
import { Drawer as MuiDrawer } from '@material-ui/core';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';

import { toggleDrawer, closeDrawer } from 'actions';
import { useDispatch, useTrackedState } from 'store';

const Drawer: React.FC = () => {
    const dispatch = useDispatch();
    const { drawer } = useTrackedState();
    const { open } = drawer;

    const handleToggleDrawer = (event: React.KeyboardEvent | React.MouseEvent) => {
        if (event.type === 'keydown' && ((event as React.KeyboardEvent).key === 'Tab' || (event as React.KeyboardEvent).key === 'Shift')) {
            return;
        }

        dispatch(toggleDrawer());
    };

    const handleCloseDrawer = () => {
        dispatch(closeDrawer());
    };

    const handleClickAway = () => {
        if (open) {
            handleCloseDrawer();
        }
    };

    const content = <div>Test content</div>;

    return (
        <MuiDrawer anchor="left" open={open} onClose={handleToggleDrawer} role="presentation">
            <ClickAwayListener onClickAway={handleClickAway}>
                {/* <ClientLookup closeDrawer={handleCloseDrawer} /> */}
                {content}
            </ClickAwayListener>
        </MuiDrawer>
    );
};

export default Drawer;
