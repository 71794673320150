import React from 'react';
import { Divider } from '@material-ui/core';
import makePost from 'helpers/makePost';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import { ListItem, ListItemText, ListItemSecondaryAction } from '@material-ui/core';
import { openDialog, closeDialog } from 'actions/dialog';
import MenuButton from 'components/MenuButton';
import LocationAutocomplete from 'components/LocationAutocomplete';
import Form from 'components/Form';
import * as Yup from 'yup';

export const addressFormStructure = [
    {
        key: 'address1',
        elements: [
            { key: 'street1', label: 'Street 1', fieldName: 'street1', inputType: 'string', size: 'md' },
            { key: 'street2', label: 'Street 2', fieldName: 'street2', inputType: 'string', size: 'md' },
        ],
    },
    {
        key: 'address2',
        elements: [
            { key: 'city', label: 'Suburb', fieldName: 'city', inputType: 'string', size: 'md' },
            { key: 'state', label: 'State', fieldName: 'state', inputType: 'string', size: 'sm' },
            { key: 'postcode', label: 'Post Code', fieldName: 'postcode', inputType: 'string', size: 'sm' },
        ],
    },
    {
        key: 'address3',
        elements: [
            { key: 'country', label: 'Country', fieldName: 'country', inputType: 'string', size: 'md', defaultValue: 'Australia' },
            {
                key: 'addressTypeID',
                label: 'Address Type',
                fieldName: 'addressTypeID',
                inputType: 'select',
                size: 'md',
                apiPath: '/refdata/AddressType',
            },
        ],
    },
    {
        rowTitle: 'Other',
        key: 'other',
        elements: [
            {
                key: 'industryID',
                label: 'Industry',
                fieldName: 'industryID',
                inputType: 'select',
                size: 'md',
                apiPath: '/refdata/Industry',
            },
            { key: 'email', label: 'Email', fieldName: 'email', inputType: 'string', size: 'md' },
        ],
    },
    {
        rowTitle: 'Notes',
        key: 'notes',
        elements: [{ key: 'notes', label: 'Notes', fieldName: 'notes', inputType: 'textarea' }],
    },
    {
        actionType: 'add',
        elements: [
            {
                inputType: 'hidden',
                fieldName: 'clientID',
                key: 'clientID',
            },
            {
                inputType: 'submit',
                fieldName: 'add',
                key: 'addAddress',
                title: 'Add Address',
            },
        ],
    },
    {
        actionType: 'edit',
        elements: [
            {
                inputType: 'hidden',
                fieldName: 'clientID',
                key: 'clientID',
            },
            {
                inputType: 'hidden',
                key: 'updatedAtKey',
                fieldName: 'updatedAt',
            },
            { key: 'updateAddress', inputType: 'submit', fieldName: 'update', title: 'Update Address' },
        ],
    },
];

export const addressFormValidation = Yup.object().shape({
    street1: Yup.string().required('Street 1 required'),
    addressTypeID: Yup.string().required('Address Type required'),
    industryID: Yup.number().required('Industry required'),
});

export const addressLookupToFormMap = {
    fieldNames: ['street1', 'street2', 'city', 'state', 'postcode', 'country'],
    matches: {
        street_number: {
            fieldName: 'street1',
            position: 0,
        },
        route: {
            fieldName: 'street1',
            position: 1,
        },
        sublocality: {
            dynamicType: 'sublocality_level_',
            fieldName: 'street2',
        },
        locality: {
            fieldName: 'city',
        },
        administrative_area: {
            dynamicType: 'administrative_area_level_',
            fieldName: 'state',
        },
        administrative_area_level_1: {
            fieldName: 'state',
        },
        postal_code: {
            fieldName: 'postcode',
        },
        country: {
            fieldName: 'country',
            display: 'long_name',
        },
    },
};

export const AddressAdd = ({ id, setRefresh }) => {
    const dispatch = useDispatch();

    return (
        <Form
            action='add'
            formStructure={addressFormStructure}
            formValidation={addressFormValidation}
            submitOptions={{
                name: 'Address',
                apiPath: '/addresses',
                transform: data => ({...data, clientID: parseInt(id)}),
                method: 'post',
                callback: ({data}) => {
                    if (data.id) {
                        dispatch(addFeedback('Address was successfully added', 'success'));
                        setRefresh(true);
                        dispatch(closeDialog());
                    } else {
                        dispatch(addFeedback(`There was an error adding the address`, 'error'));
                    }
                },
            }}
            lookupComponent={LocationAutocomplete}
            lookupToFormMap={addressLookupToFormMap}
        />
    );
};

export const AddressEdit = ({ data, setRefresh }) => {
    const dispatch = useDispatch();

    if (data.status === 'Inactive') {
        return null;
    }
    
    const handleEditAddress = () => {
        const content = (
            <Form
                action='edit'
                data={data}
                formStructure={addressFormStructure}
                formValidation={addressFormValidation}
                submitOptions={ {
                    apiPath: `/addresses/${data.id}`,
                    method: 'post',
                    transform: data => data,
                    callback: (res) => {
                        if (res.status === 200) {
                            const message = `Address was successfully updated`;
                            dispatch(addFeedback(message, 'success'));
                            setRefresh(true);
                            dispatch(closeDialog());
                        } else {
                            dispatch(addFeedback(`There was an error updating the address - ${res}`, 'error'));
                        }
                    },
                }}
                lookupComponent={LocationAutocomplete}
                lookupToFormMap={addressLookupToFormMap}
            />
        );

        dispatch(openDialog('Edit Address', content, [], {maxWidth: 'md', fullWidth: true}));
    };

    const handleRemoveAddress = async () => {
        const { status } = await makePost({
            url: `/addresses/${data.id}`,
            data: { ...data, status: 'Inactive' },
        });

        if (status === 200) {
            dispatch(addFeedback('Address successfully removed', 'success'));
            setRefresh(true);
            dispatch(closeDialog());
        } else {
            dispatch(addFeedback('There was an error removing the address', 'error'));
        }
    };

    const menuOptions = [
        { title: 'Edit Address', key: 'editAddressButton', onClick: handleEditAddress },
        { title: 'Remove Address', key: 'removeAddressButton', onClick: handleRemoveAddress },
    ];
    
    return (
        <>
            <Divider />
            <ListItem disableGutters>
                <ListItemText>
                    <b>Type: {data.addressTypeName}</b>
                    <br />
                    {data.street1 && (
                        <>
                            {data.street1}
                            <br />
                        </>
                    )}
                    {data.street2 && (
                        <>
                            {data.street2}
                            <br />
                        </>
                    )}
                    {data.city} {data.state} {data.postcode} {data.country}
                </ListItemText>
                <ListItemSecondaryAction>
                    <MenuButton menuOptions={menuOptions} />
                </ListItemSecondaryAction>
            </ListItem>
        </>
    )
};
