import React, { useMemo, useCallback } from 'react';
import { IconButton, Menu, MenuItem } from '@material-ui/core';
import { Link } from 'react-router-dom';
import MenuIcon from '@material-ui/icons/Menu';

export interface MenuButtonProps {
    menuId: string;
    data: any;
    menuOptions: any;
    className?: any;
}

const MenuButton: React.FC<MenuButtonProps> = ({ menuId, data, menuOptions, className }) => {
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = useMemo(() => Boolean(anchorEl), [anchorEl]);
    const style = useMemo(
        () => ({
            style: {
                width: '20ch',
            },
        }),
        [],
    );

    const handleClick = useCallback((event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    }, []);

    const handleClose = useCallback(() => {
        setAnchorEl(null);
    }, []);

    const handleMenuItem = useCallback(
        (event: React.MouseEvent<HTMLElement>) => {
            handleClose();
        },
        [handleClose],
    );

    const options = useMemo(
        () =>
            menuOptions.map(
                ({
                    action,
                    title,
                    path,
                    idField,
                    onClick,
                    key = '',
                }: {
                    action: string;
                    title: string;
                    path: string;
                    idField: string;
                    onClick?: any;
                    key?: string;
                }) => {
                    let config: any = {
                        onClick: () => {
                            if (onClick) onClick();
                            handleClose();
                        },
                    };

                    if (path) {
                            let toPath = `${path}/${action}`;

                            if (idField) {
                                if (Array.isArray(idField)) {
                                    toPath = toPath + idField.map(i => '/' + data[i]).join('')
                                } else {
                                    toPath = `${toPath}/${data[idField]}`
                                }
                                
                            }

                        config = {
                            to: toPath,
                            component: Link,
                            id: action,
                            onClick: handleMenuItem,
                        };
                    }

                    return (
                        <MenuItem key={`${action}_${key}`} {...config}>
                            {title}
                        </MenuItem>
                    );
                },
            ),
        [data, handleClose, handleMenuItem, menuOptions],
    );

    if (!menuOptions) {
        return null;
    }

    return (
        <>
            <IconButton className={className} aria-label="more" aria-controls={menuId} aria-haspopup="true" onClick={handleClick}>
                <MenuIcon />
            </IconButton>
            <Menu id={menuId} anchorEl={anchorEl} keepMounted open={open} onClose={handleClose} PaperProps={style}>
                {options}
            </Menu>
        </>
    );
};

export default MenuButton;
