import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import SelectWithDataReactHook from 'components/SelectWithDataReactHook';
import ReactHookCheckbox from 'components/ReactHookCheckbox';

const Motor = ({ control, tableView = false }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Motor</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.motorQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '9%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.motorMake'
                type='text'
                control={control}
                label='Make'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.motorDesign'
                type='text'
                control={control}
                label='Design'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.motorKW'
                type='text'
                control={control}
                label='kW'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.motorPole'
                type='text'
                control={control}
                label='Pole'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.motorSpeed'
                type='text'
                control={control}
                label='Speed'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.motorPowerSupply'
                type='text'
                control={control}
                label='Power Supply'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.motorProtection'
                type='text'
                control={control}
                label='Protection'
                style={{width: '14%', marginRight: '1%'}}
            />   
            <SelectWithDataReactHook
                name='attributes.motorInsulation'
                type='text'
                control={control}
                label='Insulation'
                options={[
                    {
                        label: 'Class F',
                        id: 'Class F'
                    },
                    {
                        label: 'Class H',
                        id: 'Class H'
                    },
                    
                ]}
                style={{width: '14%', marginRight: '1%'}}
            />    
            <ReactHookTextField
                name='attributes.motorFrame'
                type='text'
                control={control}
                label='Frame'
                style={{width: '14%', marginRight: '1%'}}
            />
            <SelectWithDataReactHook
                name='attributes.motorMount'
                type='text'
                control={control}
                label='Mounting'
                options={[
                    {
                        label: 'Foot',
                        id: 'Foot'
                    },
                    {
                        label: 'Flange',
                        id: 'Flange'
                    },
                    {
                        label: 'Foot/Flange',
                        id: 'Foot/Flange'
                    }
                ]}
                style={{width: '14%', marginRight: '1%'}}
            />  
            <ReactHookTextField
                name='attributes.motorStart'
                type='text'
                control={control}
                label='Motor Start'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.motorTerminalBox'
                type='text'
                control={control}
                label='Terminal Box'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.motorDatasheet'
                label="Datasheet"     
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.motorTestReport'
                label="Test"     
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.motorCertification'
                label="Certification"     
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.motorOther'
                type='textarea'
                control={control}
                label='Other'
                style={{width: '24%', marginRight: '1%'}}
                InputProps={{
                    multiline:true
                }}
            />   
        </>
    );
};

export default Motor;
