import React from 'react';
import { Button as MuiButton } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CircularProgress from '@material-ui/core/CircularProgress';

export interface ButtonProps {
    type?: 'submit' | 'button' | 'reset' | undefined;
    disabled?: boolean;
    color?: 'primary' | 'inherit' | 'secondary' | 'default' | undefined;
    variant?: 'contained' | 'text' | 'outlined' | undefined;
    size?: 'small' | 'medium' | 'large' | undefined;
    className?: any;
    children?: any;
    onClick?: any;
}

const useStyles = makeStyles((theme) => ({
    spinner: {
        marginLeft: '20px',
    },
}));

const Button: React.FC<ButtonProps> = ({
    children,
    type = 'submit',
    disabled = false,
    color = 'primary',
    variant = 'contained',
    onClick,
    ...rest
}) => {
    const classes = useStyles();

    return (
        <MuiButton {...rest} type={type} disabled={disabled} color={color} variant={variant} onClick={onClick}>
            {children}
            {disabled && <CircularProgress color="inherit" size={20} className={classes.spinner} />}
        </MuiButton>
    );
};

export default Button;
