import { Document, Page, Text, View, StyleSheet, Font, Image } from '@react-pdf/renderer';
import { displayedDueDate, salesRepInitials } from '../../Quotes/components/QuoteFields';
import fanSizePlusMinus from 'helpers/fanSizePlusMinus';
import PDFHeader from './PDFHeader';

Font.register({
    family: "Roboto",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-regular-webfont.ttf"
});

Font.register({
    family: "Roboto Bold",
    src:
        "https://cdnjs.cloudflare.com/ajax/libs/ink/3.1.10/fonts/Roboto/roboto-bold-webfont.ttf"
});

const appendFormatter = (str) => {

    const appender = (name, value) => {
        return `${name != '' ? name + ' ' : ''}${value} ${str}`
    }

    return appender
}

const positiveValueFormatter = (formatter) => {

    return (name, value) => {
        if (value > 0) return formatter(name, value);
        return ''
    }
}

const reverseFormatter = (name, value) => {
    if (!value) return ''
    return `${value} ${name}`
}

const boolFormatter = (name, value) => {
    return `${name} ${value ? 'yes' : 'no'}`
}

const boolYesFormatter = (name, value) => {
    if (!value) return ''
    return `${name} yes`
}

export const styles = StyleSheet.create({
    page: {
        position: 'relative',
        fontSize: 10,
        width: '100%',
        marginTop: 15
    },
    w5: { width: '5%' },
    w10: { width: '10%' },
    w15: { width: '15%' },
    w20: { width: '20%' },
    w25: { width: '25%' },
    w30: { width: '30%' },
    w45: { width: '45%' },
    w50: { width: '50%' },
    w55: { width: '55%' },
    w65: { width: '65%' },
    w70: { width: '70%' },
    w80: { width: '80%' },
    w85: { width: '85%' },
    w100: { width: '100%' },
    title: {
        fontSize: 20,
        marginTop: 20,
        fontFamily: 'Roboto Bold'
    },
    mR: {
        marginRight: 2
    },
    mL: {
        marginLeft: 2
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    table: {
        marginLeft: '4%',
        marginRight: '4%',
        width: '92%',
        borderTop: '1px solid grey',
        borderLeft: '1px solid grey',
        borderRight: '1px solid grey',
        borderBottom: '1px solid grey',
    },
    big: {
        fontSize: 16,
        fontFamily: 'Roboto Bold',
    },
    boldText: {
        fontSize: 12,
        fontFamily: 'Roboto Bold',
    },
    text: {
        fontSize: 12,
        fontFamily: 'Roboto',
    },
    tC: {
        textAlign: 'center'
    },
    bR: {
        borderRight: '1px solid grey',
    },
    cellSmall: {
        width: 25,
        borderRight: '1px solid grey'
    },
    cellDesc: {
        width: 270,
        borderRight: '1px solid grey',
        margin: '0 4 0 2'
    },
    cellSup: {
        width: 100, 
        borderRight: '1px solid grey'
    },
    cellItp: {
        width: 100, 
        borderRight: '1px solid grey'
    },
    cellReport: {
        width: 50
    },

});

const PaintPDF = ({ quoteData, jobData }) => {
    const { attributes } = jobData;

    const client = quoteData.clientDetails;
    const contact = client.contactDetails;
    let contactText = contact.name
    contactText +=  contact.phone ? ` ${contact.phone}` : ""
    contactText +=  contact.phoneAlt ? ` (${contact.phoneAlt})` : ""

    const Aerotech = quoteData.productAreaID === 7 || quoteData.productAreaID === 8;

    return (
        <Document>
            <Page size="A4" style={styles.page}>
                <PDFHeader Aerotech={Aerotech} id={jobData.id} isPaint={true} />
                <View style={[styles.flex, styles.table]}>
                    <Text style={[styles.big, styles.mL]}>{attributes.fanQty} x {Aerotech ? '' : 'D'}{attributes.fanDesign} {Aerotech ? '' : 'S'}{attributes.fanSize}{fanSizePlusMinus(attributes.fanSizePlusMinus)}/{attributes.fanPercentageWidth} Arr {attributes.fanArrangement} {attributes.fanHanding} {attributes.fanOrt}{attributes.fanPos ? `POS ${attributes.fanPos}` : ''} {attributes.fanOther}</Text>
                </View>

                <View style={[styles.flex, styles.table]}>
                    <View style={[styles.cellSmall]}>
                        <Text style={[styles.boldText, styles.tC]}>Qty</Text>
                    </View>
                    <View style={[styles.cellDesc]}>
                        <Text style={[styles.boldText, styles.tC]}>Description</Text>
                    </View>
                    <View style={[styles.cellSup]}>
                        <Text style={[styles.boldText, styles.tC]}>Supplier</Text>
                    </View>
                    <View style={[styles.cellItp]}>
                        <Text style={[styles.boldText, styles.tC]}>Drawing</Text>
                    </View>
                    <View style={[styles.cellReport]}>
                        <Text style={[styles.boldText, styles.tC]}>Report</Text>
                    </View>
                </View>

                <View style={[styles.flex, styles.table]}>
                    <View style={[styles.cellSmall]}>
                        <Text style={[styles.text, styles.tC]}>{attributes.internalCoatingQty}</Text>
                    </View>
                    <View style={[styles.cellDesc]}>
                        <Text style={[styles.boldText]}>Internal Finish</Text>
                        {attributes.internalCoatingType && <Text style={[styles.text]}>Type: {attributes.internalCoatingType}</Text>}
                    </View>
                    <View style={[styles.cellSup]}>
                        <Text style={[styles.text, styles.tC]}>{attributes.internalCoatingSupplier}</Text>
                    </View>
                    <View style={[styles.cellItp]}>
                        <Text style={[styles.text]}>{attributes.internalCoatingDrawing}</Text>
                    </View>
                    <View style={[styles.cellReport]}>
                        <Text style={[styles.text]}>{attributes.internalCoatingReport}</Text>
                    </View>
                </View>

                <View style={[styles.flex, styles.table]}>
                    <View style={[styles.cellSmall]}>
                        <Text style={[styles.text, styles.tC]}>{attributes.externalCoatingQty}</Text>
                    </View>
                    <View style={[styles.cellDesc]}>
                        <Text style={[styles.boldText]}>External Finish</Text>
                        {attributes.externalCoatingType && <Text style={[styles.text]}>Type: {attributes.externalCoatingType}</Text>}
                        {attributes.externalCoatingColour && <Text style={[styles.text]}>Colour: {attributes.externalCoatingColour}</Text>}
                    </View>
                    <View style={[styles.cellSup]}>
                        <Text style={[styles.text, styles.tC]}>{attributes.externalCoatingSupplier}</Text>
                    </View>
                    <View style={[styles.cellItp]}>
                        <Text style={[styles.text]}>{attributes.externalCoatingDrawing}</Text>
                    </View>
                    <View style={[styles.cellReport]}>
                        <Text style={[styles.text]}>{attributes.externalCoatingReport}</Text>
                    </View>
                </View>
                
                <View style={[styles.flex, styles.table, { borderBottom: '1px solid grey' }]}>
                    <View style={[styles.w100]}>
                        <Text style={[styles.text, styles.mL]}>Collection: dispatch@aireng.com.au or production@aireng.com.au</Text>
                    </View>
                </View>
            </Page>
        </Document>
    );
};

export default PaintPDF;
