import QuicksightLink from 'components/QuicksightLink';
import { Grid } from '@material-ui/core';
import React from 'react';
const Reports = () => {
    return (
        <Grid container alignItems="center" justifyContent='center' style={{ minHeight: '30vh' }}>
            <Grid item xs={12} style={{ textAlign: 'center' }} >
                <QuicksightLink />
            </Grid>
        </Grid>
    );
};

export default Reports;
