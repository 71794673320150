import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import MenuButton from 'components/MenuButton';
import { ActionType } from 'types/browse';

const useStyles = makeStyles((theme) => ({
    menuButton: {
        padding: '2px'
    }
}));

interface ActionsProps {
    id: number;
    actions?: ActionType[];
    item: any;
    linkPath?: string | undefined;
}

const Actions: React.FC<ActionsProps> = ({ actions, item, id }) => {
    const classes = useStyles();

    return <MenuButton className={classes.menuButton} key={`dataTableMenu_${item.id}`} menuId={id.toString()} menuOptions={actions} data={item} />;
};

export default Actions;
