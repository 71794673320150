import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';
import { Button, IconButton, Grid } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ContentWrapper from 'components/ContentWrapper';
import makePost from 'helpers/makePost';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import { useForm } from "react-hook-form";
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import ReactHookTextField from '../../components/ReactHookTextField';
import { CardHeader } from '@material-ui/core';
import { openDialog } from 'actions/dialog';
import { List } from '@material-ui/core';
import { AddressEdit, AddressAdd } from './Address';
import { ContactEdit, ContactAdd } from './Contact';

export const formValidation = Yup.object().shape({
    name: Yup.string().required('Company name required'),
});

const useStyles = makeStyles({
    header: {
        padding: '0',
    },
});

const Form = ({ id, clientData, setRefresh, action }) => {
    
    const classes = useStyles();
    const dispatch = useDispatch();
    const history = useHistory();

    const { handleSubmit, control, setError } = useForm({
        resolver: yupResolver(formValidation),
        defaultValues: {
            name: clientData.name,
            abn: clientData.abn
        } 
    });

    const onSubmit = async data => {
        try {
            const response = await makePost({
                url: action === 'add' ? `/clients` : `/clients/${id}`,
                data: { 
                    ...data
                },
            });

            dispatch(addFeedback('Client details have been saved', 'success'));
            
            if (action === 'add') {
                history.push(`/clients/edit/${response.data.id}`)
            }
        } catch (error) {
            const errData = error.response.data;

            Object.keys(errData.errors).map((key) => {
                setError(key.slice(2), errData.errors[key][0]);
                return null;
            });

            dispatch(addFeedback(errData.title || errData, 'error', errData.errors));
        }
        
        setRefresh(true);
    };
    
    const { addresses, contacts } = clientData;
    
    const handleAddAddress = () => {
        dispatch(openDialog(`Add Address`, <AddressAdd id={id} setRefresh={setRefresh} />, [], {maxWidth: 'md', fullWidth: true}));
    };

    const handleAddContact = () => {
        dispatch(openDialog(`Add Contact`, <ContactAdd addresses={addresses} id={id} setRefresh={setRefresh} />, [], {maxWidth: 'md', fullWidth: true}));
    };

    return (
        <form onSubmit={handleSubmit(onSubmit)}>
            <ContentWrapper>
                <ReactHookTextField
                    name='name'
                    type='text'
                    control={control}
                    label='Company Name'
                    style={{width: '59%', marginRight: '1%'}}
                />
                <ReactHookTextField
                    name='abn'
                    type='text'
                    control={control}
                    label='ABN'
                    style={{width: '27%', marginRight: '1%'}}
                />
                <Button
                    variant='contained'
                    color='secondary'
                    type='submit'
                    style={{width: '12%'}}
                >
                    {action !== 'add' ? 'Update Client' : 'Add Client'}
                </Button>

                {action !== 'add' &&
                    <Grid container spacing={3}>
                        <Grid item xs={12} md={6}>
                            <ContentWrapper style={{paddingTop: '6px', paddingBottom: '6px'}}>
                                <CardHeader
                                    title={"Addresses"}
                                    className={classes.header}
                                    action={
                                        <IconButton onClick={handleAddAddress}>
                                            <AddIcon />
                                        </IconButton>
                                    }
                                />  
                                {addresses.length > 0 && 
                                    <List>  
                                        {addresses.map((address) => <AddressEdit data={address} setRefresh={setRefresh} />)}
                                    </List>
                                }
                            </ContentWrapper>
                        </Grid>
                        <Grid item xs={12} md={6}>
                            <ContentWrapper style={{paddingTop: '6px', paddingBottom: '6px'}}>
                                <CardHeader
                                    title={"Contacts"}
                                    className={classes.header}
                                    action={
                                        <IconButton onClick={handleAddContact}>
                                            <AddIcon />
                                        </IconButton>
                                    }
                                />  
                                {contacts.length > 0 && 
                                    <List>  
                                        {contacts.map((contact) => <ContactEdit addresses={addresses} data={contact} setRefresh={setRefresh} />)}
                                    </List>
                                }
                            </ContentWrapper>
                        </Grid>
                    </Grid>
                }
            </ContentWrapper>
        </form>
    );
};

export default Form;
