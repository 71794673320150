import React, { useContext } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import { TableHead, TableRow, TableCell, FormControl, OutlinedInput, InputAdornment, IconButton } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ClearIcon from '@material-ui/icons/Clear';

import BrowseContext from 'contexts/browseContext';
import { TableItem } from 'types/browse';
import { debounce } from 'mini-debounce'

const useStyles = makeStyles((theme) => ({
    th: {
        padding: '0 8px 0 0',
        [theme.breakpoints.up('md')]: {
            paddingTop: 0,
        },
        fontWeight: 'bold'
    },
    titleWrapper: {
        display: 'flex',
        flexDirection: 'row',
        pointerEvents: 'none',
    },
    title: {
        flex: '1',
    },
    filterRow: {
        '& th': {
            backgroundColor: '#e8e8e8',
            padding: '0 10px',
        },
    },
    arrow: {
        pointerEvents: 'none',
    },
    svg: {
        transition: theme.transitions.create(['transform'], {
            duration: theme.transitions.duration.short,
        }),
    },
    desc: {
        transform: 'rotate(-180deg)',
    },
    asc: {
        transform: 'rotate(0)',
    },
    margin: {
        margin: theme.spacing(1),
        marginLeft: 0,
    },
    textField: {
        width: '100%',
        '& .MuiInputBase-formControl': {
            backgroundColor: 'white',
        },
    },
    sticky: {
        position: "sticky",
        left: 0,
        background: "white"
    }
}));

const THead = () => {
    const classes = useStyles();
    const { tableStructure, filterValues, sortValue, handleFilterChange, clearFilter } = useContext(BrowseContext);

    const hasFilterRow = tableStructure.some(({ showFilter }: { showFilter: boolean }) => showFilter);

    const handleSortToggle = () => {
        // Incomplete
    };

    const debounceHandleFilterChange = debounce(( target: { name: any; value: any }) => handleFilterChange(target), 500);

    const handleChange = (e: { stopPropagation: () => void; target: { name: any; value: any } }) => {
        debounceHandleFilterChange(e.target);
    }

    if (!tableStructure || (hasFilterRow && !filterValues)) {
        return null;
    }

    return (
        <TableHead>
            <TableRow>
                {tableStructure.map(({ title, fieldName, showSort = false, width, align = 'left', sticky }: TableItem, index: number) => (
                    <TableCell
                        component="th"
                        className={clsx(classes.th, { pointer: showSort ? 'pointer' : 'none' }, { width }, sticky && classes.sticky)}
                        key={`${fieldName}_sort`}
                        onClick={handleSortToggle}
                        data-index={index}
                    >
                        <div className={classes.titleWrapper}>
                            <div className={clsx(classes.title, { textAlign: align })}>{title}</div>
                            {showSort && sortValue.fieldName === fieldName && (
                                <div className={clsx(classes.arrow, sortValue?.sortOrder ? classes.desc : classes.asc)}>
                                    <ArrowDropDownIcon
                                        className={clsx(classes.svg, sortValue.sortOrder === 'desc' ? classes.desc : classes.asc)}
                                    />
                                </div>
                            )}
                        </div>
                    </TableCell>
                ))}
            </TableRow>
            {hasFilterRow && (
                <TableRow className={classes.filterRow}>
                    {tableStructure.map(({ title, fieldName, showFilter }: TableItem, index: number) => (
                        <TableCell component="th" key={`${fieldName}_filter`}>
                            {showFilter && (
                                <FormControl variant="outlined" size="small" className={clsx(classes.margin, classes.textField)}>
                                    <OutlinedInput
                                        inputProps={{
                                            style: {
                                                padding: '5px 10px'
                                            }
                                        }}
                                        type="text"
                                        name={fieldName}
                                        placeholder={'Search'}
                                        notched={false}
                                        onChange={handleChange}
                                        data-index={index}
                                        endAdornment={
                                            fieldName &&
                                            filterValues?.[fieldName] && (
                                                <InputAdornment position="end">
                                                    <IconButton
                                                        aria-label="toggle password visibility"
                                                        onClick={() => clearFilter(fieldName)}
                                                        // onMouseDown={handleMouseDownPassword}
                                                        edge="end"
                                                    >
                                                        {/* {values.showPassword ? <Visibility /> : <VisibilityOff />} */}
                                                        <ClearIcon />
                                                    </IconButton>
                                                </InputAdornment>
                                            )
                                        }
                                        labelWidth={70}
                                    />
                                </FormControl>
                            )}
                        </TableCell>
                    ))}
                </TableRow>
            )}
        </TableHead>
    );
};

export default THead;
