import { makeStyles, withStyles } from '@material-ui/core';
import InputBase from '@material-ui/core/InputBase';

export const useStyles = makeStyles((theme) => ({
    container: {
        marginBottom: theme.spacing(4),
    },
    input: {
        marginBottom: theme.spacing(2),
    },
    disabledInput: {
        marginBottom: theme.spacing(2),
        '& .MuiFormLabel-root': {
            left: '-12px'
        },
        '& .MuiInputBase-input': {
            paddingLeft: '2px',
            paddingRight: '2px',
            color: theme.palette.primary.main,
        },
        '& .MuiOutlinedInput-notchedOutline': {
            border: 'none'
        }
    },
    selectLabel: {
        backgroundColor: 'white',
        paddingLeft: 2,
        paddingRight: 2
    },
    listItem: {
        whiteSpace: "normal",
    },
    listItemExtra: {
        color: theme.palette.grey[700],
        lineHeight: theme.spacing(3)+'px',
        marginBottom: theme.spacing(2),
    },
    select: {
        background: "#ffffff",
    },
    selectAddIcon: {
        top: '-8px',
        right: '8px',
        fill: theme.palette.primary.main,
    },
    backdrop: {
        zIndex: 1000,
    },
    header: {
        marginTop: theme.spacing(2),
    },
    groupLabel: {
        padding: '6px 10px 0',
        fontWeight: 'bold',
        color: theme.palette.grey[700],

    },
    groupList: {
        paddingLeft: '6px'
    }
}), { index: 1 });

export const SelectAddInput = withStyles((theme) => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
    },
    input: {
        width: '0',
        minWidth: '0',
        padding: '0px 12px',
        '&:focus': {
            background: 'none',
        },
        '&:hover + svg': {
            fill: theme.palette.success.main,
        },
        
    },
  }), { index: 1 })(InputBase);

export default useStyles;