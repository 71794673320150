import React from 'react';
import { Field, getIn, useFormikContext } from 'formik';
import { TextField } from '@material-ui/core';

export interface InputProps {
    name: string;
    title?: string;
    label?: string;
    type?: string;
    autoComplete?: 'true' | 'false';
    variant?: string;
    className?: any;
    InputProps?: any;
}

const Input: React.FC<InputProps> = ({ type = 'text', autoComplete = 'false', variant, InputProps, ...rest }) => {
    const { errors, touched } = useFormikContext();
    const fieldName = rest.name;
    const helper = getIn(touched, fieldName) && getIn(errors, fieldName);
    
    return (
        <Field
            {...rest}
            type={type}
            as={TextField}
            autoComplete={autoComplete}
            variant={variant}
            helperText={helper}
            InputProps={InputProps}
            size={'small'}
        />
    );
};

export default Input;
