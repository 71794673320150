import React, { useState, useCallback, useMemo } from 'react';
import { List, makeStyles } from '@material-ui/core';
import clsx from 'clsx';
import PlacesAutocomplete, { geocodeByAddress } from 'react-places-autocomplete';
import { Paper, Typography, Select, TextField, MenuItem, InputAdornment } from '@material-ui/core';
import SearchIcon from '@material-ui/icons/Search';
import countryList from 'country-list';

const useStyles = makeStyles((theme) => ({
    input: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '49%'
        },
        '& .MuiInputBase-formControl': {
            backgroundColor: 'white',
        },
    },
    suggestionList: {
        position: 'absolute',
        width: '100%',
        maxWidth: 400,
        backgroundColor: 'white',
        border: '1px solid #608280',
        borderRadius: '0 0 4px 4px',
        padding: theme.spacing(1),
        zIndex: 1000,
    },
    countryList: {
        marginBottom: theme.spacing(1),
        backgroundColor: 'white',
        width: '100%',
    },
    paper: {
        padding: theme.spacing(2),
        paddingBottom: 0,
        backgroundColor: theme.palette.background.default,
        marginBottom: theme.spacing(2),
        position: 'relative',
        [theme.breakpoints.up('md')]: {
            paddingBottom: theme.spacing(2)
        }
    },
    heading: {
        fontWeight: 'bold',
        marginBottom: theme.spacing(1),
    },
    container: {
        [theme.breakpoints.up('md')]: {
            display: 'flex'
        }
    },
    fieldContainer: {
        width: '100%',
        position: 'relative',        
        [theme.breakpoints.up('md')]: {
            width: '50%',
            marginRight: theme.spacing(2)
        }
    }
}));

const LocationAutocomplete = ({ lookupToFormMap, values, setValues }) => {
    const classes = useStyles();
    const [lookupAddress, setLookupAddress] = useState('');
    const [countryCode, setCountryCode] = useState('AU');

    const handleChange = useCallback((lookupAddress) => {
        setLookupAddress(lookupAddress);
    }, []);

    const handleSelect = useCallback(
        (lookupAddress) => {
            if (lookupAddress.length) {
                geocodeByAddress(lookupAddress)
                    .then((results) => {
                        const { address_components } = results[0] || {};


                        if (address_components) {
                            const newValues = {};
                            const { fieldNames, matches } = lookupToFormMap;
                            const fieldNameArrays = {};
                            fieldNames.map((name) => {
                                fieldNameArrays[name] = [];
                                return null;
                            });

                            address_components.map(({ types, ...rest }) => {
                                types.map((type) => {
                                    if (matches.hasOwnProperty(type)) {
                                        const { fieldName, position = 0, display = 'short_name' } = matches[type];
                                        fieldNameArrays[fieldName].splice(position, 0, rest[display]);
                                    }
                                    return null;
                                });
                                return null;
                            });

                            Object.keys(fieldNameArrays).map((fieldName) => {
                                newValues[fieldName] = fieldNameArrays[fieldName].join(' ');
                                return null;
                            });

                            setValues({ ...values, ...newValues });
                        }
                    })
                    .catch((error) => console.error('Error', error));
            }
        },
        [lookupToFormMap, setValues, values],
    );

    const countriesArray = useMemo(() => countryList.getData(), []);
    const handleCountryChange = (event) => {
        setCountryCode(event.target.value);
    };
    const searchOptions = {
        componentRestrictions: { country: [countryCode] },
    };

    return (
        <Paper elevation={2} className={classes.paper}>
            <Typography component="h3" className={classes.heading}>
                Address Lookup
            </Typography>
            <div className={classes.container}>
                <div className={classes.fieldContainer}>
                    <Select
                        name="countryList"
                        value={countryCode}
                        onChange={handleCountryChange}
                        className={classes.countryList}
                        variant="outlined"
                    >
                        {countriesArray.map(({ code, name }) => (
                            <MenuItem key={code} value={code}>
                                {name}
                            </MenuItem>
                        ))}
                    </Select>
                </div>
                
                <PlacesAutocomplete
                    value={lookupAddress}
                    onChange={handleChange}
                    onSelect={handleSelect}
                    debounce={400}
                    searchOptions={searchOptions}
                >
                    {(suggestionProps) => {
                        const { getInputProps, suggestions, getSuggestionItemProps, loading } = suggestionProps;

                        return (
                            <div className={classes.fieldContainer}>
                                <TextField
                                    {...getInputProps({
                                        placeholder: 'Search Places ...',
                                        className: classes.input,
                                    })}
                                    variant="outlined"
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <SearchIcon />
                                            </InputAdornment>
                                        ),
                                    }}
                                    style={{ width: '100%'}}
                                />
                                <List className={clsx(loading || suggestions.length ? classes.suggestionList : null)}>
                                    {loading && <div>Loading...</div>}
                                    {suggestions.map((suggestion) => {
                                        const className = suggestion.active ? 'suggestion-item--active' : 'suggestion-item';
                                        // inline style for demonstration purpose
                                        const style = suggestion.active
                                            ? { backgroundColor: '#fafafa', cursor: 'pointer' }
                                            : { backgroundColor: '#ffffff', cursor: 'pointer' };
                                        return (
                                            <div
                                                {...getSuggestionItemProps(suggestion, {
                                                    className,
                                                    style,
                                                })}
                                                key={`suggestion_${suggestion.index}`}
                                            >
                                                <span>{suggestion.description}</span>
                                            </div>
                                        );
                                    })}
                                </List>
                            </div>
                        );
                    }}
                </PlacesAutocomplete>
            </div>
        </Paper>
    );
};

export default LocationAutocomplete;
