import { useState, useEffect } from 'react';
import { Backdrop, CircularProgress } from '@material-ui/core';
import { useStyles } from '../../styles';
import { axiosInstance } from 'App';
import Form from './Form';

const SpareParts = ({ quoteData, itemList, revList, clientList, quoteStatusList, salesRepList, productCategoryList, productAreaList, enquirySourceList, industryList, applicationList }) => {
    const classes = useStyles();
    const [quoteDetails, setQuoteDetails] = useState();
    
    useEffect(() => {
        const fetchQuoteDetails = async () => {
            const result = await axiosInstance({ url: `/quotedetails/${quoteData.quoteID}/${quoteData.itemID}/${quoteData.revision}/spareparts` });
            setQuoteDetails(result.data);
        };

        fetchQuoteDetails();
    }, []);

    
    return (quoteDetails ? 
        <Form 
            quoteData={quoteData} 
            quoteDetails={quoteDetails} 
            itemList={itemList} 
            revList={revList} 
            clientList={clientList} 
            quoteStatusList={quoteStatusList} 
            salesRepList={salesRepList} 
            productCategoryList={productCategoryList} 
            productAreaList={productAreaList} 
            enquirySourceList={enquirySourceList} 
            industryList={industryList}
            applicationList={applicationList} /> : 
        <Backdrop open={true} className={classes.backdrop}>
            <CircularProgress color="primary" />
        </Backdrop>
    );
};

export default SpareParts;
