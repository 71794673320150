import React, { ReactNode } from 'react';
import DataContext from 'contexts/dataContext';

interface Props {
    children?: ReactNode;
    data?: any;
}

const DataProvider = ({ children, data }: Props) => {
    return <DataContext.Provider value={data}>{children}</DataContext.Provider>;
};

export default DataProvider;
