import { Field } from 'formik';
import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { axiosInstance } from 'App';
import { makeStyles, FormControl, InputLabel, Select, MenuItem } from '@material-ui/core';

const useStyles = makeStyles(() => ({
    overrideMuiInputLabelOutlined: {
        backgroundColor: 'white',
        paddingLeft: 2,
        paddingRight: 2
    }
}));

const WrappedSelect = ({ label, fieldName, apiPath, options, error, fieldValue, variant, className, style, multiple }) => {
    const classes = useStyles();
    const [res, setRes] = useState();
    const [isLoading, setIsLoading] = useState(false);
    
    const fetchData = async () => {
        setIsLoading(true);
   
        const result = await axiosInstance({ url: apiPath });
   
        setRes(result.data);
        setIsLoading(false);
      };

    useEffect(() => {
        if (apiPath) {
            fetchData();            
        }
    }, [apiPath]);


    const renderOptions = () => {
        if (apiPath) {
            if (isLoading || !res) {
                return <option key={`optionLoading_${fieldName}`}>Fetching data...</option>;
            }

            return res.map(({ id, data }) => (
                <MenuItem key={`option_${id}`} value={id}>
                    {data}
                </MenuItem>
            ));
        }

        return options.map(({ value, title }) => (
            <MenuItem key={`option_${value}`} value={value}>
                {title}
            </MenuItem>
        ));
    };
    
    return (
        <FormControl className={className} variant={variant} error={error} style={style}>
            {label && <InputLabel margin="dense" className={classes.overrideMuiInputLabelOutlined}>{label}</InputLabel>}
            <Field as={Select} name={fieldName} value={fieldValue} margin="dense" multiple={multiple}>
                {renderOptions()}
            </Field>
        </FormControl>
    );
};

WrappedSelect.propTypes = {
    fieldName: PropTypes.string.isRequired,
    apiPath: PropTypes.string,
    fieldValue: PropTypes.string,
    placeholder: PropTypes.string,
    options: PropTypes.array,
    hasError: PropTypes.bool,
};

WrappedSelect.defaultProps = {
    apiPath: undefined,
    placeholder: '',
    options: [],
    hasError: false,
};

export default WrappedSelect;
