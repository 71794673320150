import React, { useState } from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Formik } from 'formik';
import { Box, makeStyles } from '@material-ui/core';

import Loader from '../Loader';
import makePost from 'helpers/makePost';
import FormRow from './components/FormRow';

interface LooseObject {
    [key: string]: any;
}

interface FormStructureProps {
    inputType: string;
    fieldName: string;
    defaultValue: string;
}

interface SubmitOptionProps {
    apiPath: string;
    transform: Function;
    method: string;
    callback: Function;
    id?: number | undefined;
    mutate?: any;
}

interface FormProps extends RouteComponentProps<any> {
    lookupComponent?: any;
    lookupToFormMap?: any;
    data?: any;
    formStructure?: any[];
    fetching?: boolean;
    formValidation?: any;
    submitOptions: SubmitOptionProps;
    action?: string | undefined;
    mutate?: any;
    inputProps?: any;
}

const useStyles = makeStyles((theme) => ({
    table: {
        width: '100%',
        [theme.breakpoints.up('md')]: {
            minWidth: '400px',
        },
    },
}));

const Form: React.FC<FormProps> = ({
    lookupComponent,
    lookupToFormMap,
    data,
    formStructure = [],
    fetching = false,
    formValidation = {},
    submitOptions,
    match: {
        params: { action: matchParam },
    },
    action,
    mutate,
    inputProps = {},
}) => {
    const classes = useStyles();
    const { apiPath, transform, method, callback, id } = submitOptions;
    const formReady = action === 'add' || (Boolean(data) && !fetching);
    const [submitResponse, setSubmitResponse] = useState<any>();
    const [clearForm, setClearForm] = useState(false);
    const [isSubmittng, setIsSubmittng] = useState(false);
    const currentAction = action || matchParam;

    // Create empty object for form reset
    const empty = formStructure.reduce((acc, cur) => {
        let tempObject: LooseObject = {};
        cur.elements.map(({ inputType, fieldName, defaultValue }: FormStructureProps) => {
            if (!['submit', 'button'].includes(inputType) && (!cur.actionType || cur.actionType === currentAction)) {
                tempObject[fieldName] = defaultValue || '';
            }

            return undefined;
        });

        return { ...acc, ...tempObject };
    }, {});
    const initialVaues = currentAction === 'add' ? empty : data;
    const LookupComponent = lookupComponent || null;

    return (
        <Box pb={3}>
            {(fetching || !formReady) && <Loader />}
            {formReady && (
                <Formik
                    initialValues={initialVaues}
                    validationSchema={formValidation}
                    onSubmit={async (values) => {
                        setIsSubmittng(true);
                        try {
                            const response = await makePost({
                                url: apiPath,
                                method,
                                data: transform(values, id),
                            });
                            
                            setSubmitResponse(response);

                            if (mutate) {
                                mutate();
                            } else if (submitOptions?.mutate) {
                                submitOptions.mutate();
                            }
                        } catch (error:any) {
                            setSubmitResponse(error.response.data);
                        }
                    }}
                >
                    {(formProps) => {
                        const { handleSubmit, isSubmitting, setSubmitting, resetForm, setFieldError } = formProps;

                        if (submitResponse && isSubmitting) {
                            setSubmitting(false);
                            setIsSubmittng(false);

                            if (submitResponse?.errors) {
                                const keys = Object.keys(submitResponse?.errors);
                                keys.map((key) => {
                                    setFieldError(key.toLowerCase(), submitResponse.errors[key][0]);
                                    return null;
                                });
                            } else if (currentAction === 'add') {
                                resetForm();
                            }

                            callback(submitResponse);
                            setSubmitResponse(undefined);
                        }

                        if (clearForm) {
                            resetForm();
                            setClearForm(false);
                        }

                        return (
                            <form onSubmit={handleSubmit}>
                                <table className={classes.table}>
                                    <tbody>
                                        {lookupComponent && (
                                            <tr>
                                                <td>
                                                    <LookupComponent {...formProps} lookupToFormMap={lookupToFormMap} />
                                                </td>
                                            </tr>
                                        )}
                                        {formStructure &&
                                            formStructure.map(({ key, rowTitle, elements, actionType }) => {
                                                // Validate display is allowed
                                                if (actionType && actionType !== currentAction) {
                                                    return null;
                                                }

                                                return (
                                                    <FormRow
                                                        key={`form_${key}`}
                                                        rowTitle={rowTitle}
                                                        elements={elements}
                                                        isSubmitting={isSubmitting}
                                                        isFetching={isSubmittng}
                                                        inputProps={inputProps}
                                                    />
                                                );
                                            })}
                                    </tbody>
                                </table>
                            </form>
                        );
                    }}
                </Formik>
            )}
        </Box>
    );
};

export default withRouter(Form);
