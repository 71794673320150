import { useEffect, useState } from 'react';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import makePost from 'helpers/makePost';
import { useParams, useHistory } from 'react-router-dom';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogTitle from '@material-ui/core/DialogTitle';


const JobsClone = () => {

    const { jobID } = useParams();
    let history = useHistory();
    const dispatch = useDispatch();

    const [open, setOpen] = useState(true);

    const fetchQuoteDetails = async () => {

        const response = await makePost({
            url: `/quotes/clone`,
            data: {
                jobID: jobID,
            },
        });

        if (response.data.quoteID) {
            const quoteID = response.data.quoteID;
            const revision = response.data.revision;
            const itemID = response.data.itemID;
            history.push(`/quotes/edit/${quoteID}/${revision}/${itemID}?clone`);
        } else {
            dispatch(addFeedback('Error cloning job, please go back and try again.', 'error'));
        }
    };

    const handleYes = async () => {
        setOpen(false);
        await fetchQuoteDetails();
    };

    const handleNo = () => {
        setOpen(false);
        history.goBack()
    };

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleNo}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">{"Please confirm if you want to duplicate the job"}</DialogTitle>
                <DialogActions>
                    <Button onClick={handleNo} color="primary">
                        No
                    </Button>
                    <Button onClick={handleYes} color="secondary" variant='contained' autofocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
};

export default JobsClone;