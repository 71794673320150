import { TextField, Typography } from '@material-ui/core';
import { useStyles } from './Form';
import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';

const Impeller = ({ control, watch, tableView }) => {
    const classes = useStyles();

    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Impeller</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.impellerQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '9%', marginRight: '1%'}}
            />
            <TextField
                label='Impeller Class'
                type='text'
                disabled={true}
                className={classes.disabledInput}
                variant='outlined' 
                InputLabelProps={{ shrink: true }} 
                size='small'
                value={watch('attributes.impellerClass')}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.impellerBladeMaterial'
                type='text'
                control={control}
                label='Blade Material'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.impellerShroudEyeMaterial'
                type='text'
                control={control}
                label='Shroud & Eye Material'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.impellerBackplateMaterial'
                type='text'
                control={control}
                label='Backplate Material'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.impellerSpecial'
                type='text'
                control={control}
                label='Special'
                style={{width: '14%', marginRight: '1%'}}
            />  
            <ReactHookTextField
                name='attributes.impellerType'
                type='text'
                control={control}
                label='Type'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.impellerBladeThickness'
                type='text'
                control={control}
                label='Blade Thickness'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.impellerShroudThickness'
                type='text'
                control={control}
                label='Shroud Thickness'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.impellerEyeThickness'
                type='text'
                control={control}
                label='Eye Thickness'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.impellerBackplateThickness'
                type='text'
                control={control}
                label='Backplate Thickness'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.impellerSpeedRing'
                label="Speed Ring"     
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.impellerBackFins'
                label="Anti Thrust Vanes"     
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.impellerNotes'
                type='textarea'
                control={control}
                label='Notes'
                style={{width: '24%', marginRight: '1%'}}
                InputProps={{
                    multiline:true
                }}
            />
        </>   
    );
};

export default Impeller;
