import ReactHookTextField from 'components/ReactHookTextField';
import SelectWithDataReactHook from 'components/SelectWithDataReactHook';

const NDT = ({ control }) => {
    return (
        <div style={{width: '100%'}}>
            <SelectWithDataReactHook
                name='attributes.ndtImp'
                type='text'
                control={control}
                label="Imp"
                options={[
                    {
                        label: 'Visual',
                        id: 'Visual'
                    },
                    {
                        label: 'Dye Penetration',
                        id: 'Dye Penetration'
                    },
                    {
                        label: 'Magnetic Particle',
                        id: 'Magnetic Particle'
                    },
                    {
                        label: 'Radiography',
                        id: 'Radiography'
                    }
                ]}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.ndtImpPercent'
                type='text'
                control={control}
                label='Imp %'
                style={{width: '19%', marginRight: '1%'}}
            />
            <SelectWithDataReactHook
                name='attributes.ndtCase'
                type='text'
                control={control}
                label="Case"
                options={[
                    {
                        label: 'Visual',
                        id: 'Visual'
                    },
                    {
                        label: 'Dye Penetration',
                        id: 'Dye Penetration'
                    },
                    {
                        label: 'Magnetic Particle',
                        id: 'Magnetic Particle'
                    },
                    {
                        label: 'Radiography',
                        id: 'Radiography'
                    }
                ]}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.ndtCasePercent'
                type='text'
                control={control}
                label='Case %'
                style={{width: '19%', marginRight: '1%'}}
            />
            <SelectWithDataReactHook
                name='attributes.ndtPed'
                type='text'
                control={control}
                label="Ped"
                options={[
                    {
                        label: 'Visual',
                        id: 'Visual'
                    },
                    {
                        label: 'Dye Penetration',
                        id: 'Dye Penetration'
                    },
                    {
                        label: 'Magnetic Particle',
                        id: 'Magnetic Particle'
                    },
                    {
                        label: 'Radiography',
                        id: 'Radiography'
                    }
                ]}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.ndtPedPercent'
                type='text'
                control={control}
                label='Ped %'
                style={{width: '19%', marginRight: '1%'}}
            />
            <SelectWithDataReactHook
                name='attributes.ndtLugs'
                type='text'
                control={control}
                label="Lugs"
                options={[
                    {
                        label: 'Visual',
                        id: 'Visual'
                    },
                    {
                        label: 'Dye Penetration',
                        id: 'Dye Penetration'
                    },
                    {
                        label: 'Magnetic Particle',
                        id: 'Magnetic Particle'
                    },
                    {
                        label: 'Radiography',
                        id: 'Radiography'
                    }
                ]}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.ndtLugsPercent'
                type='text'
                control={control}
                label='Lugs %'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.ndtOther'
                type='text'
                control={control}
                label='Other'
                style={{width: '19%', marginRight: '1%'}}
            />
        </div>
    );
};

export default NDT;
