import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';

export const BearingsDE = ({control, tableView}) => {
    return <Bearings control={control} tableView={tableView} type='DE'/>
}

export const BearingsNDE = ({control, tableView}) => {
    return <Bearings control={control} tableView={tableView} type='NDE'/>
}

const Bearings = ({ control, tableView, type}) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Bearings ({type})</Typography> : <br/>}
            <ReactHookTextField
                name={`attributes.bearing${type}Qty`}
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name={`attributes.bearing${type}`}
                control={control}
                label='Type'
                style={{width: '19%', marginRight: '1%'}}
                type='textarea'
                InputProps={{
                    multiline:true
                }}
            />
            <ReactHookCheckbox 
                control={control}
                name={`attributes.bearing${type}Fixed`}
                label='Fixed'   
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name={`attributes.bearing${type}Seal`}
                type='text'
                control={control}
                label='Seal'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name={`attributes.bearing${type}Life`}
                type='text'
                control={control}
                label='Life'
                style={{width: '19%', marginRight: '1%'}}
            />     
            <ReactHookTextField
                name={`attributes.bearing${type}Notes`}
                type='text'
                control={control}
                label='Notes'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default Bearings;
