import React, { useContext } from 'react';
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { Button, Box } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

import BrowseContext from 'contexts/browseContext';

const useStyles = makeStyles((theme) => ({
    pagination: {
        marginTop: theme.spacing(2),
    },
}));

const Pagination: React.FC = () => {
    const { pageNum, apiData, pageSize, setPageNum } = useContext(BrowseContext);
    const classes = useStyles();

    const handlePrev = () => {
        if (pageNum > 1) {
            setPageNum(pageNum - 1);
        }
    };
    const handleNext = () => {
        setPageNum(pageNum + 1);
    };

    return (
        <Box display="flex" justifyContent="space-between" className={classes.pagination}>
            <Button color="primary" disabled={pageNum === 1} onClick={handlePrev}>
                <ArrowLeftIcon /> Prev
            </Button>
            <Button color="primary" disabled={apiData?.length < pageSize} onClick={handleNext}>
                Next <ArrowRightIcon />
            </Button>
        </Box>
    );
};

export default Pagination;
