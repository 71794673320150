const addWorkHours = (dateString, hours) => {
    const date = new Date(dateString);
    
    const numToAdd = Math.ceil(hours/7);

    for (let i = 1; i <= numToAdd; i++) {
      date.setDate(date.getDate() + 1);
      if (date.getDay() === 6) {
        date.setDate(date.getDate() + 2);
      }
      else if (date.getDay() === 0) {
        date.setDate(date.getDate() + 1);
      }
    }

    return date
};

export default addWorkHours;
