import { useState, useEffect } from 'react';
import File from './File';
import { axiosInstance } from 'App';

const FileWrapper = ({ url}) => {
    const [files, setFiles] = useState();


    const [refresh, setRefresh] = useState(true);

    useEffect(() => {
        const fetchFiles = async () => {
            const result = await axiosInstance({ url });
            
            setFiles(result.data);
        };

        if (refresh) {
            fetchFiles();
            setRefresh(false);
        }
    }, [refresh]);

    
    return (files ? <File url={url} files={files} setRefresh={setRefresh} /> : null);
};

export default FileWrapper;