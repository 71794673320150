import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const MotorPulley = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Motor Pulley</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.driveMotorPulleyQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '9%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.driveMotorPulleyType'
                type='text'
                control={control}
                label='Type'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.driveMotorPulleyBush'
                type='text'
                control={control}
                 
                label='Bush'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.driveMotorPulleyBore'
                type='text'
                control={control}
                label='Bore'
                style={{width: '14%', marginRight: '1%'}}
            />
        </>   
    );
};

export default MotorPulley;
