import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Paper, Backdrop, CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
    wrapper: {
        position: 'relative',
        padding: theme.spacing(1),
        marginTop: theme.spacing(2),
        marginBottom: theme.spacing(2),
        minHeight: '114px',
        [theme.breakpoints.up('md')]: {
            padding: theme.spacing(3),
        },
    },
    backdrop: {
        zIndex: 1000,
    },
}));

interface ContentWrapperProps {
    children?: any;
    isBusy?: boolean;
    style?: any;
}

const ContentWrapper: React.FC<ContentWrapperProps> = ({ children, isBusy = false, style}) => {
    const classes = useStyles();

    return (
        <Paper className={classes.wrapper} elevation={10} style={style}>
            {children}
            <Backdrop open={isBusy} className={classes.backdrop}>
                <CircularProgress color="primary" />
            </Backdrop>
        </Paper>
    );
};
export default ContentWrapper;
