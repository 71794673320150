import { useEffect } from 'react';
import { TextField } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import { useStyles } from './Form';
import SelectWithDataReactHook from 'components/SelectWithDataReactHook';
import { MenuItem, ListSubheader, FormControl, InputLabel, Select, FormHelperText } from '@material-ui/core';
import { Controller } from 'react-hook-form';

const Delivery = ({ control, quoteData, clientData, getValues, watch, setValue }) => {
    const classes = useStyles();

    const { clientDetails } = quoteData;

    const deliverTo = watch('attributes.commercialsDeliverTo.id');

    const deliveryAddresses = clientData.addresses
        .filter((e) => e.status !== 'Inactive' && e.addressTypeName === 'Delivery')
        .map(({ id, street1, street2, city, state, postcode, country, notes }) => {
            let street = [street1, street2?.trim() ?? ''].filter((s) => s != '').join(', ');
            return [
                <MenuItem key={`option_${id}`} value={id} className={classes.listItem}>
                    {street}, {city}, {state} {postcode}
                </MenuItem>,
                <ListSubheader className={classes.listItemExtra}>
                    {country}
                    <br />
                    {notes && <>Notes: {notes}</>}
                </ListSubheader>,
            ];
        });
    useEffect(() => {
        if (deliverTo) {
            const add = clientData.addresses.filter((e) => e.id === deliverTo)[0];

            setValue('attributes.commercialsDeliverTo.city', add?.city);
            setValue('attributes.commercialsDeliverTo.country', add?.country);
            setValue('attributes.commercialsDeliverTo.postcode', add?.postcode);
            setValue('attributes.commercialsDeliverTo.state', add?.state);
            setValue('attributes.commercialsDeliverTo.street1', add?.street1);
            setValue('attributes.commercialsDeliverTo.street2', add?.street2);
            setValue('attributes.commercialsDeliverTo.email', add?.email);
        }
    }, [deliverTo]);

    return (
        <div style={{ width: '100%' }}>
            <SelectWithDataReactHook
                name="attributes.deliveryTerms"
                type="text"
                control={control}
                label="Delivery Terms"
                options={[
                    {
                        label: 'EXW',
                        id: 'EXW',
                    },
                    {
                        label: 'FCA',
                        id: 'FCA',
                    },
                    {
                        label: 'CPT',
                        id: 'CPT',
                    },
                    {
                        label: 'CIP',
                        id: 'CIP',
                    },
                    {
                        label: 'DPU',
                        id: 'DPU',
                    },
                    {
                        label: 'DAP',
                        id: 'DAP',
                    },
                    {
                        label: 'DDP',
                        id: 'DDP',
                    },
                    {
                        label: 'FAS',
                        id: 'FAS',
                    },
                    {
                        label: 'FOB',
                        id: 'FOB',
                    },
                    {
                        label: 'CFR',
                        id: 'CFR',
                    },
                    {
                        label: 'CIF',
                        id: 'CIF',
                    },
                ]}
                style={{ width: '29%', marginRight: '1%' }}
            />
            <ReactHookTextField
                name="attributes.deliveryInstallLocation"
                type="text"
                control={control}
                label="Install Location"
                style={{ width: '19%', marginRight: '1%' }}
            />
            <SelectWithDataReactHook
                name="attributes.deliveryFreightProtection"
                type="text"
                control={control}
                label="Freight Protection"
                options={[
                    {
                        label: 'Plastic Wrap Fan',
                        id: 'Plastic Wrap Fan',
                    },
                    {
                        label: 'Plastic Wrap Motor only',
                        id: 'Plastic Wrap Motor only',
                    },
                    {
                        label: 'Plastic Wrap Motor & Bearings',
                        id: 'Plastic Wrap Motor & Bearings',
                    },
                    {
                        label: 'Cover Openings (timber)',
                        id: 'Cover Openings (timber)',
                    },
                    {
                        label: 'Anti-Condensation Dessicant',
                        id: 'Anti-Condensation Dessicant',
                    },
                ]}
                style={{ width: '29%', marginRight: '1%' }}
            />
            <SelectWithDataReactHook
                name="attributes.deliveryPacking"
                type="text"
                control={control}
                label="Packing"
                options={[
                    {
                        label: 'Pallet',
                        id: 'Pallet',
                    },
                    {
                        label: 'Skid',
                        id: 'Skid',
                    },
                    {
                        label: 'Open Crate',
                        id: 'Open Crate',
                    },
                    {
                        label: 'Closed Crate (Packing Case)',
                        id: 'Closed Crate (Packing Case)',
                    },
                    {
                        label: 'Export Crate (ISPM15 fumigation certificate)',
                        id: 'Export Crate (ISPM15 fumigation certificate)',
                    },
                    {
                        label: 'Cardboard Box',
                        id: 'Cardboard Box',
                    },
                    {
                        label: 'Transit Cradle',
                        id: 'Transit Cradle',
                    },
                ]}
                style={{ width: '29%', marginRight: '1%' }}
            />
            <ReactHookTextField
                name="attributes.deliveryFreightNotes"
                type="text"
                control={control}
                label="Freight Notes"
                style={{ width: '19%', marginRight: '1%' }}
            />
            <Controller
                name="attributes.commercialsDeliverTo.id"
                type={'select'}
                control={control}
                render={({ field, fieldState: { error } }) => (
                    <FormControl variant="outlined" className={classes.input} style={{ width: '24%', marginRight: '1%' }} error={error}>
                        <InputLabel margin="dense" className={classes.selectLabel}>
                            Deliver To
                        </InputLabel>

                        <Select margin="dense" {...field}>
                            {deliveryAddresses}
                        </Select>

                        {error && <FormHelperText>{error.message}</FormHelperText>}
                    </FormControl>
                )}
            />
            <TextField
                label="Deliver To Email"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={watch('attributes.commercialsDeliverTo.email')}
                style={{ width: '22%', marginRight: '1%' }}
            />

            <TextField
                label="Job Contact Name"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={watch('attributes.commercialsContact.name')}
                style={{ width: '22%', marginRight: '1%' }}
            />

            <TextField
                label="Job Contact Landline"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={watch('attributes.commercialsContact.phone')}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Job Contact Mobile"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={watch('attributes.commercialsContact.phoneAlt')}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Job Contact Email"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={watch('attributes.commercialsContact.email')}
                style={{ width: '22%', marginRight: '1%' }}
            />

            {/* <TextField
                label="Quote Contact Name"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={clientDetails?.contactDetails.name}
                style={{ width: '19%', marginRight: '1%' }}
            /> */}
            {/* <TextField
                label="Quote Contact Landline"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={clientDetails?.contactDetails.phone}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Quote Contact Mobile"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={clientDetails?.contactDetails.phoneAlt}
                style={{ width: '14%', marginRight: '1%' }}
            />
            <TextField
                label="Quote Contact Email"
                type="text"
                disabled={true}
                className={classes.disabledInput}
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                size="small"
                value={clientDetails?.contactDetails.email}
                style={{ width: '22%', marginRight: '1%' }}
            /> */}
            <ReactHookTextField
                name="attributes.deliveryFreightCompany"
                type="text"
                control={control}
                label="Freight Company"
                style={{ width: '19%', marginRight: '1%' }}
            />
            <ReactHookTextField
                name="attributes.deliveryConNote"
                type="text"
                control={control}
                label="Con Note"
                style={{ width: '19%', marginRight: '1%' }}
            />
            <ReactHookTextField
                name="attributes.deliveryDimension"
                type="text"
                control={control}
                label="Dimension (cm)"
                style={{ width: '19%', marginRight: '1%' }}
            />
            <ReactHookTextField
                name="attributes.deliveryWeight"
                type="text"
                control={control}
                label="Weight (kg)"
                style={{ width: '19%', marginRight: '1%' }}
            />
            <ReactHookTextField
                name="attributes.deliveryDateBooked"
                type="date"
                control={control}
                label="Date Booked"
                style={{ width: '19%', marginRight: '1%' }}
            />
            <ReactHookTextField
                name="attributes.deliveryDateSent"
                type="date"
                control={control}
                label="Date Sent/Picked-up"
                style={{ width: '19%', marginRight: '1%' }}
            />
        </div>
    );
};

export default Delivery;
