import { TextField, IconButton } from '@material-ui/core';
import { useStyles } from '../../Quotes/styles';
import { Controller, useWatch } from "react-hook-form";
import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';
import RemoveIcon from '@material-ui/icons/Remove';
import DoneIcon from '@material-ui/icons/Done';

const Row = ({ control, index, remove }) => {
    const classes = useStyles();

    const stock = useWatch({
        control,
        name: `lineItems.${index}.stock`
    });

    return (
        <div style={{ display: 'flex' }}>
            <Controller
                name={`lineItems.${index}.qty`}
                type='number'
                control={control}
                render={({ field, fieldState: { error } }) => <TextField
                    {...field}
                    label='Qty'
                    style={{ width: '100px', marginRight: '10px' }}
                    className={classes.input}
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    size='small'
                    error={error}
                    helperText={error && error.message}
                    onChange={(e) => {
                        const quantity = e.target.value;
                        field.onChange(quantity);
                    }}
                />
                }
            />
            <ReactHookTextField
                name={`lineItems.${index}.description`}
                type='textarea'
                control={control}
                label='Description'
                style={{ minWidth: '100px', marginRight: '10px', marginBottom: '16px' }}
                InputProps={{
                    multiline: true
                }}
            />
            <ReactHookTextField
                name={`lineItems.${index}.notes`}
                type='textarea'
                control={control}
                label='Notes'
                InputProps={{
                    multiline: true
                }}
                style={{ minWidth: '70px', marginRight: '10px' }}
            />
            <Controller
                name={`lineItems.${index}.drawing`}
                type='textarea'
                control={control}
                render={({ field, fieldState: { error } }) => <TextField
                    {...field}
                    label='Drawing'
                    style={{ minWidth: '70px', marginRight: '10px' }}
                    className={classes.input}
                    variant='outlined'
                    InputLabelProps={{ shrink: true }}
                    InputProps={{ multiline: true }}
                    size='small'
                    error={error}
                    helperText={error && error.message}
                    onChange={(e) => {
                        const drawing = e.target.value;
                        field.onChange(drawing);
                    }}
                />
                }
            />

            <ReactHookCheckbox
                control={control}
                name={`lineItems.${index}.stock`}
                label='Stock'
                style={{ marginBottom: '14px' }}
            />
            {stock ?
                <>
                    <div style={{ minWidth: '210px' }}> Stock Item</div>
                    <DoneIcon style={{ color: '#2576b6' }} /> In Stock
                </>
                :
                <>
                    <Controller
                        name={`lineItems.${index}.supplier`}
                        type='text'
                        control={control}
                        render={({ field, fieldState: { error } }) => <TextField
                            {...field}
                            label='Supplier'
                            style={{ minWidth: '70px', marginRight: '10px' }}
                            className={classes.input}
                            variant='outlined'
                            InputLabelProps={{ shrink: true }}
                            size='small'
                            error={error}
                            helperText={error && error.message}
                            onChange={(e) => {
                                const supplier = e.target.value;
                                field.onChange(supplier);
                            }}
                        />
                        }
                    />
                    <ReactHookCheckbox
                        control={control}
                        name={`lineItems.${index}.warehouse`}
                        label='WH'
                        style={{ marginBottom: '14px' }}
                    />
                    <ReactHookTextField
                        name={`lineItems.${index}.orderDate`}
                        type='date'
                        control={control}
                        label='Order Date'
                        style={{ minWidth: '70px', marginRight: '10px' }}
                    />
                    <ReactHookTextField
                        name={`lineItems.${index}.orderDue`}
                        type='date'
                        control={control}
                        label='Order Due'
                        style={{ minWidth: '70px', marginRight: '10px' }}
                    />
                    <ReactHookCheckbox
                        control={control}
                        name={`lineItems.${index}.orderReceived`}
                        label='Order Received'
                        style={{ marginBottom: '14px' }}
                    />
                </>
            }

            <IconButton
                type="button"
                color="secondary"
                onClick={() => {
                    return remove(index);
                }}
                style={{ padding: '0 10px', margin: '0px 6px', height: 40 }}
            >
                <RemoveIcon />
            </IconButton>
        </div>
    );
};

export default Row;
