import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';

import { addFeedback } from 'actions';
import AddEditForm from 'components/AddEditForm';
import BrowseData from 'components/BrowseData';
import BrowseProvider from 'providers/browse';
import { useDispatch, useTrackedState } from 'store';

import {
    USERS,
    formStructureAdd,
    formStructureEdit,
    userTransform,
    formValidation,
    apiBasePath,
    submitAddTransform,
    submitUpdateTransform,
    browseConfig,
} from './constants';
import ResetPasswordForm from './ResetPasswordForm';

interface ContentProps {
    action: string;
    id: string;
}

const Content: React.FC<ContentProps> = ({ action, id }) => {
    const dispatch = useDispatch();
    const { browseConfig: stateBrowseConfig } = useTrackedState();
    const history = useHistory();
    const { link } = USERS;
    const parseBrowseConfig = useMemo(() => JSON.parse(JSON.stringify(stateBrowseConfig)), [stateBrowseConfig]);

    const browseDefaultConfig = useMemo(() => {
        const newConfig = parseBrowseConfig?.[browseConfig.dataId] || {};

        return { ...browseConfig, ...newConfig };
    }, [parseBrowseConfig]);

    if (!action) {
        return (
            <BrowseProvider config={browseDefaultConfig}>
                <BrowseData />
            </BrowseProvider>
        );
    }

    if (['edit', 'add'].includes(action)) {
        const apiPath = action === 'edit' ? `${apiBasePath}/${id}` : apiBasePath;
        const apiSubmitPath = action === 'edit' ? apiPath : apiBasePath;

        return (
            <>
                <AddEditForm
                    apiPath={apiPath}
                    formStructure={action === 'edit' ? formStructureEdit : formStructureAdd}
                    transform={action === 'edit' && userTransform}
                    formValidation={formValidation}
                    submitOptions={{
                        apiPath: apiSubmitPath,
                        method: 'post',
                        transform: action === 'edit' ? submitUpdateTransform : submitAddTransform,
                        callback: (submitResponse: any) => {
                            const { data } = submitResponse;
                            const { userID } = data || {};

                            if (userID) {
                                const message = `User was successfully ${action === 'edit' ? 'updated' : 'added'}`;
                                dispatch(addFeedback(message, 'success'));
                                history.push(`${link}`);
                            } else {
                                dispatch(addFeedback(submitResponse?.title || submitResponse, 'error', submitResponse?.errors));
                            }
                        },
                    }}
                />
                <ResetPasswordForm userId={id} />
            </>
        );
    }

    return null;
};

export default Content;
