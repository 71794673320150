import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';

const InletFlexible = ({ control, tableView }) => {
    return (
        <>    
            {!tableView ? <Typography variant="h6" gutterBottom>Inlet Flexible</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.inletFlexibleQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.inletFlexibleMaterial'
                type='text'
                control={control}
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookCheckbox 
                control={control}
                name='attributes.inletFlexibleFlowLiners'
                label='Flow Liners'   
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.inletFlexibleMisc'
                type='text'
                control={control}
                label='Misc'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default InletFlexible;
