import { DialogProps } from 'types/dialog';
import { Action } from 'types/redux';

export const initialDialogState: DialogProps = {
    idField: 'dialog',
    open: false,
    title: '',
    content: undefined,
    actions: [],
    dialogProps: undefined
};

const dialogReducer = (state: DialogProps = initialDialogState, { type, ...payload }: Action): DialogProps => {
    switch (type) {
        case 'OPEN_DIALOG':
            return { ...state, open: true, ...payload };
        case 'CLOSE_DIALOG':
            return { ...state, open: false, title: '' };
        default:
            return state;
    }
};

export default dialogReducer;
