import { Text, View, Image, StyleSheet } from '@react-pdf/renderer';

export const styles = StyleSheet.create({
    page: {
        position: 'relative',
        fontSize: 10,
        width: '100%',
        marginTop: 15,
        paddingBottom: 30,
    },
    w5: { width: '5%' },
    w10: { width: '10%' },
    w15: { width: '15%' },
    w20: { width: '20%' },
    w25: { width: '25%' },
    w30: { width: '30%' },
    w35: { width: '35%' },
    w45: { width: '45%' },
    w50: { width: '50%' },
    w55: { width: '55%' },
    w65: { width: '65%' },
    w70: { width: '70%' },
    w80: { width: '80%' },
    w85: { width: '85%' },
    w100: { width: '100%' },
    aireng: {
        marginTop: 5,
        width: 100,
        height: 60,
    },
    aerotech:  {
        marginTop: 5,
        width: 180,
        height: 36,
    },
    title: {
        fontSize: 20,
        marginTop: 20,
        fontFamily: 'Roboto Bold',
        textAlign: 'right'
    },
    subtitle: {
        fontSize: 20,
        fontFamily: 'Roboto Bold',
        textAlign: 'right'
    },
    mR: {
        marginRight: 2,
    },
    mL: {
        marginLeft: 2,
    },
    flex: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'nowrap',
    },
    table: {
        marginLeft: '4%',
        marginRight: '4%',
        width: '92%',
        borderTop: '1px solid grey',
        borderLeft: '1px solid grey',
        borderRight: '1px solid grey',
        borderBottom: '1px solid grey',
    },
    boldText: {
        fontSize: 12,
        fontFamily: 'Roboto Bold',
    },
});

const PDFHeader = ({ Aerotech, id, isWarehouse, isPaint }) => {
    return (
        Aerotech ?
            <View style={[styles.flex, styles.table]}>
                <View style={[styles.w35, styles.mL]}>
                    <Image style={styles.aerotech} src="/img/Aerotech-Fans-Logo-300px.png" />
                </View>
                <View style={[styles.w65, styles.mL]}>
                    <Text style={[styles.boldText, styles.mR, styles.subtitle]}>AEROTECH {isWarehouse && 'WAREHOUSE'}</Text>
                    <Text style={[styles.boldText, styles.mR, styles.subtitle]}>{isPaint ? 'PAINT SCOPE REF' : 'JOB SHEET'} # {id}</Text>
                </View>
            </View>
            :
            <View style={[styles.flex, styles.table]}>
                <View style={[styles.w30, styles.mL]}>
                    <Image style={styles.aireng} src="/img/aireng-logo.png" />
                </View>
                <View style={[styles.w70, styles.mL]}>
                    <Text style={[styles.boldText, styles.mR, styles.title]}>AIRENG {isWarehouse && 'WAREHOUSE '}{isPaint ? 'PAINT SCOPE REF' : 'JOB SHEET'} # {id}</Text>
                </View>
            </View>
    );
};

export default PDFHeader;
