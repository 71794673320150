import { DialogActionType, DialogPropType } from 'types/dialog';

export const openDialog = (title: string, content: any, actions: DialogActionType[], dialogProps?: DialogPropType) => {
    return {
        type: 'OPEN_DIALOG',
        title,
        content,
        actions,
        dialogProps,
    };
};

export const closeDialog = () => ({
    type: 'CLOSE_DIALOG',
});
