import { combineReducers } from 'redux';
import browseConfig, { initialBrowseConfigState } from './browseConfigReducer';
import dialog, { initialDialogState } from './dialogReducer';
import drawer, { initialDrawerState } from './drawerReducer';
import feedback, { initialFeedbackState } from './feedbackReducer';

export const initialState = {
    browseConfig: initialBrowseConfigState,
    dialog: initialDialogState,
    drawer: initialDrawerState,
    feedback: initialFeedbackState,
};

export const rootReducer = combineReducers({
    browseConfig,
    dialog,
    drawer,
    feedback,
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
