import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import LoginForm from './LoginForm';
import ResetPassForm from './ResetPassForm';
import ResetCodeForm from './ResetCodeForm';
import Challenge from './Challenge';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        backgroundColor: theme.palette.background.default,
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        '& td': {
            padding: '4px',
        },
        '& .MuiFormControl-root': {
            width: '100%',
        },
        '& .MuiFilledInput-root': {
            background: theme.palette.background,
            width: '100%',
        },
    },
    submit: {
        width: '100%',
    },
    logo: { width: '80%', marginLeft: '6px' },
}));

// https://blog.kylegalbraith.com/2020/03/31/customizing-the-aws-amplify-authentication-ui-with-your-own-react-components/

export interface LoginProps {}

const Login: React.SFC<LoginProps> = () => {
    const classes = useStyles();
    const [step, setStep] = useState<'login' | 'reset' | 'code' | 'challenge'>('login');
    const [resetUsername, setResetUsername] = useState<string>('');
    const [ user, setUser ] = useState();

    const showLogin = () => {
        setStep('login');
    };

    const showReset = () => {
        setStep('reset');
    };

    const showCode = () => {
        setStep('code');
    };

    const showChallenge = (userObj: any) => {
        setUser(userObj);
        setStep('challenge');
    };

    return (
        <div className={classes.root}>
            <Link to="/portal">
                <img src="/img/aireng-logo.png" alt="AirEng" className={classes.logo} />
            </Link>
            {step === 'login' && <LoginForm showReset={showReset} showChallenge={showChallenge} />}
            {step === 'reset' && <ResetPassForm showLogin={showLogin} showCode={showCode} setResetUsername={setResetUsername} />}
            {step === 'code' && <ResetCodeForm showLogin={showLogin} username={resetUsername} />}
            {step === 'challenge' && <Challenge showLogin={showLogin} user={user} />}
        </div>
    );
};

export default Login;
