import React, { useMemo } from 'react';
import clsx from 'clsx';
import { TableCell, Link as MuiLink } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { Link, useLocation } from 'react-router-dom';
import Actions from './Actions';
import { ActionType, TextLinkType } from 'types/browse';

const useStyles = makeStyles((theme) => ({
    td: {
        fontSize: '0.9em',
        padding: 0,
        paddingRight: theme.spacing(1),
    },
    sticky: {
        position: 'sticky',
        left: 0,
        background: 'white',
    },
}));

interface TCellProps {
    id: number;
    actions?: ActionType[] | undefined;
    textLink?: TextLinkType | undefined;
    text: string;
    fieldName: string | undefined;
    formatData: any;
    item: any;
    urlPath?: string;
    sticky?: boolean;
    component?: React.FC<any>;
}

const TCell: React.FC<TCellProps> = ({
    id,
    actions,
    textLink,
    text = '',
    item,
    fieldName,
    formatData,
    urlPath,
    sticky,
    component: Comp,
    ...rest
}) => {
    const classes = useStyles();
    const location = useLocation();

    const linkPath = useMemo(() => urlPath || location.pathname, [location.pathname, urlPath]);
    const linkUrl = useMemo(() => {
        if (!textLink) {
            return undefined;
        }

        return `${linkPath}/${textLink.action}/${item[textLink.idField]}`;
    }, [item, linkPath, textLink]);

    let content;

    if (Comp) {
        content = <Comp id={id} item={item} fieldName={fieldName} formatData={formatData} text={text} />;
    } else if (actions) {
        content = <Actions actions={actions} linkPath={linkPath} id={id} item={item} />;
    } else if (linkUrl) {
        content = (
            <MuiLink component={Link} to={linkUrl}>
                {text}
            </MuiLink>
        );
    } else {
        content = text;
    }

    return (
        <TableCell className={clsx(classes.td, sticky && classes.sticky)} {...rest}>
            {content}
        </TableCell>
    );
};

export default TCell;
