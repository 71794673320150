import Checkbox from '@material-ui/core/Checkbox';
import { useStyles } from '../screens/Quotes/styles';
import { Controller } from "react-hook-form";
import FormControlLabel from '@material-ui/core/FormControlLabel';

const ReactHookTextField = ({ name, type, control, label, style }) => {
    const classes = useStyles();

    return (
        <Controller
            name={name}
            type={type}
            control={control}
            render={({ field }) => 
                label ? <FormControlLabel 
                    control={
                        <Checkbox 
                            {...field} 
                            checked={field.value}
                        />
                    } 
                    label={label}
                    style={style}
                /> :
                <Checkbox 
                    {...field} 
                    checked={field.value}
                    style={style}
                    className={classes.input}
                />
            }
        />            
    );
};

export default ReactHookTextField;
