import { TextField } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import SelectWithDataReactHook from 'components/SelectWithDataReactHook';
import { useStyles } from './Form';

const Production = ({ control, getValues }) => {
    const classes = useStyles();
    
    return (
        <div style={{width: '100%'}}>
            <ReactHookTextField
                name='attributes.productionFabExpectedStart'
                type='date'
                control={control}
                label='Expected Fab Start'
                style={{width: '14%', marginRight: '1%'}}
            />
            <TextField
                label='Quoted Fab (Hours)'
                type='text'
                disabled={true}
                className={classes.disabledInput}
                variant='outlined' 
                InputLabelProps={{ shrink: true }} 
                size='small'
                value={getValues('attributes.commercialsFabQuoted')}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.commercialsFabAllowed'
                type='text'
                control={control}
                label='Allowed Fab (Hours)'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.commercialsFabActual'
                type='text'
                control={control}
                label='Actual Fab (Hours)'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.productionFitExpectedStart'
                type='date'
                control={control}
                label='Expected Fit Start'
                style={{width: '14%', marginRight: '1%'}}
            />
            <TextField
                label='Quoted Fit (Hours)'
                type='text'
                disabled={true}
                className={classes.disabledInput}
                variant='outlined' 
                InputLabelProps={{ shrink: true }} 
                size='small'
                value={getValues('attributes.commercialsFitQuoted')}
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.commercialsFitAllowed'
                type='text'
                control={control}
                label='Allowed Fit (Hours)'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.commercialsFitActual'
                type='text'
                control={control}
                label='Actual Fit (Hours)'
                style={{width: '14%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.productionNotes'
                type='textarea'
                control={control}
                label='Notes'
                style={{width: '24%', marginRight: '1%'}}
                InputProps={{
                    multiline:true
                }}
            />   
            <SelectWithDataReactHook
                name="attributes.productionAllocated"
                type="text"
                control={control}
                label="Production Allocated"
                options={[
                    {
                        label: 'AirEng',
                        id: 'AirEng',
                    },
                    {
                        label: 'Aerotech',
                        id: 'Aerotech',
                    },
                    {
                        label: 'Metfab',
                        id: 'Metfab',
                    },
                    {
                        label: 'Control Fab',
                        id: 'Control Fab',
                    },
                    {
                        label: 'Shearform',
                        id: 'Shearform',
                    },
                    {
                        label: '24/7',
                        id: '24/7',
                    },
                    {
                        label: 'Other External Fab',
                        id: 'Other External Fab',
                    },
                ]}
                style={{ width: '24%', marginRight: '1%' }}
            />
        </div>
    );
};

export default Production;
