import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const ShaftSeal = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Shaft Seal</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.shaftSealQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.shaftSealType'
                type='text'
                control={control}
                 
                label='Type'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.shaftSealThickness'
                type='text'
                control={control}
                 
                label='Thickness'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default ShaftSeal;
