import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const SlideRails = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Slide Rails</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.driveSlideRailsQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.driveSlideRailsModel'
                type='text'
                control={control}
                label='Model'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.driveSlideRailsSize'
                type='text'
                control={control}
                 
                label='Size'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default SlideRails;
