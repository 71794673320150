import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const Couplings = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Couplings</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.couplingQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.couplingType'
                type='text'
                control={control}
                label='Type'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.couplingNotes'
                type='textarea'
                control={control}
                label='Notes'
                style={{width: '24%', marginRight: '1%'}}
                InputProps={{
                    multiline:true
                }}
            />  
        </>   
    );
};

export default Couplings;
