import React from 'react';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { Chip } from '@material-ui/core';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme) => ({
    chip: {
        marginLeft: theme.spacing(1),
        cursor: 'pointer',
    },
}));

interface ActionButtonProps {
    action?: string | undefined;
    useUrlId?: boolean;
    excludeActions?: any[];
    title: string;
    to: string;
    icon?: any;
}

const ActionButton: React.FC<ActionButtonProps> = ({ action, to, useUrlId, excludeActions = [], title, icon: ItemIcon }) => {
    const classes = useStyles();    
    const params:any = useParams();

    if (useUrlId) {
        const { id } = params;

        if (id) {
            to = `${to}/${id}`;
        }
    }

    if (excludeActions.includes(action)) {
        return null;
    }

    return <Chip to={to} component={Link} label={title} color="secondary" clickable className={classes.chip} icon={<ItemIcon/>} />;
};

export default ActionButton;
