import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const BeltGuard = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Belt Guard</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.driveBeltGuardQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.driveBeltGuardCentreRange'
                type='text'
                control={control}
                label='Centre Range'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.driveBeltGuardMaterial'
                type='text'
                control={control}
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.driveBeltGuardThickness'
                type='text'
                control={control}
                label='Thickness'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.driveBeltGuardPaint'
                type='text'
                control={control}
                 
                label='Paint'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.driveBeltGuardNotes'
                type='text'
                control={control}
                label='Notes'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default BeltGuard;
