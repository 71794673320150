import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';
import ReactHookCheckbox from 'components/ReactHookCheckbox';

export const InletSilencerTransition = ({control, tableView}) => (<SilencerTransition type="Inlet" control={control} tableView={tableView}/>)
export const OutletSilencerTransition = ({control, tableView}) => (<SilencerTransition type="Outlet" control={control} tableView={tableView}/>)
const SilencerTransition = ({ type, control, tableView }) => {

    const lType = type.toLowerCase()
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>{type} Silencer Transition</Typography> : <br/>}
            <ReactHookTextField
                name={`attributes.${lType}SilencerTransitionQty`}
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name={`attributes.${lType}SilencerTransitionLength`}
                type='text'
                control={control}
                label='Length'
                style={{width: '19%', marginRight: '1%'}}
            />

            <ReactHookTextField
                name={`attributes.${lType}SilencerTransitionThickness`}
                type='text'
                control={control}
                label='Thickness'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name={`attributes.${lType}SilencerTransitionMisc`}
                type='text'
                control={control}
                label='Misc'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

