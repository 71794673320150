import { useParams } from 'react-router-dom';
import { Container } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ContentHeader from 'components/ContentHeader';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import AddIcon from '@material-ui/icons/Add';
import FormWrapper from './FormWrapper';
import BrowseProvider from 'providers/browse';
import BrowseData from 'components/BrowseData';
import { useTrackedState } from 'store';
import { Link } from 'react-router-dom';
import QuicksightLink from 'components/QuicksightLink';

const actions = [
    {
        key: 'browseClients',
        excludeActions: [''],
        title: 'Browse Clients',
        to: '/clients',
        icon: ArrowBackIcon,
    },
    {
        key: 'createQuote',
        excludeActions: ['', 'add'],
        useUrlId: true,
        title: 'Create Quote',
        to: '/quotes/new/client',
        icon: AddIcon,
    },
]; 

const useStyles = makeStyles({
    root: {
        position: 'relative',
        margin: '20px',
        padding: '20px',
    },
    list: {
        width: 250,
    },
});

export const browseConfig = {
    dataId: 'clients',
    apiBasePath: '/clients',
    createApiPath: (path, pageNum, pageSize, filterValues) => {
        if (filterValues?.hasOwnProperty('name') && filterValues['name'].length) {
            return `${path}?pageNum=${pageNum}&pageSize=${pageSize}&searchTerm=${encodeURIComponent(filterValues?.['name'].toString())}`;
        }
        return `${path}?pageNum=${pageNum}&pageSize=${pageSize}`;
    },
    transform: undefined,
    tableStructure: [
        {
            title: 'Action',
            align: 'left',
            actions: [
                { title: 'Create Quote', idField: 'id', action: 'new/client', path: '/quotes' },
                { title: 'Edit Client', idField: 'id', action: 'edit', path: '/clients' },
                // { title: 'Remove Client', idField: 'id', action: 'remove', path: '/clients' },
            ],
            width: '5',
        },
        {
            title: 'Company Name',
            fieldName: 'name',
            showFilter: true,
            showSort: false,
            textLink: { idField: 'id', action: 'edit' },
        },
    ],
    urlPath: '/clients',
};

const Clients = () => {
    const classes = useStyles();
    const { action, id } = useParams();
    const { browseConfig: stateBrowseConfig } = useTrackedState();
    const { pageSize, ...parseBrowseConfig } = JSON.parse(JSON.stringify(stateBrowseConfig));

    const addRowContent = <div>Can't find the client you are looking for? Click here to <Link to={'/clients/add'} color="secondary">add a client</Link>.</div>

    return (
        <Container maxWidth={false}>
            <ContentHeader title={'Clients'} actions={actions} action={action} />
            {!action &&
                <BrowseProvider config={{ ...browseConfig, ...parseBrowseConfig?.[browseConfig.dataId] || {} }} pageSize={pageSize}>
                    <BrowseData addRowContent={addRowContent} />
                </BrowseProvider>
            }

            { action && <FormWrapper id={id} action={action} /> }
        </Container>
    );
};

export default Clients;
