import React from 'react';
import { Link, useHistory } from 'react-router-dom';
import { AppBar, Toolbar, IconButton, Typography, Menu, MenuItem } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { makeStyles } from '@material-ui/core/styles';
import Drawer from 'components/Drawer';
import Dialog from 'components/Dialog';
import { closeDialog } from 'actions/dialog';
import { useDispatch, useTrackedState } from 'store';
import { useAuth } from 'providers/auth';
import QuicksightLink from 'components/QuicksightLink';

const useStyles = makeStyles((theme) => ({
    root: {
        minHeight: '100vh',
        flexGrow: 1,
    },
    appBar: {
        backgroundColor: theme.palette.primary.dark,
    },
    menuButton: {
        position: 'fixed',
        top: '10px',
        right: theme.spacing(2),
        backgroundColor: `${theme.palette.primary.dark}be`,
        zIndex: 1000
    },
    title: {
        flexGrow: 1,
    },
    links: {
        textDecoration: 'none',
    },
    footer: {
        textAlign: 'center',
        paddingBottom: theme.spacing(2),
    },
    logo: {
        height: '50px',
        marginTop: '8px',
    },
}));

export interface LayoutProps {
    children: any;
}

const Layout: React.SFC<LayoutProps> = ({ children }) => {
    const dispatch = useDispatch();
    const classes = useStyles();
    const { logout, userAttr }: any = useAuth();
    const { dialog } = useTrackedState();
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const history = useHistory();

    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    history.listen(() => {
        if (dialog.open) {
            dispatch(closeDialog());
        }
    });

    return (
        <div className={classes.root}>
            <AppBar position="static" className={classes.appBar}>
                <Toolbar>
                    <Typography variant="h6" className={classes.title}>
                        <img src="/img/aireng.svg" alt="AirEng" className={classes.logo} />
                    </Typography>
                    {/* <Button color="inherit" onClick={handleLogout}>
                        Log Out
                    </Button> */}
                    <IconButton edge="start" className={classes.menuButton} color="inherit" aria-label="menu" onClick={handleClick}>
                        <MenuIcon />
                    </IconButton>
                    <Menu
                        id="main-menu"
                        anchorEl={anchorEl}
                        keepMounted
                        open={Boolean(anchorEl)}
                        onClose={handleClose}
                        className={classes.links}
                    >
                        <MenuItem component={Link} onClick={handleClose} to="/clients">
                            Clients
                        </MenuItem>
                        <MenuItem component={Link} onClick={handleClose} to="/quotes">
                            Quotes
                        </MenuItem>
                        <MenuItem component={Link} onClick={handleClose} to="/jobs">
                            Jobs
                        </MenuItem>
                        <MenuItem component={Link} onClick={handleClose} to="/jobs/wip">
                            WIP List
                        </MenuItem>
                        <MenuItem component={Link} onClick={handleClose} to="/scblowers">
                            Side Channel Blowers
                        </MenuItem>

                        <MenuItem component={Link} onClick={handleClose} to="/reports">
                            Reports
                        </MenuItem>

                        { userAttr.roles.includes("Admin") && 
                            <MenuItem component={Link} onClick={handleClose} to="/users">
                                Users
                            </MenuItem>
                        }
                        <MenuItem onClick={logout}>Log Out</MenuItem>
                    </Menu>
                </Toolbar>
            </AppBar>
            {children}
            <Drawer />
            <Dialog />
        </div>
    );
};

export default Layout;
