import { Text, View } from '@react-pdf/renderer';
import { styles } from './PDFStyles';

const PDFHeader = ({ Aerotech, Aftermarket }) => {
    return (
        Aerotech ? 
            <View fixed style={[styles.flex, styles.footer]}>         
                <Text>            
                    {Aftermarket && <>
                        Due to recent market volatility all quoted pricing will only remain valid for the standard thirty (30) day period. At the expiry of this period we reserve the right to revalidate the quotation based upon current market rates. Aerotech will endeavour to hold quoted pricing wherever possible.
                        {"\n"}{"\n"}
                    </>} 
                    Subject to Aerotech Standard Terms & Conditions of Sale, available at https://www.aerotechfans.com.au/terms-and-conditions/
                </Text>   
            </View> :
            <View fixed style={[styles.flex, styles.footer]}>           
                <Text>           
                    {Aftermarket && <>
                        Due to recent market volatility all quoted pricing will only remain valid for the standard thirty (30) day period. At the expiry of this period we reserve the right to revalidate the quotation based upon current market rates. AirEng will endeavour to hold quoted pricing wherever possible.
                        {"\n"}{"\n"}
                    </>}  
                    Subject to AirEng Standard Terms & Conditions of Sale, available at https://www.aireng.com.au/wp-content/uploads/STANDARD-TERMS-CONDITIONS-25-Sept-2013.pdf
                </Text>     
            </View>
    );
};

export default PDFHeader;
