import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const ShaftGuard = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Shaft Guard</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.shaftGuardQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.shaftGuardMaterial'
                type='text'
                control={control}
                label='Material'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.shaftGuardThickness'
                type='text'
                control={control}
                label='Thickness'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.shaftGuardPaint'
                type='text'
                control={control}
                label='Paint'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.shaftGuardNotes'
                type='text'
                control={control}
                label='Notes'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default ShaftGuard;
