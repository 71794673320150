import { Typography } from '@material-ui/core';
import ReactHookTextField from 'components/ReactHookTextField';

const Internal = ({ control, tableView }) => {
    return (
        <>
            {!tableView ? <Typography variant="h6" gutterBottom>Internal Finish</Typography> : <br/>}
            <ReactHookTextField
                name='attributes.internalCoatingQty'
                type='text'
                control={control}
                label='Qty'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.internalCoatingType'
                type='textarea'
                control={control}
                label='Type'
                style={{width: '19%', marginRight: '1%'}}
                InputProps={{
                    multiline:true
                }}
            />
            <ReactHookTextField
                name='attributes.internalCoatingReport'
                type='text'
                control={control}
                label='Int Report'
                style={{width: '19%', marginRight: '1%'}}
            />
            <ReactHookTextField
                name='attributes.internalCoatingITP'
                type='text'
                control={control}
                label='Int ITP'
                style={{width: '19%', marginRight: '1%'}}
            />
        </>   
    );
};

export default Internal;
