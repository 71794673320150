import React from 'react';
import { TableRow } from '@material-ui/core';

import TCell from './TCell';
import { TableItem } from 'types/browse';

interface TRowProps {
    item: any;
    id: number;
    defaultFormatData: any;
    urlPath: string;
    tableStructure: TableItem[];
}

const TRow: React.FC<TRowProps> = ({ item, id, defaultFormatData, urlPath, tableStructure }) => {
    const content = 
            tableStructure.map(
                ({ fieldName, actions, formatData = defaultFormatData, textLink, showFilter, showSort, ...rest }) => {
                    const text = formatData(item, fieldName);
                    
                    return (
                        <TCell
                            key={`${id}_${fieldName}`}
                            id={id}
                            fieldName={fieldName}
                            formatData={formatData}
                            actions={actions}
                            textLink={textLink}
                            text={text}
                            item={item}
                            urlPath={urlPath}
                            {...rest}
                        />
                    );
                },
            );

    return <TableRow>{content}</TableRow>;
};

export default TRow;
