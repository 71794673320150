import React, { useState } from 'react';
import useStyles from '../styles';
import { MenuItem, ListSubheader, FormControl, InputLabel, Select, FormHelperText } from '@material-ui/core';
import { Controller } from "react-hook-form";
import { axiosInstance } from 'App';

const ClientContactsAndAddresses = ({ control, watch, setValue }) => {
    const classes = useStyles();

    const [res, setRes] = useState();
    const [clientContacts, setClientContacts] = useState();
    const [clientAddresses, setClientAddresses] = useState();
    const [initClient, setInitClient] = useState(true);
    const clientID = watch('clientID');
    const addressID = watch('addressID');
    const contactID = watch('contactID');

    React.useEffect(() => {
        const fetchClient = async () => {
            const result = await axiosInstance({ url: `/clients/${clientID}` });

            setRes(result.data);

            setValue('clientDetails.abn', result.data.abn)
            setValue('clientDetails.name', result.data.name)

            if (initClient) {
                setInitClient(false);
            } else {
                setValue('contactID', '')
                setValue('addressID', '')
            }
        }

        fetchClient();
    }, [clientID]);

    React.useEffect(() => {
        if (res) {
            const addresses = res.addresses.map(({ id, street1, city, state, postcode, country, addressTypeName, notes, status }) => {
                return status !== 'Inactive' ? [
                    <MenuItem key={`option_${id}`} value={id} className={classes.listItem}>
                        {street1}, {city}, {state} {postcode}
                    </MenuItem>,
                    <ListSubheader className={classes.listItemExtra}>
                        {addressTypeName}<br/>
                        {country}<br/>
                        {notes && <>Notes: {notes}</>}
                    </ListSubheader>, 
                ]: [];    
            });
            
            setClientAddresses(addresses);
        }
    }, [res]);

    React.useEffect(() => {
        if (res && addressID) {
            const contacts = res.contacts.map(({ id, name, phone, position, notes, status, addresses }) => {
                return status !== 'Inactive' && (addresses.includes(addressID) || addresses.length == 0) ? [
                    <MenuItem key={`option_${id}`} value={id} className={classes.listItem}>
                        {name}
                    </MenuItem>,
                    <ListSubheader className={classes.listItemExtra}>
                        Phone: {phone}
                        {position && <><br/>Position: {position}</>}
                        {notes && <><br/>Notes: {notes}</>}
                    </ListSubheader>, 
                ] : [];    
            });
            setClientContacts(contacts);

            const add = res.addresses.filter(e => e.id === addressID)[0]

            if(add){
                setValue('clientDetails.addressDetails.id', addressID)
                setValue('clientDetails.addressDetails.city', add.city)
                setValue('clientDetails.addressDetails.country', add.country)
                setValue('clientDetails.addressDetails.postcode', add.postcode)
                setValue('clientDetails.addressDetails.state', add.state)
                setValue('clientDetails.addressDetails.street1', add.street1)
                setValue('clientDetails.addressDetails.street2', add.street2)
            }
        
        } else {
            setClientContacts([]);
        }
    }, [res, addressID]);

    React.useEffect(() => {
        if (res && contactID) {
            const cont = res.contacts.filter(e => e.id === contactID)[0]
            
            if(cont){
                setValue('clientDetails.contactDetails.id', contactID)
                setValue('clientDetails.contactDetails.email', cont.email)
                setValue('clientDetails.contactDetails.fax', cont.fax)
                setValue('clientDetails.contactDetails.name', cont.name)
                setValue('clientDetails.contactDetails.phone', cont.phone)
                setValue('clientDetails.contactDetails.phoneAlt', cont.phoneAlt)
            }
        }
    }, [res, contactID]);
  
    return (
        <>
            <Controller
                name='addressID'
                type={'select'}
                control={control}
                render={({ field, fieldState: { error } }) =>
                    <FormControl variant='outlined' className={classes.input} style={{ width: '29%', marginRight: '1%' }} error={error}>
                        <InputLabel margin="dense" className={classes.selectLabel}>Client Address</InputLabel>

                        <Select name='addressID' margin='dense' {...field}>  
                            {clientAddresses}
                        </Select>

                        {error && <FormHelperText>{error.message}</FormHelperText> }
                    </FormControl>
                }
            /> 
            
            <Controller
                name='contactID'
                type={'select'}
                control={control}
                render={({ field, fieldState: { error } }) =>
                    <FormControl variant='outlined' className={classes.input} style={{ width: '19%', marginRight: '1%' }} error={error}>
                        <InputLabel margin="dense" className={classes.selectLabel}>Client Contact</InputLabel>

                        <Select name='contactID' margin='dense' {...field}>
                            {clientContacts}
                        </Select>

                        {error && <FormHelperText>{error.message}</FormHelperText> }
                    </FormControl>
                }
            /> 
        </>
    );
};

export default ClientContactsAndAddresses;