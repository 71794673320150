import { AxiosRequestConfig } from 'axios';
import { axiosInstance } from 'App';

const makePatch = async <Data extends {}>(request: any) => {
    const requestModified: AxiosRequestConfig = {
        method: 'PATCH',
        ...request,
        headers: {
            "Content-Type" : "application/json-patch+json"
        }
    };
    const { data, status, statusText } = await axiosInstance(requestModified);
    return { data, status, statusText };
};

export default makePatch;
