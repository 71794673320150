import { TableItem, SortValueType } from 'types/browse';

export const toggleSortOrder = (sortValue: SortValueType, fieldName: string) => {
    const sortOrder = sortValue.sortOrder === 'asc' && sortValue.fieldName === fieldName ? 'desc' : 'asc';

    return {
        fieldName,
        sortOrder,
    };
};

export const createFilterValues = (tableStructure: TableItem[]) =>
    tableStructure.reduce((obj, item: TableItem) => {
        if (!item.showFilter || !item.fieldName) {
            return obj;
        }

        return { ...obj, [item.fieldName]: '' };
    }, {});
