import { Text, View, Image } from '@react-pdf/renderer';
import { styles } from './PDFStyles';

const PDFHeader = ({ Aerotech }) => {
    return (
        Aerotech ? 
            <View style={[styles.flex, styles.header]}>
                <View style={styles.w70}>
                    <Image style={styles.aerotech} src="/img/Aerotech-Fans-Logo-800px.png" />
                </View>
                <View style={styles.w30}>
                    <Text style={styles.name}>Aerotech Pty Ltd</Text>
                    <Text style={styles.abn}>ABN 29 616 452 405</Text>
                    <Text>176 Colchester Road</Text>
                    <Text style={styles.address}>Bayswater VIC 3153 Australia</Text>
                    <Text>Phone +613 9455 2700</Text>
                    <Text>Email sales@aerotechfans.com.au</Text>
                    <Text style={styles.web}>Web www.aerotechfans.com.au</Text>
                </View>
            </View> :
            <View style={[styles.flex, styles.header]}>
                <View style={styles.w70}>
                    <Image style={styles.aireng} src="/img/aireng-logo.png" />
                </View>
                <View style={styles.w30}>
                    <Text style={styles.name}>AirEng Pty Ltd</Text>
                    <Text style={styles.abn}>ABN 60 060 583 164</Text>
                    <Text>176 Colchester Road</Text>
                    <Text style={styles.address}>Bayswater VIC 3153 Australia</Text>
                    <Text>Phone +613 9738 1999</Text>
                    <Text>Email fans@aireng.com.au</Text>
                    <Text style={styles.web}>Web www.aireng.com.au</Text>
                </View>
            </View>
    );
};

export default PDFHeader;
