import { useState } from 'react';
import { useForm, Controller, useFieldArray } from "react-hook-form";
import { makeStyles } from '@material-ui/core/styles';
import { Button, Grid, IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import Modal from '@material-ui/core/Modal';
import makePatch from 'helpers/makePatch';
import { useDispatch } from 'store';
import { addFeedback } from 'actions';
import ReactHookTextField from './ReactHookTextField';

export const useStyles = makeStyles((theme) => ({
    paper: {
        position: 'absolute',
        width: 400,
        backgroundColor: theme.palette.background.paper,
        border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),

        top: `${50}%`,
        left: `${50}%`,
        transform: `translate(-${50}%, -${50}%)`,
    },
}), { index: 1 });


export const EditableField = ({ item, fieldName, formatData, resourceUrl }) => {

    const dispatch = useDispatch()
    
    // value displayed in the cell (not modal)
    const [dispValue, setDispValue] = useState(formatData(item))

    // original value shown in modal which will be restored when modal is cancelled
    const [origValue, setOrigValue] = useState(dispValue)
    const [modalOpen, setModalOpen] = useState(false)

    const { handleSubmit, control, getValues, setValue } = useForm({
        defaultValues: {
            fieldName: dispValue
        }
    });

    const classes = useStyles();

    const submit = async () => {

        try {
            const response = await makePatch({
                url: resourceUrl,
                data: [
                    {
                        value: getValues(fieldName),
                        path: `/${fieldName.replace('.', '/')}`,
                        op: "replace"
                    }
                ]
            });

            setDispValue(formatData(response.data))
            dispatch(addFeedback('Field updated', 'success'));
        } catch (error) {
            dispatch(addFeedback(error, 'error'));
        } finally {
            setModalOpen(false)
        }
    };
    return (
        <>
            <Modal open={modalOpen} aria-labelledby="simple-modal-title" aria-describedby="simple-modal-description">
                <div className={classes.paper}>
                    <h2 id="simple-modal-title">Update Field</h2>
                    <p id="simple-modal-description">
                        <ReactHookTextField
                            name={fieldName}
                            type='textarea'
                            control={control}
                            style={{ width: '100%' }}
                            InputProps={{
                                multiline: true,
                                rows: 3
                            }}
                        />
                    </p>
                    <Grid container justifyContent="space-between">
                        <Grid item>
                            <Button
                                variant='contained'
                                color="secondary"
                                type='submit'
                                onClick={handleSubmit(submit)}
                            >
                                Confirm
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button
                                variant='contained'
                                color="secondary"
                                type='submit'
                                onClick={() => {
                                    setValue(fieldName, origValue)
                                    setDispValue(origValue)
                                    setModalOpen(false)
                                }}
                            >
                                Cancel
                            </Button>
                        </Grid>
                    </Grid>
                </div>
            </Modal>
            {dispValue}
            <IconButton
                onClick={() => {
                    setValue(fieldName, dispValue)
                    setOrigValue(dispValue)
                    setModalOpen(true)
                }}>
                <EditIcon />
            </IconButton>
        </>
    )
}