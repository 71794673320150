import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles({
    loader: {
        display: 'flex',
        height: '100%',
        justifyContent: 'center',
        alignItems: 'center',
    },
});

const Loader: React.FC = (props) => {
    const classes = useStyles();

    return (
        <div className={classes.loader} {...props}>
            <CircularProgress />
        </div>
    );
};

export default Loader;
