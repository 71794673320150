import React from 'react';
import Login from 'components/Login';
import { useAuth } from 'providers/auth';

interface SecurityCheckProps {
    children: any;
}

const SecurityCheck: React.FC<SecurityCheckProps> = ({ children }) => {
    const { userLoading }: any = useAuth();
    
    return userLoading ? <Login /> : children ;
};

export default SecurityCheck;
