import { AxiosRequestConfig } from 'axios';
import { axiosInstance } from 'App';

// const makePost = async <Data extends {}>(request: Omit<AxiosRequestConfig, 'baseURL' | 'method' | 'data'>) => {
const makePost = async <Data extends {}>(request: any) => {
    const requestModified: AxiosRequestConfig = {
        method: 'POST',
        ...request,
    };

    const { data, status, statusText } = await axiosInstance(requestModified);

    return { data, status, statusText };
};

export default makePost;
